import React, { forwardRef } from "react";
import styled from "styled-components/macro";
import { NavLink } from "react-router-dom";
import { rgba } from "polished";
import { useTranslation } from "react-i18next";
import { Chip, Collapse, ListItemButton, ListItemText } from "@mui/material";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
// import { id } from "date-fns/locale";
// import useAuth from "../../hooks/useAuth";
const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <NavLink {...props} />
  </div>
));

// padding-right: ${(props) =>
// props.theme.spacing(props.depth && props.depth > 0 ? 4 : 7)};
//  padding-left: ${(props) =>
// props.theme.spacing(props.depth && props.depth > 0 ? 14 : 8)};

const Item = styled(ListItemButton)`
  padding-top: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  padding-bottom: ${(props) =>
    props.theme.spacing(props.depth && props.depth > 0 ? 2 : 3)};
  background-color: #000000;
  padding-left: 5px;

  font-weight: ${(props) => props.theme.typography.fontWeightRegular};
  svg {
    color: rgba(255, 255, 255, 0.6);
    font-size: 20px;
    margin-left: 15px;

    opacity: 5.5;
  }
  &:hover {
    // background: rgba(6, 189, 255, 1);
    background: #1b2430;
    color: #ffffff;
    // font-weight: 900
  }
  &.${(props) => props.activeclassname} {
    span {
      color: white;
      // font-weight: 600;
      // background: #1B2430;
    }
    color: white;
    //  background: #0638C1;
    background: #1b2430;
  }
`;
// color: ${(props) => props.theme.sidebar.color};
//  color: ${(props) => props.theme.sidebar.color};
const Title = styled(ListItemText)`
  span {
    color: rgba(255, 255, 255, 0.6);
    font-size: 16px;
    font-weight: 400;
    line-heigth: 24px;

    padding: 0 ${(props) => props.theme.spacing(4)};
    &:hover {
      color: #ffffff;
      // font-weight: 600
    }
  }
`;
// font-size: ${(props) => props.theme.typography.body1.fontSize}px;
//
//  color: ${(props) =>
//       rgba(
//         props.theme.sidebar.color,
//         props.depth && props.depth > 0 ? 0.7 : 1
//       )};

const Badge = styled(Chip)`
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
  height: 20px;
  position: absolute;
  right: 26px;
  top: 12px;
  background: ${(props) => props.theme.sidebar.badge.background};
  z-index: 1;
  span.MuiChip-label,
  span.MuiChip-label:hover {
    font-size: 11px;
    cursor: pointer;
    color: ${(props) => props.theme.sidebar.badge.color};
    padding-left: ${(props) => props.theme.spacing(2)};
    padding-right: ${(props) => props.theme.spacing(2)};
  }
`;

const ExpandLessIcon = styled(ExpandLess)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const ExpandMoreIcon = styled(ExpandMore)`
  color: ${(props) => rgba(props.theme.sidebar.color, 0.5)};
`;

const SidebarNavListItem = (props) => {
  const {
    title,
    href,
    depth = 0,
    children,
    icon: Icon,
    badge,
    open: openProp = false,
  } = props;

  //   const {user}  = useAuth()
  // const {id} = user.companyId

  const [open, setOpen] = React.useState(openProp);

  const { t } = useTranslation();

  const handleToggle = () => {
    setOpen((state) => !state);
  };

  if (children) {
    return (
      <React.Fragment>
        <Item depth={depth} onClick={handleToggle}>
          {Icon && <Icon />}
          <Title depth={depth}>
            {t(title)}
            {badge && <Badge label={badge} />}
          </Title>
          {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Item>
        <Collapse in={open}>{children}</Collapse>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Item
        depth={depth}
        component={CustomRouterLink}
        to={href}
        activeclassname="active"
      >
        {Icon && <Icon />}
        <Title depth={depth}>
          {t(title)}
          {badge && <Badge label={badge} />}
        </Title>
      </Item>
    </React.Fragment>
  );
};

export default SidebarNavListItem;

import React, { useState, useEffect } from "react";
import styled from "styled-components/macro";
import ReconnectCard from "../../../../components/CustomComponents/Cards/ReconnectCard";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Grid,
  Typography as MuiTypography,
  Box,
  Paper,
  Stack,
  tooltipClasses,
  Tooltip,
} from "@mui/material";
import { useLocation } from "react-router-dom";
import { api } from "../../../../contexts/JWTContext";
import { spacing } from "@mui/system";
import { makeStyles } from "@mui/styles";
import { alpha } from "@material-ui/core/styles";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import Loader from "../../../../components/Loaders/Loader";
import AccountsCard from "./AccountsCard";
import FacebookAccountsCard from "./FacebookCard";
import { useNavigate } from "react-router-dom";

import { useStyles } from "../../../../styles/style";
const Typography = styled(MuiTypography)(spacing);

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

function Products({
  allSubscriptions,
  getLocationById,
  getLocationId,
  setIsLoading,
  isLoading,
  singleLocation,
  /*  locationSelectedSubscription,
  getAllSubscriptions,
  subscriptionArr,
  setSubscriptionArr, */
  addPermission,
  editPermission,
  deletePermission,
  viewPermission,
}) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [googleConnect, setGoogleConnect] = useState(null);
  const [facebookConnect, setFacebookConnect] = useState(null);
  const [displayFacebookIcon, setDisplayFacebookIcon] = useState(false);
  const [confirmSubscription, setConfirmSubscription] = useState(false);

  useEffect(() => {
    if (singleLocation?.enableFacebook === true) {
      setDisplayFacebookIcon(true);
    }
    singleLocation?.socialLink?.length > 0 &&
      singleLocation?.socialLink?.forEach((element) => {
        if (element?.type === "google") {
          setGoogleConnect(element);
        }
        if (element?.type === "facebook") {
          setFacebookConnect(element);
        }
      });
  }, [singleLocation]);
  const handleSubmit = () => {
    setConfirmSubscription(true);
  };
  const onCancel = () => {
    navigate("/user/locations");
  };
  /*   const handleSubscriptionClose = () => {
    setConfirmSubscription(false);
    //  setSubBoxOpen(false);
  }; */

  /*  const updateSubscriptionHandler = async () => {
    setIsLoading(true);

    try {
      const res = await api.post(`/locationSubscription/bulk`, {
        locationId: getLocationId,
        subscriptionId: subscriptionArr,
      });
      if (res.data.status===true) {
        setSubscriptionArr([]);

        toast.success(res?.data?.message);
        //  getAllSubscriptions();

        getLocationById(getLocationId);
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setSubscriptionArr([]);

      setIsLoading(false);
    }

    // setSubBoxOpen(false);
  }; */

  return (
    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ width: "100%" }}>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <Box className={classes.MainboxProduct} component={Paper}>
            <>
              <Grid container spacing={2}>
                {displayFacebookIcon === true && (
                  <FacebookAccountsCard
                    socialLink={singleLocation?.socialLink}
                    id={singleLocation?.id}
                    placeId={singleLocation?.placeId}
                    setIsLoading={setIsLoading}
                    getLocationById={getLocationById}
                    location={location}
                    singleLocation={singleLocation}
                    addPermission={addPermission}
                    editPermission={editPermission}
                    deletePermission={deletePermission}
                    viewPermission={viewPermission}
                  />
                )}
              </Grid>
              {singleLocation !== null && (
                <Grid container spacing={2}>
                  {googleConnect !== null &&
                  googleConnect?.validToken === false &&
                  displayFacebookIcon === false ? (
                    <ReconnectCard
                      location={singleLocation}
                      displayReconnectButton={true}
                      addPermission={addPermission}
                      editPermission={editPermission}
                      deletePermission={deletePermission}
                      viewPermission={viewPermission}
                    />
                  ) : (
                    <AccountsCard
                      socialLink={singleLocation?.socialLink}
                      id={singleLocation?.id}
                      placeId={singleLocation?.placeId}
                      setIsLoading={setIsLoading}
                      getLocationById={getLocationById}
                      location={location}
                      addPermission={addPermission}
                      editPermission={editPermission}
                      deletePermission={deletePermission}
                      viewPermission={viewPermission}
                    />
                  )}
                </Grid>
              )}
              {/*  <Grid
                container
                spacing={2}
                className={classes.subscriptionProductContainer}
              >
                <Grid item xs={5} sm={5} md={5} lg={5}>
                  <Box sx={{ fontSize: "20px", fontWeight: "700" }}>
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Typography variant="h3">
                        {t("Products")} <br />
                      </Typography>
                    </Stack>

                    <Typography sx={{ fontSize: "14px" }}>
                      {t("sol_desc")}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={7} sm={7} md={7} lg={7}>
                  <Box direction="column" spacing={2}>
                    {allSubscriptions?.results?.map((subscription, index) => {
                      const result = locationSelectedSubscription.find(
                        (data) =>
                          data?.subscription?.id===subscription.id 
                      );
                      return (
                        <SubscriptionCard
                          item={subscription}
                          // allSubscription={false}
                          result={result}
                          getLocationById={getLocationById}
                          getLocationId={getLocationId}
                          setIsLoading={setIsLoading}
                          confirmSubscription={confirmSubscription}
                          handleSubscriptionClose={handleSubscriptionClose}
                          subscriptionArr={subscriptionArr}
                          setSubscriptionArr={setSubscriptionArr}
                          updateSubscriptionHandler={updateSubscriptionHandler}
                          addPermission={addPermission}
                          editPermission={editPermission}
                          deletePermission={deletePermission}
                          viewPermission={viewPermission}
                        />
                      );
                    })}
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        marginTop: "20px",
                      }}
                    >
                      <CommonButton
                        displayWhite="true"
                        onSubmit={onCancel}
                        //  disabled={isSubmitting}
                        label={t("Cancel")}
                      />
                      {editPermission && viewPermission ? (
                        <CommonButton
                          //   loading={isLoading}
                          type="submit"
                          //   disabled={isSubmitting}
                          label={"Update Subscription"}
                          onSubmit={handleSubmit}
                        />
                      ) : (
                        <BootstrapTooltip title={t("authorized_access")}>
                          <span>
                            <CommonButton
                              //   loading={isLoading}
                              type="submit"
                              disabled={true}
                              label={"Update Subscription"}
                              onSubmit={handleSubmit}
                            />
                          </span>
                        </BootstrapTooltip>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid> */}
            </>
          </Box>
        </>
      )}
    </Grid>
  );
}

export default Products;

import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import PostAIModal from "../../../../../../../components/Models/AIModal/ListingAIModelBulk";
import ListingsAIKeywordsModelBulk from "../../../../../../../components/Models/AIModal/ListingsAiKeywordsModelBulk";
import { Dialog, DialogActions } from "@mui/material";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { LoadingButton } from "@mui/lab";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { styled } from "@mui/system";
import { ReactComponent as AiLogoGrey } from "../../../../../../../assets/images/AIlogogrey.svg";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import Collapsible from "../../../../../../../components/CustomComponents/Collapsible";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DescriptionField from "./DescriptionField";
import KeywordField from "./KeywordField";
import ListingCustomBulkModal from "../../../../../../../components/Models/AIModal/ListingCustomBulkModal";
import ListingCustomKeywordBulkModal from "../../../../../../../components/Models/AIModal/ListingCustomKeywordBulkModal";
import { languagesData } from "../../../../../../../assets/defaultLanguage";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    placement="top-start"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CompanyDescription = ({
  locationDetailsData,
  onCancel,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedLocationsBulkUpdate,
  getLocationDetails,
  handleCloseFilterModel,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [AiContentDescription, setAiContentDescription] = useState("");
  const [generatedContent, setGeneratedContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [keywordLoading, setKeywordLoading] = useState(false);
  const [displayError, setDisplayError] = useState(false);
  const [contentRequired, setContentRequired] = useState(false);
  const [openDefaultModel, setOpenDefaultModel] = useState(false);
  const [openKeywordModel, setOpenKeywordModel] = useState(false);
  const [tags, setTags] = useState([]);
  const [aiTags, setAiTags] = useState([]);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [tagsAIRequired, setTagsAIRequired] = useState(false);
  const [tagsLimitError, setTagsLimitError] = useState(false);

  const [longDescription, setLongDescription] = useState([]);
  const [shortDescription, setShortDescription] = useState([]);
  const [keywords, setKeywords] = useState([]);

  const [locationKeywords, setLocationKeywords] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [openFrom, setOpenFrom] = useState(null);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const userData = JSON.parse(localStorage.getItem("user"));

  const [openBulkModal, setOpenBulkModal] = useState("");
  const [customDescription, setCustomDescription] = useState("");
  const [customKeyword, setCustomKeywords] = useState([]);
  const languageData = localStorage.getItem("i18nextLng");

  useEffect(
    (e) => {
      if (tags?.length > 20) {
        setTagsLimitError(true);
      } else {
        setTagsLimitError(false);
      }
    },
    [tags]
  );

  const handleOpenTemplateMenu = (key) => {
    setOpenDefaultModel(true);
    setOpenFrom(key);
  };
  const handleCloseTemplateMenu = () => {
    setOpenDefaultModel(false);
    // setOpenFrom(null);
  };
  const handleOpenKeywordMenu = () => {
    setOpenKeywordModel(true);
  };
  const handleCloseKeywordMenu = () => {
    setOpenKeywordModel(false);
  };
  const handleDisplayLanguageName = (langKey) => {
    const selectedLanguage = languagesData?.find(
      (language) => language?.value === langKey
    );
    return selectedLanguage?.language;
  };
  const generateContentKeywords = async () => {
    try {
      setKeywordLoading(true);
      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate.map((item) => {
          locationIds.push(item.id);
        });
      }
      const res = await api.get(
        `/locationListing/bulkGenerateKeywords?ids=${locationIds.join(",")}`
      );
      if (res.status === 200) {
        setLocationKeywords(res?.data?.data);
        setKeywordLoading(false);
      } else {
        toast.error("Something went wrong");
      }
    } catch (error) {
      setKeywordLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };

  const generateContent = async (data) => {
    if (AiContentDescription?.length === 0) {
      setContentRequired(true);
    } else {
      setContentRequired(false);
    }
    if (AiContentDescription?.length > 0) {
      try {
        setLoading(true);
        let locationIds = [];
        if (selectedLocationsBulkUpdate?.length > 0) {
          selectedLocationsBulkUpdate.map((item) => {
            locationIds.push(item.id);
          });
        }
        const res = await api.post(`/locationListing/bulkGenerateContent`, {
          locationIds: locationIds,
          prompt: `${data} \n\nGenerated response must be in ${userData?.language}`,
        });
        if (res.status === 200) {
          setLoading(false);
          setDisplayError(false);
          setGeneratedContent(res?.data?.data);
        } else {
          toast.error("Something went wrong");
        }
      } catch (error) {
        setDisplayError(true);
        setLoading(false);
        toast.error(error?.response?.data?.message);
      }
    }
  };
  const generateAIContent = () => {
    const str = "\n\nproduct:";
    const str2 = "\n\n";
    const joinedStr = AiContentDescription.concat(str);
    /* const finalStr = joinedStr tags.toString(); */
    let finalStr = "";
    if (tags?.length > 0) {
      finalStr = joinedStr + tags.toString();
    } else {
      finalStr = AiContentDescription.concat(str2);
    }

    generateContent(finalStr);
  };

  const handleSubmit = () => {
    handleCloseTemplateMenu();
    handleCloseKeywordMenu();
    setTouchedValue(true);
    if (openFrom !== null && openFrom === "long") {
      setLongDescription(generatedContent);
    } else {
      setShortDescription(generatedContent);
    }
  };

  const trimKeywords = (array1, array2) => {
    const mergedArray = array1.map((item1) => {
      const match = array2.find((item2) => item2.id === item1.id);
      if (match) {
        const combinedKeywords = [...item1.keywords, ...match.keywords];
        return {
          ...item1,
          keywords: combinedKeywords.slice(0, 20),
        };
      }
      return item1;
    });
    return mergedArray;
  };

  const handleSubmitKeywords = () => {
    setTouchedValue(true);
    handleCloseKeywordMenu();
    const updated = locationKeywords.map((item) => ({
      ...item,
      id: JSON.parse(item?.id),
      name: selectedLocationsBulkUpdate.filter(
        (x) => x.id === JSON.parse(item?.id)
      )[0]?.internalName,
    }));
    setKeywords(trimKeywords(keywords, updated));
  };

  const handleUpdateDescription = async () => {
    const descriptionData = selectedLocationsBulkUpdate.map((item) => ({
      id: item?.id,
      keywords: keywords.filter((x) => x.id === item.id)[0]?.keywords,
      shortDescription: shortDescription.filter((x) => x.id === item.id)[0]
        ?.content,
      profile: {
        description: longDescription.filter((x) => x.id === item.id)[0]
          ?.content,
      },
    }));

    try {
      setIsLoading(true);
      setDisplayConfirmationBox(false);
      const res = await api.patch(`/locationListing/bulkCompanyDescription`, {
        locations: descriptionData,
      });

      if (indexValue !== null) {
        setSelectedTab(indexValue);
      }

      if (cancelState === true) {
        onCancel();
        handleCloseFilterModel();
      }

      if (res.status === 200) {
        setIsLoading(false);
        setTouchedValue(false);
        toast.success("Updated Successfully");
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  useEffect(() => {
    const updated = selectedLocationsBulkUpdate.map((item) => ({
      id: item?.id,
      name: item?.internalName,
      content: "",
    }));
    const updatedKeywords = selectedLocationsBulkUpdate.map((item) => ({
      id: item?.id,
      name: item?.internalName,
      status: false,
      keywords: [],
    }));

    setLongDescription(updated);
    setShortDescription(updated);
    setKeywords(updatedKeywords);
  }, [selectedLocationsBulkUpdate]);

  const handleOpenBulkMenu = (section) => {
    setOpenBulkModal(section);
  };

  const handleDescriptions = () => {
    if (openBulkModal === "short") {
      setShortDescription(
        shortDescription.map((item) => ({
          ...item,
          content: customDescription,
        }))
      );
      setOpenBulkModal("");
      setCustomDescription("");
    } else if (openBulkModal === "long") {
      setLongDescription(
        longDescription.map((item) => ({
          ...item,
          content: customDescription,
        }))
      );
      setOpenBulkModal("");
      setCustomDescription("");
    }
  };

  const handleKeywords = () => {
    if (openBulkModal === "keyword") {
      const updated = selectedLocationsBulkUpdate.map((item) => ({
        id: item?.id,
        name: item?.internalName,
        status: false,
        keywords: customKeyword,
      }));
      setKeywords(trimKeywords(keywords, updated));
      setOpenBulkModal("");
      setCustomKeywords([]);
    }
  };

  return (
    <>
      {openBulkModal !== "" && (
        <Dialog maxWidth maxHeight open={openBulkModal !== ""}>
          <Box sx={{ minHeight: "450px", overflow: "auto" }}>
            {openBulkModal === "keyword" ? (
              <ListingCustomKeywordBulkModal
                title={t("Keyword_Input")}
                subTitle={t("keyword_sub_title")}
                placeHolder={t("Keyword_Input")}
                length={20}
                onCancel={() => setOpenBulkModal("")}
                keywords={customKeyword}
                setKeywords={setCustomKeywords}
                setTouchedValue={setTouchedValue}
              />
            ) : (
              <ListingCustomBulkModal
                title={
                  openBulkModal === "short"
                    ? `${t("Add_label")} ${t("Short_Description")}`
                    : openBulkModal === "long"
                    ? `${t("Add_label")} ${t("Long_Description")}`
                    : ""
                }
                subTitle={
                  openBulkModal === "short"
                    ? t("short_sub_title")
                    : openBulkModal === "long"
                    ? t("long_sub_title")
                    : ""
                }
                placeHolder={
                  openBulkModal === "short"
                    ? t("short_des_placeholder")
                    : openBulkModal === "long"
                    ? t("long_des_placeholder")
                    : ""
                }
                length={
                  openBulkModal === "short"
                    ? 200
                    : openBulkModal === "long"
                    ? 750
                    : 0
                }
                onCancel={() => setOpenBulkModal("")}
                description={customDescription}
                setDescription={setCustomDescription}
                setTouchedValue={setTouchedValue}
              />
            )}
          </Box>
          <Divider />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                <LoadingButton
                  onClick={() => setOpenBulkModal("")}
                  type="submit"
                  fullWidth
                  variant="outlined"
                  disabled={loading}
                  className={classes.postLoadingButton}
                >
                  {t("Cancel")}
                </LoadingButton>

                <LoadingButton
                  onClick={() => {
                    if (openBulkModal === "keyword") {
                      handleKeywords();
                    } else {
                      handleDescriptions();
                    }
                  }}
                  disabled={
                    openBulkModal === "keyword"
                      ? customKeyword.length === 0
                        ? true
                        : false
                      : customDescription.length === 0
                      ? true
                      : false
                  }
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.postSubmitButton}
                >
                  {t("Submit")}
                </LoadingButton>
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {openDefaultModel && (
        <Dialog maxWidth maxHeight open={openDefaultModel}>
          <Box sx={{ minHeight: "450px", overflow: "auto" }}>
            <PostAIModal
              title={t("AI_Writer")}
              onCancel={handleCloseTemplateMenu}
              aiTags={aiTags}
              setAiTags={setAiTags}
              displayError={displayError}
              AiContentDescription={AiContentDescription}
              setAiContentDescription={setAiContentDescription}
              locationDetailsData={locationDetailsData}
              generatedContent={generatedContent}
              setGeneratedContent={setGeneratedContent}
              setTagsRequired={setTagsRequired}
              setContentRequired={setContentRequired}
              contentRequired={contentRequired}
              openFrom={openFrom}
              setTouchedValue={setTouchedValue}
            />
          </Box>
          <Divider />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {generatedContent?.length > 0 && (
                  <LoadingButton
                    loading={loading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={loading}
                    onClick={() => generateAIContent()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}
                {generatedContent?.length === 0 && (
                  <LoadingButton
                    loading={loading}
                    onClick={() => generateAIContent()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Mee")}
                  </LoadingButton>
                )}
                {generatedContent?.length > 0 && (
                  <LoadingButton
                    // loading={loading}
                    onClick={() => handleSubmit()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={loading}
                    className={classes.postSubmitButton}
                  >
                    {t("Submit")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      {openKeywordModel && (
        <Dialog maxWidth maxHeight open={openKeywordModel}>
          <Box sx={{ minHeight: "450px", overflow: "auto" }}>
            <ListingsAIKeywordsModelBulk
              title={t("AI_Writer")}
              onCancel={handleCloseKeywordMenu}
              tags={tags}
              setTags={setTags}
              displayError={displayError}
              AiContentDescription={AiContentDescription}
              setAiContentDescription={setAiContentDescription}
              locationDetailsData={locationDetailsData}
              generateContentKeywords={generateContentKeywords}
              locationKeywords={locationKeywords}
              setLocationKeywords={setLocationKeywords}
              setGeneratedContent={setGeneratedContent}
              setTagsRequired={setTagsRequired}
              tagsAIRequired={tagsAIRequired}
              setContentRequired={setContentRequired}
              contentRequired={contentRequired}
              selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
              setTouchedValue={setTouchedValue}
            />
          </Box>
          <Divider />
          <DialogActions className={classes.postDialog}>
            <Grid container>
              <Grid item xs={8} sm={8} md={8} lg={8}></Grid>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{ display: "flex", justifyContent: "flex-end" }}
              >
                {locationKeywords?.length > 0 && (
                  <LoadingButton
                    loading={keywordLoading}
                    type="submit"
                    fullWidth
                    variant="outlined"
                    disabled={keywordLoading}
                    onClick={() => generateContentKeywords()}
                    className={classes.postLoadingButton}
                  >
                    <RefreshIcon />
                  </LoadingButton>
                )}

                {locationKeywords?.length === 0 && (
                  <LoadingButton
                    loading={keywordLoading}
                    onClick={() => generateContentKeywords()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={keywordLoading}
                    className={classes.PostAiButton}
                  >
                    {t("Write_For_Mee")}
                  </LoadingButton>
                )}

                {locationKeywords?.length > 0 && (
                  <LoadingButton
                    // loading={keywordLoading}
                    onClick={() => handleSubmitKeywords()}
                    fullWidth
                    variant="contained"
                    color="primary"
                    disabled={keywordLoading}
                    className={classes.postSubmitButton}
                  >
                    {t("Done")}
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          </DialogActions>
        </Dialog>
      )}
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Grid container sx={{ padding: "18px" }}>
          <Grid
            item
            xs={10}
            sm={11}
            md={11.5}
            lg={11.5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.modalHeader}>
              {t("Company_Desc")}
            </Typography>
            <BootstrapTooltip title={t("Company_Desc_Subhead")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
            <IconButton
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <Grid container className={classes.gridContainertwoBulk}>
          {displayConfirmationBox && (
            <Dialog
              open={displayConfirmationBox}
              onClose={handleClose}
              aria-labelledby="responsive-dialog-title"
            >
              <ConfirmModal
                title={t("Delete_Bulk_Posts")}
                description={t("Delete_post_subhead")}
                onConfirm={handleUpdateDescription}
                onClose={handleClose}
                onCancel={onCancel}
                indexValue={indexValue}
                setSelectedTab={setSelectedTab}
                cancelState={cancelState}
                touchedValue={touchedValue}
                setTouchedValue={setTouchedValue}
                getLocationDetails={getLocationDetails}
              />
            </Dialog>
          )}
          {isLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                sx={{ padding: "18px", paddingTop: "50px", width: "100%" }}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <Grid container sx={{ padding: "16px" }}>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Collapsible
                    title={t("add_Keyword")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("keyword")}
                    id={"keyword"}
                    defaultPreventions={true}
                    ComponentToRender={
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <BootstrapTooltip
                            title={`${t("add_Keyword")} ${t(
                              "manual_selection"
                            )}`}
                          >
                            <IconButton
                              onClick={() => handleOpenBulkMenu("keyword")}
                              sx={{ padding: "0px" }}
                            >
                              <AddBoxOutlinedIcon
                                style={{ cursor: "pointer" }}
                                sx={{ color: "#06BDFF", fontSize: "30px" }}
                              />
                            </IconButton>
                          </BootstrapTooltip>
                          <BootstrapTooltip title={t("A.I_keywords")}>
                            <IconButton
                              onClick={() => handleOpenKeywordMenu()}
                              sx={{ padding: "0px" }}
                            >
                              <AiLogoGrey style={{ cursor: "pointer" }} />
                            </IconButton>
                          </BootstrapTooltip>
                        </Box>
                        {keywords?.map((item, index) => (
                          <KeywordField
                            key={index}
                            data={item}
                            setTouchedValue={setTouchedValue}
                            setKeywordsMain={setKeywords}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Collapsible
                    title={t("Short_Description")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("short")}
                    id={"short"}
                    defaultPreventions={true}
                    displayInfoIcon={true}
                    infoText={t("Short_Description_tooltip")}
                    ComponentToRender={
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <BootstrapTooltip
                            title={`${t("Add_label")} ${t(
                              "Short_Description"
                            )} ${t("manual_selection")}`}
                          >
                            <IconButton
                              onClick={() => handleOpenBulkMenu("short")}
                              sx={{ padding: "0px" }}
                            >
                              <AddBoxOutlinedIcon
                                style={{ cursor: "pointer" }}
                                sx={{ color: "#06BDFF", fontSize: "30px" }}
                              />
                            </IconButton>
                          </BootstrapTooltip>
                          <BootstrapTooltip title={t("A.I_desc_short")}>
                            <IconButton
                              onClick={() => handleOpenTemplateMenu("short")}
                              sx={{ padding: "0px" }}
                            >
                              <AiLogoGrey style={{ cursor: "pointer" }} />
                            </IconButton>
                          </BootstrapTooltip>
                        </Box>
                        {shortDescription.map((item, index) => (
                          <DescriptionField
                            length={200}
                            rows={3}
                            data={item}
                            label={item?.name}
                            placeHolder={`${t("Add_label")} ${t(
                              "Short_Description"
                            )}`}
                            errorText={t("Text_Limit_Short_Description")}
                            description={shortDescription}
                            setDescription={setShortDescription}
                            setTouchedValue={setTouchedValue}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
                <Grid
                  item
                  md={12}
                  sm={12}
                  xs={12}
                  lg={12}
                  sx={{ marginTop: "12px" }}
                >
                  <Collapsible
                    title={t("Long_Description")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("long")}
                    id={"long"}
                    defaultPreventions={true}
                    displayInfoIcon={true}
                    infoText={t("Long_Description_tooltip")}
                    ComponentToRender={
                      <>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "center",
                            gap: 2,
                          }}
                        >
                          <BootstrapTooltip
                            title={`${t("Add_label")} ${t(
                              "Long_Description"
                            )} ${t("manual_selection")}`}
                          >
                            <IconButton
                              onClick={() => handleOpenBulkMenu("long")}
                              sx={{ padding: "0px" }}
                            >
                              <AddBoxOutlinedIcon
                                style={{ cursor: "pointer" }}
                                sx={{ color: "#06BDFF", fontSize: "30px" }}
                              />
                            </IconButton>
                          </BootstrapTooltip>
                          <BootstrapTooltip title={t("A.I_desc")}>
                            <IconButton
                              onClick={() => handleOpenTemplateMenu("long")}
                              sx={{ padding: "0px" }}
                            >
                              <AiLogoGrey style={{ cursor: "pointer" }} />
                            </IconButton>
                          </BootstrapTooltip>
                        </Box>
                        {longDescription.map((item, index) => (
                          <DescriptionField
                            length={750}
                            rows={8}
                            data={item}
                            label={item?.name}
                            placeHolder={`${t("Add_label")} ${t(
                              "Long_Description"
                            )}`}
                            errorText={t("Text_Limit_Description")}
                            description={longDescription}
                            setDescription={setLongDescription}
                            setTouchedValue={setTouchedValue}
                          />
                        ))}
                      </>
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Back")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={isLoading || tagsLimitError || tagsRequired}
            onSubmit={handleUpdateDescription}
            label={t("Save")}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default CompanyDescription;

import React, { useEffect, useState, useRef } from "react";

import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
  DialogActions,
  DialogContent,
  Box,
  TextField,
  Dialog,
  Autocomplete,
} from "@mui/material";
import "./model.css";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import { makeStyles } from "@mui/styles";
import { api } from "../../../../contexts/JWTContext";
import ReviewModel from "./ReviewModel";
import { languagesData } from "../../../../assets/defaultLanguage";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    paddingTop: "10px",
    paddingLeft: "20px",
    paddingRight: "20px",
    paddingBottom: "20px",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
    paddingBottom: "8px",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
}));

const PostAIModal = ({
  open,
  handleCloseTestModel,
  textPresentPrompt,
  setTextPresentGeneratedContent,
  textPresentGeneratedContent,
  reviewsData,
  dummyReviewsData,
  allReviewSource,
  selectedTab,
  selectedRating,
  handleDisplayNames,
  defaultLocation,
  getLocation,
  present,
  signatureValue,
  selectedLanguage,
  handleDisplayLanguageName,
  customLengthValue,
  nameOption,
  tags,
  extraData,
  emailValue,
  signatureTextValue,
  pronounOfPrompt,
  responseLength,
  phrase,
  toneOfPrompt,
  languageOfPrompt,
  signatureOfPrompt,
  languageName,
}) => {
  const classes = useStyles();
  const gridRef = useRef();
  const { t } = useTranslation();

  const [ratingValue, setRatingValue] = useState(null);
  const [reviewToReplace, setReviewToReplace] = useState("");
  const [promptToTest, setPromptToTest] = useState(textPresentPrompt);
  const [reviewValue, setReviewValue] = useState("");
  const [testLoading, setTestLoading] = useState(false);
  const [openReviewModel, setOpenReviewModel] = useState(false);
  const [selectedReviewData, setSelectedReviewData] = useState(null);
  const [defaultTestPrompt, setDefaultTestPrompt] = useState("");
  const [propmptProperties, setPromptProperties] = useState({
    phraseOfPrompt: phrase,
    keywords: tags,
    extraData: extraData,
    languageName: languageName,
    toneOfPrompt: toneOfPrompt,
    businessEmail: emailValue,
    signatureText: signatureTextValue,
    nameOfCustomer:
      nameOption === "first"
        ? "firstName"
        : nameOption === "full"
        ? "fullName"
        : "Guest",
    responseLength: responseLength,
    pronounOfPrompt: pronounOfPrompt,
    languageOfPrompt: languageOfPrompt,
    signatureOfPrompt: signatureOfPrompt,
  });

  const userData = JSON.parse(localStorage.getItem("user"));

  useEffect(() => {
    if (reviewValue !== "") {
      const delayDebounceFn = setTimeout(() => {
        handleChangeReviewsInPrompt(reviewValue, selectedReviewData);
      }, 500);

      return () => clearTimeout(delayDebounceFn);
    }
  }, [reviewValue]);

  useEffect(() => {
    if (open) {
      handlePromptSetOnOpen();
    }
  }, [open]);

  function numberToWords(num) {
    if (num === 0) return "zero";

    const belowTwenty = [
      "zero",
      "one",
      "two",
      "three",
      "four",
      "five",
      "six",
      "seven",
      "eight",
      "nine",
      "ten",
      "eleven",
      "twelve",
      "thirteen",
      "fourteen",
      "fifteen",
      "sixteen",
      "seventeen",
      "eighteen",
      "nineteen",
    ];
    const tens = [
      "",
      "",
      "twenty",
      "thirty",
      "forty",
      "fifty",
      "sixty",
      "seventy",
      "eighty",
      "ninety",
    ];

    if (num < 20) {
      return belowTwenty[num];
    } else if (num < 100) {
      let result = tens[Math.floor(num / 10)];
      if (num % 10 !== 0) {
        result += "-" + belowTwenty[num % 10];
      }
      return result;
    } else {
      return "Number too large for this function";
    }
  }

  const handlePromptSetOnOpen = () => {
    let formattedStr = textPresentPrompt;
    if (formattedStr?.includes("A customer name")) {
      formattedStr = formattedStr?.replace("A customer name", "A customer");
    }
    if (formattedStr?.includes("{{-selectedExtras-}}")) {
      formattedStr = formattedStr?.replace("{{-selectedExtras-}}", "");
    }
    if (
      formattedStr?.includes("{{-nameOfCustomer-}}") &&
      nameOption === "full"
    ) {
      formattedStr = formattedStr?.replace(
        "A customer {{-nameOfCustomer-}} has",
        `A customer ${
          userData?.firstName.toLowerCase() +
          " " +
          userData?.lastName.toLowerCase()
        }`
      );
    } else if (formattedStr?.includes("{{-fullName-}}")) {
      formattedStr = formattedStr?.replace(
        "A customer {{-fullName-}} has",
        `A customer ${
          userData?.firstName.toLowerCase() +
          " " +
          userData?.lastName.toLowerCase()
        }`
      );
    } else if (formattedStr?.includes("{{-firstName-}}")) {
      formattedStr = formattedStr?.replace(
        "A customer {{-firstName-}} has",
        `A customer ${userData?.firstName.toLowerCase()}`
      );
    } else if (
      formattedStr?.includes("{{-nameOfCustomer-}}") &&
      nameOption === "first"
    ) {
      formattedStr = formattedStr?.replace(
        "A customer {{-nameOfCustomer-}} has",
        `A customer ${userData?.firstName.toLowerCase()}`
      );
    } else if (
      formattedStr?.includes("{{-nameOfCustomer-}}") &&
      nameOption === "guest"
    ) {
      formattedStr = formattedStr?.replace(
        "A customer {{-nameOfCustomer-}} has",
        `A valued guest has left a review`
      );
    } else if (formattedStr?.includes("{{-Guest-}}")) {
      formattedStr = formattedStr?.replace(
        "A customer {{-Guest-}}",
        `A valued guest has left a review`
      );
    }
    if (formattedStr?.includes("{{-responseLength-}}")) {
      let numberValue = numberToWords(customLengthValue);
      formattedStr = formattedStr?.replace("{{-responseLength-}}", numberValue);
    }

    if (
      formattedStr?.includes("{{-locationName-}}") &&
      formattedStr?.includes("{{-cityName-}}")
    ) {
      formattedStr = formattedStr?.replace(
        "{{-locationName-}}",
        defaultLocation?.internalName
      );
      formattedStr = formattedStr?.replace(
        "{{-cityName-}}",
        defaultLocation?.city
      );
    }

    if (
      defaultLocation?.businessEmail !== null &&
      formattedStr?.includes("{{businessEmail}}")
    ) {
      formattedStr = formattedStr.replace(
        "{{businessEmail}}",
        defaultLocation?.businessEmail !== null
          ? defaultLocation?.businessEmail
          : "{{businessEmail}}"
      );
    }
    if (formattedStr?.includes("{{-singularPronoun-}}")) {
      formattedStr = formattedStr.replace("{{-singularPronoun-}}", "I");
    } else if (formattedStr?.includes("{{-pluralPronoun-}}")) {
      formattedStr = formattedStr.replace("{{-pluralPronoun-}}", "We");
    }

    if (formattedStr?.includes("{{-formalTone-}}")) {
      formattedStr = formattedStr?.replace("{{-formalTone-}}", "formal tone");
    } else if (formattedStr?.includes("{{-friendlyTone-}}")) {
      formattedStr = formattedStr?.replace(
        "{{-friendlyTone-}}",
        "friendly tone"
      );
    } else if (formattedStr?.includes("{{-reviewTone-}}")) {
      formattedStr = formattedStr?.replace("{{-reviewTone-}}", "review tone");
    }

    /*   if (formattedStr?.includes("{{-shortResponse-}}")) {
      formattedStr = formattedStr?.replace("to {{-shortResponse-}}", "short");
    } else if (formattedStr?.includes("{{-detailedResponse-}}")) {
      formattedStr = formattedStr?.replace(
        "to {{-detailedResponse-}}",
        "detailed"
      );
    } */

    if (formattedStr?.includes("{{-excludeSignature-}}")) {
      formattedStr = formattedStr?.replace(
        "{{-excludeSignature-}}",
        "with out signatures"
      );
    } else if (formattedStr?.includes("{{-includeSignature-}}")) {
      formattedStr = formattedStr?.replace(
        "{{-includeSignature-}}",
        `include these signature: ${
          signatureValue !== null ? "'" + signatureValue + "'" : ""
        }`
      );
    }

    if (formattedStr?.includes("{{-includePhrase-}}")) {
      formattedStr = formattedStr?.replace("{{-includePhrase-}}", "include");
    } else if (formattedStr?.includes("{{-excludePhrase-}}")) {
      formattedStr = formattedStr?.replace("{{-excludePhrase-}}", "exclude");
    }

    if (formattedStr?.includes("{{-reviewLanguage-}}")) {
      formattedStr = formattedStr?.replace(
        "{{-reviewLanguage-}}",
        "review language"
      );
    } else if (formattedStr?.includes("{{-locationLanguage-}}")) {
      formattedStr = formattedStr?.replace(
        "{{-locationLanguage-}}",
        handleDisplayLanguageName(defaultLocation?.defaultLanguage)
      );
    } else if (formattedStr?.includes("{{-multipleLanguage-}}")) {
      let langString = [];
      let userSelectedLangs = [];
      languagesData.forEach((language) => {
        selectedLanguage?.forEach((item) => {
          if (item?.language === language?.language) {
            userSelectedLangs?.push(language);
          }
        });
      });
      langString = userSelectedLangs?.map((item) => item?.language);
      formattedStr = formattedStr.replace(
        "polite and constructive response to this rating in {{-multipleLanguage-}}",
        `polite and constructive response to this rating in review language if review language matches with one of the following languages ${
          langString?.length > 0 ? langString?.toString() : ""
        }.  otherwise provide response in ${handleDisplayLanguageName(
          defaultLocation?.defaultLanguage
        )}`
      );

      /*  formattedStr = formattedStr?.replace(
        "constructive response to this rating in {{-MultipleLanguage-}}",
        handleDisplayLanguageName(defaultLocation?.defaultLanguage)
      ); */
    }
    formattedStr = formattedStr.trim().replace(/\s+/g, " ");

    setPromptToTest(formattedStr);
    setDefaultTestPrompt(formattedStr);

    if (selectedRating === "one") {
      setRatingValue({ id: 1, name: t("1_star"), value: 1 });
      handleChangeRatingOnOpen(1, formattedStr);
    } else if (selectedRating === "two") {
      setRatingValue({ id: 2, name: t("2_star"), value: 2 });
      handleChangeRatingOnOpen(2, formattedStr);
    } else if (selectedRating === "three") {
      setRatingValue({ id: 3, name: t("3_star"), value: 3 });
      handleChangeRatingOnOpen(3, formattedStr);
    } else if (selectedRating === "four") {
      setRatingValue({ id: 4, name: t("4_star"), value: 4 });
      handleChangeRatingOnOpen(4, formattedStr);
    } else if (selectedRating === "five") {
      setRatingValue({
        id: 5,
        name: t("5_star"),
        value: 5,
      });
      handleChangeRatingOnOpen(5, formattedStr);
    }
  };

  const starRatings = [
    { id: 1, name: t("1_star"), value: 1 },
    { id: 2, name: t("2_star"), value: 2 },
    { id: 3, name: t("3_star"), value: 3 },
    { id: 4, name: t("4_star"), value: 4 },
    { id: 5, name: t("5_star"), value: 5 },
  ];
  const handleReset = () => {
    setTextPresentGeneratedContent("");
    // setRatingValue(selectedRating);
    setReviewValue("");
    setReviewToReplace("");
    setPromptToTest(defaultTestPrompt);
    setSelectedReviewData(null);
    handlePromptSetOnOpen();
    setRatingValue(null);
  };

  const handleChangeRatingOnOpen = (event, stringValue) => {
    let value = "";

    if (event === 1) {
      value = 1;
    } else if (event === 2) {
      value = 2;
    } else if (event === 3) {
      value = 3;
    } else if (event === 4) {
      value = 4;
    } else if (event === 5) {
      value = 5;
    }
    const matchedItem = starRatings?.find((item) => item?.value === value);

    let newStr = stringValue;

    if (newStr?.includes("{{-ratingStars-}}")) {
      newStr = newStr?.replaceAll("{{-ratingStars-}}", matchedItem?.name);
      //  setPromptToTest(str);
    } else {
      starRatings?.forEach((item) => {
        if (newStr?.includes(item?.name)) {
          newStr = newStr?.replaceAll(item?.name, value);
          /*     setPromptToTest((prevPrompt) =>
            prevPrompt.replaceAll(item?.name, value)
          ); */
        }
      });
    }

    if (defaultLocation !== null) {
      if (newStr?.includes("{{-locationName-}}")) {
        newStr = newStr.replace("{{-locationName-}}", defaultLocation?.name);
        newStr = newStr.replace(
          "{{-cityName-}}",
          defaultLocation?.city !== null
            ? defaultLocation?.city
            : "{{-cityName-}}"
        );
        if (defaultLocation?.businessEmail !== null) {
          newStr = newStr.replace(
            "{{businessEmail}}",
            defaultLocation?.businessEmail !== null
              ? defaultLocation?.businessEmail
              : "{{businessEmail}}"
          );
        }
      }
    }
    setPromptToTest(newStr);

    //  setRatingValue(value);
  };
  const handleChangeRating = (event) => {
    const value = event?.value;
    const matchedItem = starRatings.find((item) => item?.value === value);

    if (promptToTest?.includes("{{-ratingStars-}}")) {
      let str = promptToTest.replaceAll("{{-ratingStars-}}", matchedItem?.name);
      setPromptToTest(str);
    } else {
      starRatings?.forEach((item) => {
        if (promptToTest?.includes(item?.name)) {
          let str = promptToTest.replaceAll(item?.value, value);
          setPromptToTest(str);
        }
      });
    }

    setRatingValue(event);
  };

  const handleChangeReviewsInPrompt = (review, item) => {
    let reviewerFirstName = selectedReviewData?.reviewerTitle.split(" ");
    let value = review;

    if (value?.includes("(Original)")) {
      let splittedText = value.split("(Original)");
      value = splittedText[1];
    }
    let str = promptToTest;

    if (item !== null) {
      const starValue = item?.ratingValue;
      const matchedItem = starRatings.find((item) => item?.value === starValue);

      if (starValue === 1) {
        setRatingValue({ id: 1, name: t("1_star"), value: 1 });
      } else if (starValue === 2) {
        setRatingValue({ id: 2, name: t("2_star"), value: 2 });
      } else if (starValue === 3) {
        setRatingValue({ id: 3, name: t("3_star"), value: 3 });
      } else if (starValue === 4) {
        setRatingValue({ id: 4, name: t("4_star"), value: 4 });
      } else if (starValue === 5) {
        setRatingValue({
          id: 5,
          name: t("5_star"),
          value: 5,
        });
      }
      if (str?.includes("{{-ratingStars-}}")) {
        str = str.replaceAll("{{-ratingStars-}}", matchedItem?.name);
      } else {
        starRatings?.forEach((item) => {
          if (str?.includes(item?.name)) {
            str = str.replaceAll(item?.value, starValue);
          }
        });
      }
    }

    if (str?.includes("{{-reviewText-}}")) {
      str = str.replace("{{-reviewText-}}", value);
    } else if (str?.includes(reviewToReplace)) {
      str = str.replace(reviewToReplace, value);
    }

    if (item !== null) {
      if (str?.includes("{{-fullName-}}")) {
        str = str.replace(
          "{{-fullName-}}",
          item !== null
            ? item?.reviewerTitle
            : userData?.firstName.toLowerCase() +
                " " +
                userData?.lastName.toLowerCase()
        );
      } else if (str?.includes("{{-firstName-}}")) {
        let name = item?.reviewerTitle.split(" ");
        str = str.replace(
          "{{-firstName-}}",
          name?.length > 0
            ? name[0]
            : userData?.firstName.toLowerCase() +
                " " +
                userData?.lastName.toLowerCase()
        );
      } else if (
        str
          ?.toLowerCase()
          .includes(
            userData?.firstName.toLowerCase() +
              " " +
              userData?.lastName.toLowerCase()
          ) &&
        userData?.lastName !== "" &&
        userData !== null
      ) {
        str = str.replace(
          `A customer ${
            userData?.firstName.toLowerCase() +
            " " +
            userData?.lastName.toLowerCase()
          }`,
          item !== null
            ? `A customer ${item?.reviewerTitle}`
            : `A customer ${
                userData?.firstName.toLowerCase() +
                " " +
                userData?.lastName.toLowerCase
              }`
        );
      } else if (
        str?.includes(userData?.firstName.toLowerCase()) &&
        (str?.includes(userData?.lastName) === false ||
          userData?.lastName === null ||
          userData?.lastName === "") &&
        nameOption === "first"
      ) {
        let name = item?.reviewerTitle.split(" ");

        str = str.replace(
          `A customer ${userData?.firstName.toLowerCase()}`,
          name?.length > 0
            ? `A customer ${name[0]}`
            : `A customer ${userData?.firstName.toLowerCase()}`
        );
      } else if (
        str.includes(userData?.firstName.toLowerCase()) &&
        (str.includes(userData?.lastName.toLowerCase()) === false ||
          userData?.lastName === null ||
          userData?.lastName === "") &&
        nameOption === "full"
      ) {
        str = str.replace(
          `A customer ${userData?.firstName.toLowerCase()}`,
          `A customer ${item?.reviewerTitle}`
        );
      } else if (str?.includes(selectedReviewData?.reviewerTitle)) {
        str = str.replace(
          `A customer ${selectedReviewData?.reviewerTitle}`,
          `A customer ${item?.reviewerTitle}`
        );
      } else if (
        str?.includes(
          reviewerFirstName?.length > 0 ? reviewerFirstName[0] : ""
        ) &&
        nameOption === "first"
      ) {
        let name = item?.reviewerTitle.split(" ");
        str = str.replace(
          `A customer ${reviewerFirstName[0]}`,
          `A customer ${name?.length > 0 ? name[0] : ""}`
        );
      }
    }

    if (item !== null && selectedReviewData !== null) {
      if (str?.includes(defaultLocation?.name)) {
        str = str.replace(
          defaultLocation?.name,
          handleDisplayNames(item?.locationId)
        );
      } else if (
        str?.includes(getLocation(selectedReviewData?.locationId).name)
      ) {
        str = str.replace(
          getLocation(selectedReviewData?.locationId).name,
          handleDisplayNames(item?.locationId)
        );
      }
    } else {
      if (str?.includes(defaultLocation?.name)) {
        str = str.replace(
          defaultLocation?.name,
          handleDisplayNames(defaultLocation?.id)
        );
      } else if (str?.includes(getLocation(defaultLocation?.locationId).name)) {
        str = str.replace(
          getLocation(defaultLocation?.locationId).name,
          handleDisplayNames(item?.locationId)
        );
      }
    }
    setPromptToTest(str);
    setSelectedReviewData(item);
    setReviewToReplace(value);
  };

  const handleTestPrompt = async () => {
    setTestLoading(true);
    try {
      const res = await api.patch(`review/reply`, {
        ratingText: reviewValue,
        promptWithOutTextProperties: present === false ? propmptProperties : {},
        promptWithTextProperties: present === true ? propmptProperties : {},
        reviewerTitle:
          present === true
            ? selectedReviewData?.reviewerTitle
            : `${userData?.firstName + " " + userData?.lastName}`,
        ratingValue: ratingValue?.value,
        location: {
          id: defaultLocation?.id,
          name: defaultLocation?.internalName,
          defaultLanguage: defaultLocation?.defaultLanguage,
          city: defaultLocation?.city,
          businessEmail: defaultLocation?.businessEmail,
          category: defaultLocation?.category,
        },
      });
      if (res.status === 200) {
        setTextPresentGeneratedContent(res?.data?.data?.reply);
        scrollDown();
        setTestLoading(false);
      }
    } catch (error) {
      setTestLoading(false);
      toast.error(error?.response?.data?.message);
    }
  };
  const scrollDown = () => {
    if (gridRef.current) {
      const grid = gridRef.current;
      grid.scrollTop = grid.scrollHeight - grid.clientHeight;
    }
  };

  const handleCloseReviewModel = async () => {
    setOpenReviewModel(false);
  };
  const handleOpenReviewModel = async () => {
    setOpenReviewModel(true);
  };

  return (
    <>
      <Dialog
        maxWidth
        maxHeight
        open={open}
        // onClose={onCancel}
        sx={{ "& .MuiDialog-paper": { width: "850px", height: "700px" } }}
      >
        <DialogTitle sx={{ padding: "12px" }}>
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>
                {t("test_prompt")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={() => {
                  handleCloseTestModel();

                  handleReset();
                }}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid className={classes.headingTextGrid}>
            <Typography className={classes.headingText}>
              {t("test_prompt_subheading")}
            </Typography>
          </Grid>
        </DialogTitle>
        <Divider />
        <DialogContent ref={gridRef}>
          {" "}
          <Grid container>
            <Divider variant="middle" />
            <Grid container>
              {" "}
              <Grid
                sx={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.mainKeyWordContainer}
              >
                <Typography className={classes.labelText}>
                  {t("Select_Rating")}
                </Typography>

                <Autocomplete
                  //  error={ratingRequired}
                  onChange={(e, value) => {
                    // setRatingRequired(false);
                    handleChangeRating(value);
                  }}
                  disablePortal
                  id="combo-box-demo"
                  getOptionLabel={(option) => option?.name}
                  options={starRatings}
                  sx={{ width: "100%" }}
                  value={ratingValue}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      //  error={ratingRequired}
                      placeholder={t("Select_Rating")}
                      //  defaultValue={{ selectedRating }}
                    />
                  )}
                />
                {/*  <FormControl fullWidth>
                  <Select value={ratingValue} onChange={handleChangeRating}>
                    {starRatings?.map((item) => (
                      <MenuItem key={item.id} value={item?.value}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl> */}
              </Grid>
              {selectedTab !== 1 && (
                <Grid
                  sx={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.mainKeyWordContainer}
                >
                  <Typography className={classes.labelText}>
                    {t("Review")}
                  </Typography>

                  <TextField
                    id="standard-basic"
                    type="text"
                    multiline
                    fullWidth
                    rows={5.8}
                    maxRows={10}
                    placeholder={t("Enter_Review")}
                    //  helperText={t("Enter_extra_text")}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        handleChangeReviewsInPrompt(event.target.value);
                      }
                    }}
                    value={reviewValue}
                    onChange={(e) => {
                      setReviewValue(e.target.value);
                      if (e.target.value.length === 0) {
                        setSelectedReviewData(null);
                        // setPromptToTest(defaultTestPrompt);
                        let str = defaultTestPrompt;
                        const value = ratingValue?.value;
                        const matchedItem = starRatings.find(
                          (item) => item?.value === value
                        );

                        if (str?.includes("{{-ratingStars-}}")) {
                          str = str.replaceAll(
                            "{{-ratingStars-}}",
                            matchedItem?.name
                          );
                        } else {
                          starRatings?.forEach((item) => {
                            if (str?.includes(item?.name)) {
                              str = str.replaceAll(item?.value, value);
                            }
                          });
                        }
                        setPromptToTest(str);

                        /* if (promptToTest?.includes(reviewToReplace)) {
                          let str = promptToTest.replace(
                            reviewToReplace,
                            "{{-reviewText-}}"
                          );
                        } */
                      }
                    }}
                    InputProps={{
                      disableunderline: true,
                    }}
                  />

                  {/*   <FormControl fullWidth>
                    <Select value={reviewsValue} onChange={handleSelectReview}>
                      {reviewsData?.length > 0
                        ? reviewsData?.map((item) => (
                            <MenuItem
                              style={{
                                maxWidth: "850px",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                              value={item?.ratingText}
                            >
                              {item?.ratingText}
                            </MenuItem>
                          ))
                        : dummyReviewsData?.map((item) => (
                            <MenuItem value={item?.ratingText}>
                              {item?.ratingText}
                            </MenuItem>
                          ))}
                    </Select>
                  </FormControl> */}

                  <Typography
                    sx={{
                      fontSize: "12px",
                      lineHeight: "16px",
                      color: "#06BDFF",
                      cursor: "pointer",
                      paddingTop: "5px",
                      textAlign: "right",
                    }}
                    onClick={handleOpenReviewModel}
                  >
                    {t("useDefault")}
                  </Typography>
                </Grid>
              )}
              {textPresentGeneratedContent?.length > 0 && (
                <Grid
                  sx={12}
                  sm={12}
                  md={12}
                  lg={12}
                  className={classes.mainKeyWordContainer}
                >
                  <Typography className={classes.labelText}>
                    {t("Generated_review_response")}
                  </Typography>

                  <TextField
                    multiline
                    fullWidth
                    placeholder={t("What_Would_Like_Placeholder")}
                    value={textPresentGeneratedContent}
                    name="Description"
                  />
                </Grid>
              )}
            </Grid>

            {/* </DialogActions> */}
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ padding: "20px", boxShadow: "0px -1px 0px #E0E0E0" }}
        >
          <Grid container>
            <Grid item xs={8} sm={10} md={10} lg={10}></Grid>
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              sx={{ justifyContent: "flex-end" }}
            >
              {textPresentGeneratedContent?.length === 0 && (
                <CommonButton
                  onSubmit={handleTestPrompt}
                  label={t("test_prompt")}
                  alignment={true}
                  disabled={testLoading || (present && reviewValue === "")}
                  isLoading={testLoading}
                />
              )}
              {textPresentGeneratedContent?.length !== 0 && (
                <CommonButton
                  onSubmit={handleReset}
                  label={t("test_prompt_again")}
                  alignment={true}
                  disabled={testLoading || (present && reviewValue === "")}
                  isLoading={testLoading}
                />
              )}
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

      {openReviewModel && (
        <ReviewModel
          open={openReviewModel}
          title={`${t("Reviewss")}`}
          handleCloseReviewModel={handleCloseReviewModel}
          reviewsData={reviewsData?.length > 0 ? reviewsData : dummyReviewsData}
          allReviewSource={allReviewSource}
          setReviewsValue={setReviewValue}
          reviewValue={reviewValue}
          handleChangeReviewsInPrompt={handleChangeReviewsInPrompt}
          handleDisplayNames={handleDisplayNames}
          setSelectedReviewData={setSelectedReviewData}
        />
      )}
    </>
  );
};

export default PostAIModal;

import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  Box,
  TextField,
  Checkbox,
  TextareaAutosize,
  CircularProgress,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import { toast } from "react-toastify";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { Formik, Form } from "formik";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import { api } from "../../../../../../../contexts/JWTContext";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Alert } from "@mui/material";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import CheckIcon from "@mui/icons-material/Check";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import styled from "styled-components/macro";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const Additionaldescription = ({
  onCancel,
  data,
  locationDetailsData,
  location,
  getLocationDetails,
  getLocationDetailsListing,
  locationCategory,
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  setLocationDetailsData,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  categoryLoading,
}) => {
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [tagsRequired, setTagsRequired] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [tagsLimitError, setTagsLimitError] = useState(false);
  const [newCategory, setNewCategory] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  // useEffect(() => {
  //   if (selectedCategory?.length !==newCategory?.length) {
  //     setTouchedValue(true);
  //   }

  // }, [selectedCategory]);

  const handleCategoryChange = (option) => {
    setSelectedCategory(option);
    setTouchedValue(true);
  };
  useEffect(() => {
    /*    setSelectedCategory(
      locationDetailsData?.result?.google?.categories?.additionalCategories
        ? locationDetailsData?.result?.google?.categories?.additionalCategories
        : []
    ); */
    setNewCategory(
      locationDetailsData?.result?.google?.categories?.additionalCategories
        ? locationDetailsData?.result?.google?.categories?.additionalCategories
        : []
    );
    if (locationCategory?.length > 0) {
      let arr = [];
      locationDetailsData?.result?.google?.categories?.additionalCategories &&
        locationDetailsData?.result?.google?.categories?.additionalCategories?.map(
          (item) => {
            locationCategory[0]?.google?.map((itemTwo) => {
              if (item?.name === itemTwo?.name) {
                arr.push(itemTwo);
              }
            });
          }
        );
      setSelectedCategory(arr);
      /*  locationDetailsData?.result?.google?.categories?.additionalCategories?.map(
        (item) => locationCategory[0]?.google?.push(item)
      ); */
    }
  }, [locationCategory]);

  useEffect(
    (e) => {
      if (selectedCategory?.length === 10) {
        setTagsLimitError(true);
      } else {
        setTagsLimitError(false);
      }
    },
    [selectedCategory]
  );
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      getLocationDetails(location);
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const getLocationListingDetails = async (locationData) => {
    //  setTimesArr([]);
    // setLoadingLocation(true);
    try {
      const response = await api.get(`locationListing/${locationData.id}`);
      if (response.status === 200) {
        // setAutoSync(response?.data?.data?.result?.autoSync);
        //  setLoadingLocation(false);
        setLocationDetailsData(response?.data?.data);
      }
    } catch (error) {
      //  toast.error(error?.response?.data?.message);
      //  setLoadingLocation(false);
    }
  };
  const handleCategory = async () => {
    if (selectedCategory.length >= 10) {
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);
        let filteredArr = [...selectedCategory];

        filteredArr.forEach((item) => {
          delete item["moreHoursTypes"];
          delete item["serviceTypes"];
        });
        const res = await api.patch(
          `/locationListing/update-additionalCategories/${location?.id}`,
          {
            additionalCategories: filteredArr,
          }
        );

        if (res.status === 200) {
          setIsLoading(false);

          toast.success("Updated Successfully");

          // onCancel();
          // getLocationDetails(location);
          getLocationDetailsListing(location);
          // setLocationDetailsData(res?.data?.data);
          if (indexValue !== null) {
            let selectedValue = indexValue;

            setSelectedTab(selectedValue);
          }
          if (cancelState === true) {
            onCancel();
          }
          setTouchedValue(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  };

  const customFilterToSearchCategory = (options, { inputValue }) => {
    const startsWithInput = options.filter((option) =>
      option?.displayName?.toLowerCase().startsWith(inputValue?.toLowerCase())
    );

    const containsInput = options.filter(
      (option) =>
        option?.displayName
          ?.toLowerCase()
          .includes(inputValue?.toLowerCase()) &&
        !option?.displayName
          ?.toLowerCase()
          .startsWith(inputValue?.toLowerCase())
    );

    return [...startsWithInput, ...containsInput];
  };

  return (
    <Grid container direction="column" className={classes.gridContainerone}>
      <Grid container sx={{ padding: "18px" }}>
        <Grid item xs={10} sm={11} md={11.5} lg={11.5}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography className={classes.modalHeader}>
              {t("Additional_Desc")}
            </Typography>
            <BootstrapTooltip title={t("Additional_Desc_Subhead")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Box>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <Grid container className={classes.gridContainertwoother}>
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              className={classes.loaderBox}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <>
            {displayConfirmationBox && (
              <Dialog
                // fullScreen={fullScreen}
                open={displayConfirmationBox}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
              >
                <ConfirmModal
                  title={t("Delete_Bulk_Posts")}
                  description={t("Delete_post_subhead")}
                  onConfirm={handleCategory}
                  onClose={handleClose}
                  onCancel={onCancel}
                  indexValue={indexValue}
                  setSelectedTab={setSelectedTab}
                  cancelState={cancelState}
                  touchedValue={touchedValue}
                  setTouchedValue={setTouchedValue}
                  getLocationDetails={getLocationDetails}
                  location={location}

                  //  loading={setIsLoading}
                />
              </Dialog>
            )}
            <Grid container sx={{ padding: "18px" }}>
              <Grid
                item
                md={categoryLoading ? 11 : 12}
                sm={categoryLoading ? 11 : 12}
                xs={categoryLoading ? 11 : 12}
                lg={categoryLoading ? 11 : 12}
              >
                <Typography className={classes.fieldTextCategories}>
                  {t("Add_Categories")}
                </Typography>

                <Autocomplete
                  multiple
                  disablePortal
                  id="combo-box-demo"
                  disableCloseOnSelect
                  options={
                    locationCategory?.length > 0 &&
                    locationCategory[0]?.google?.length > 0
                      ? locationCategory[0]?.google
                      : []
                  }
                  getOptionLabel={(option) =>
                    option?.displayName ? option?.displayName : ""
                  }
                  sx={{ width: "100%" }}
                  //                 value={locationDetailsData?.result
                  //    ? locationDetailsData?.result?.google?.categories
                  //  : ""}
                  value={selectedCategory}
                  filterOptions={customFilterToSearchCategory}
                  onChange={(e, value) => {
                    if (selectedCategory.length >= 10) {
                      setTagsLimitError(true);
                    } else {
                      setTagsLimitError(false);
                    }
                    handleCategoryChange(value);
                  }}
                  renderOption={(props, option, { selected }) => {
                    return selected &&
                      selectedCategory?.length > 0 &&
                      selectedCategory?.find(
                        (item) => item?.name === option?.name
                      ) ? (
                      <li {...props}>
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            // border: "2px solid gray",
                            backgroundColor: "#06BDFF",
                            cursor: "pointer",
                            display: "inlineBlock",
                            marginRight: "10px",
                            textAlign: "center",
                            borderRadius: "3px",
                          }}
                        >
                          <CheckIcon
                            sx={{
                              color: "#FFFF",
                              marginBottom: "3px",
                              width: "14px",
                            }}
                            fontSize="small"
                          />
                        </div>
                        {option?.displayName ? option.displayName : ""}
                        {/* {option?.internalName + ", " + option?.city} */}
                      </li>
                    ) : (
                      <li {...props}>
                        <div
                          style={{
                            width: "16px",
                            height: "16px",
                            border: "2px solid #aaaaaa",
                            // backgroundColor: "#06BDFF",
                            cursor: "pointer",
                            display: "inlineBlock",
                            marginRight: "10px",
                            textAlign: "center",
                            borderRadius: "3px",
                          }}
                        ></div>
                        {option?.displayName ? option.displayName : ""}
                        {/* {option?.internalName + ", " + option?.city} */}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // required
                      name="selectedCategory"
                      placeholder={t("Select_Category")}
                      error={selectedCategory.length >= 10}
                    />
                  )}
                ></Autocomplete>
                {selectedCategory.length >= 10 && (
                  <Typography className={classes.errorText}>
                    {t("Categories_limit_text")}
                  </Typography>
                )}
              </Grid>
              {categoryLoading && (
                <Grid
                  item
                  xs={1}
                  sm={1}
                  md={1}
                  lg={1}
                  sx={{ textAlign: "center", paddingTop: "20px" }}
                >
                  <CircularProgress
                    color="secondary"
                    style={{
                      width: "25px",
                      height: "25px",
                      color: "#06BDFF",
                    }}
                  />
                </Grid>
              )}
            </Grid>
          </>
        )}
      </Grid>
      <Divider />
      <Grid container className={classes.gridContainerthree}>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Close")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            type="submit"
            label={t("Save")}
            onSubmit={handleCategory}
            disabled={selectedCategory.length >= 10}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Additionaldescription;

import React, { useEffect, useState } from "react";
import data from "./data.json";
import {
  Grid,
  Typography,
  Autocomplete,
  Divider as MuiDivider,
  DialogContentText,
  Box,
  IconButton,
  Dialog,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import CompanyDescription from "../Components/CompanyDescription/Modal/index";
import CompanyContactmodal from "../Components/CompanyContactDetails/Modal/index";
import CompanyOpeninghours from "../Components/LocationOpeningHours/Modal/index";
import CompanySpeacialhour from "../Components/LocationSpeacialHours/Modal/index";
import { useStyles } from "../../Styles/style";
import SocialAccountDetails from "../Components/SocialAccountDetails/Modal/index";
import Images from "../Components/Images/Modals";
import LocalPages from "../Components/LocalPages/Modals/index";
import Categories from "../Components/Categories/Modal/index";
import MoreHours from "../Components/MoreOpeningHours/Modal/index";
import CompanyBrandsAndServices from "../Components/CompanyServices&Brands/Modals/index";
import Companyattributes from "../Components/CompanyAttributes/Modal/index";
import LocationProductsModel from "../Components/Products/Model";

const label = { inputProps: { "aria-label": "Switch demo" } };

const EditModal = ({
  open,
  handleClose,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  location,
  getLocationById,
  getLocationDetails,
  handleCloseFilterModel,
  processedLocations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [selectedTab, setSelectedTab] = useState(1);
  const [touchedValue, setTouchedValue] = useState(false);

  const [displayConfirmationBox, setDisplayConfirmationBox] = useState(false);
  const [indexValue, setIndexValue] = useState(null);

  const handleChangeTab = (event, newValue) => {
    //  setSelectedTab(newValue);
    if (touchedValue === false) {
      setSelectedTab(newValue);
      setDisplayConfirmationBox(false);
      setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      setIndexValue(newValue);
    }
  };

  return (
    <>
      <div>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Dialog
              maxWidth="lg" // You can adjust the width if necessary
              sx={{
                "& .MuiDialog-paper": {
                  // You can uncomment this if you need a fixed width
                  height: "700px",
                  borderRadius: "4px",
                  overflow: "hidden", // Prevent scroll on entire dialog
                },
              }}
              open={open}
            >
              {" "}
              {/*************************************** Dialog Content Box ***************************************/}
              <DialogContentText
                id="responsive-dialog-title"
                sx={{ display: "flex", height: "100%", color: "#1b2430" }} // Flex to align grids
              >
                <Grid container sx={{ height: "100%", width: "1100px" }}>
                  <Grid
                    item
                    xs={4}
                    sm={3}
                    md={3}
                    lg={3}
                    sx={{
                      height: "100%", // Ensure it takes the full height
                      overflow: "auto",
                      borderRight: 1,
                      borderColor: "divider",
                      backgroundColor: "#F6F6F7",
                      textAlign: "left",
                    }}
                  >
                    {/*************************************** Tabs Section ***************************************/}
                    <Box sx={{ maxHeight: "100%", overflowY: "auto" }}>
                      <Tabs
                        orientation="vertical"
                        value={selectedTab}
                        onChange={handleChangeTab}
                        variant="scrollable"
                        aria-label="Vertical tabs example"
                        classes={{ indicator: classes.indicator }}
                        style={{ alignItems: "left" }}
                        modalIndex={selectedTab}
                      >
                        <Typography
                          sx={{
                            fontSize: "14px",
                            color: "#8D9298",
                            fontWeight: "700",
                            padding: "10px",
                          }}
                        >
                          {t("Basic_Information")}
                        </Typography>

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Categories")}
                        />
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Contact_Details")}
                        />

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Hours_head")}
                        />

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Speacial_Opening_Hours")}
                        />
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("More_Opening_Hours")}
                        />
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Company_Description")}
                        />

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Social_Accounts")}
                        />
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("Attributes")}
                        />

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("services")}
                        />

                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("cover_images_uberAll")}
                        />
                        <Tab
                          classes={{
                            root: classes.tabRoot,
                            selected: classes.selectedTab,
                          }}
                          label={t("product")}
                        />

                        {/*    {companyData !== null &&
                        companyData !== undefined &&
                        companyData?.thirdPartyCoverImages === true && (
                          <Tab
                            classes={{
                              root: classes.tabRoot,
                              selected: classes.selectedTab,
                            }}
                            label={t("local_pages")}
                          />
                        )} */}
                      </Tabs>
                    </Box>
                  </Grid>
                  <Grid item xs={8} sm={9} md={9} lg={9}>
                    {selectedTab === 1 && (
                      <Categories
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 2 && (
                      <CompanyContactmodal
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 3 && (
                      <CompanyOpeninghours
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 4 && (
                      <CompanySpeacialhour
                        sx={{ width: "90vh" }}
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 5 && (
                      <MoreHours
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 6 && (
                      <CompanyDescription
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 7 && (
                      <SocialAccountDetails
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 8 && (
                      <Companyattributes
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 9 && (
                      <CompanyBrandsAndServices
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 10 && (
                      <Images
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}
                    {selectedTab === 11 && (
                      <LocationProductsModel
                        touchedValue={touchedValue}
                        handleCloseFilterModel={handleCloseFilterModel}
                        setTouchedValue={setTouchedValue}
                        setDisplayConfirmationBox={setDisplayConfirmationBox}
                        displayConfirmationBox={displayConfirmationBox}
                        indexValue={indexValue}
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        setIndexValue={setIndexValue}
                        setSelectedLocationsBulkUpdate={
                          setSelectedLocationsBulkUpdate
                        }
                        selectedLocationsBulkUpdate={
                          selectedLocationsBulkUpdate
                        }
                        onCancel={handleClose}
                        location={location}
                        getLocationById={getLocationById}
                        getLocationDetails={getLocationDetails}
                        processedLocations={processedLocations}
                      />
                    )}

                    {/*  {companyData !== null &&
                      companyData !== undefined &&
                      companyData?.thirdPartyCoverImages === true &&
                      selectedTab === 11 && (
                        <LocalPages
                          touchedValue={touchedValue}
                          handleCloseFilterModel={handleCloseFilterModel}
                          setTouchedValue={setTouchedValue}
                          setDisplayConfirmationBox={setDisplayConfirmationBox}
                          displayConfirmationBox={displayConfirmationBox}
                          indexValue={indexValue}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          setIndexValue={setIndexValue}
                          setSelectedLocationsBulkUpdate={
                            setSelectedLocationsBulkUpdate
                          }
                          selectedLocationsBulkUpdate={
                            selectedLocationsBulkUpdate
                          }
                          onCancel={handleClose}
                          location={location}
                          getLocationById={getLocationById}
                          getLocationDetails={getLocationDetails}
                        />
                      )} */}
                  </Grid>
                </Grid>
              </DialogContentText>
            </Dialog>
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default EditModal;

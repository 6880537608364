import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Grid,
  Zoom,
  Stack,
  styled,
  Divider,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import { api } from "../../../../../../../contexts/JWTContext";
import Delete from "../../../../../../../components/Models/DeleteModal/Delete";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@mui/styles";
import AddAPhotoOutlinedIcon from "@mui/icons-material/AddAPhotoOutlined";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import Compressor from "compressorjs";
import CoverImagesCard from "./CoverImagesCard";

const useStyles = makeStyles((theme) => ({
  formUpload: {
    height: "16rem",
    width: "28rem",
    maxWidth: "100%",
    textAlign: "center",
    position: "relative",
  },
  lableFileUpload: {
    height: " 100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderWidth: "2px",
    borderRadius: "1rem",
    borderStyle: "dashed",
    borderColor: "#cbd5e1",
    backgroundColor: "#f8fafc",
  },
  dragFile: {
    position: "absolute",
    width: "100%",
    height: "100%",
    borderRadius: "1rem",
    top: "0px",
    right: "0px",
    bottom: "0px",
    left: "0px",
  },
}));
const Transition = React.forwardRef((props, ref) => (
  <Zoom ref={ref} {...props} style={{ transitionDelay: "200ms" }} />
));
Transition.displayName = "Transition";

const Item = styled(Box)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
}));

const CoverImageModal = ({
  open,
  handleClose,
  category,
  setSelectedImages,
  selectedImages,
  title,
  setSelectedFile,
  uploadedImages,
  setUploadedImages,
  setTouchedValue,
  locationId,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const inputFileRef = useRef(null);
  const [dragActive, setDragActive] = React.useState(false);
  const [isUploading, setIsUploading] = useState(false);

  const [openOptimizeImageModel, setOpenOptimizeImageModel] = useState(false);
  const [newIndexValue, setNewIndexValue] = useState(null);
  const [resizeLoading, setResizeLoading] = useState(false);
  const [openCropModel, setOpenCropModel] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = (index) => {
    const deleteImage = selectedImages.filter((item, indx) => {
      return indx !== index;
    });

    setSelectedImages(deleteImage);
    setUploadedImages([]);
  };
  const handleImageError = (index) => {
    let temmpArray = [...selectedImages];
    let temmpObj = temmpArray[index];
    temmpObj["error"] = true;
    temmpArray[index] = temmpObj;
    setSelectedImages(temmpArray);
  };

  const onFileChangeCapture = (e) => {
    const fileType = e.target.files[0]?.type;

    if (fileType.startsWith("image/")) {
      let tempImages = [];
      tempImages.push({
        picture: e.target.files[0],
        picturePreview: URL.createObjectURL(e.target.files[0]),
      });

      setSelectedImages(tempImages);
    }
  };

  const handleDrag = function (e) {
    e.preventDefault();
    e.stopPropagation();
    if (e.type === "dragenter" || e.type === "dragover") {
      setDragActive(true);
    } else if (e.type === "dragleave") {
      setDragActive(false);
    }
  };
  const handleDrop = function (e) {
    const fileType = e.dataTransfer.files[0]?.type;

    if (fileType.startsWith("image/")) {
      e.preventDefault();
      e.stopPropagation();
      setDragActive(false);
      let tempImages = [];
      tempImages.push({
        picture: e.dataTransfer.files[0],
        picturePreview: URL.createObjectURL(e.dataTransfer.files[0]),
      });
      setSelectedImages([...tempImages]);
    }
  };
  const handleSubmitImages = async () => {
    setIsLoading(true);

    let images = [
      {
        category: "FOOD_AND_MENU",
        sourceUrl: uploadedImages[0],
      },
    ];
    try {
      const res = await api.post(`/locationListing/add-media/${locationId}`, {
        images: images,
      });

      if (res.status === 200) {
        setIsLoading(false);
        handleClose(true);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleOpenOptimizeImageModel = (indValue) => {
    setNewIndexValue(indValue);
    setOpenOptimizeImageModel(true);
  };
  const handleCloseOptimizeImageModel = () => {
    setOpenOptimizeImageModel(false);
  };
  const handleOptimize = () => {
    setResizeLoading(true);
    const fileToOptimize = selectedImages[newIndexValue];
    const tempImages = [...selectedImages];
    new Compressor(fileToOptimize?.picture, {
      quality: 0.8,

      success: (compressedResult) => {
        tempImages[newIndexValue] = {
          picture: compressedResult,
          picturePreview: URL.createObjectURL(compressedResult),
        };
        setSelectedImages(tempImages);
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
      error: (err) => {
        setResizeLoading(false);
        handleCloseOptimizeImageModel();
      },
    });
  };
  const closeCropModal = () => {
    setOpenCropModel(false);
  };
  const handleOpenCropModel = () => {
    setResizeLoading(true);
    setOpenCropModel(true);

    setTimeout(() => {
      setOpenOptimizeImageModel(false);
      setResizeLoading(false);
    }, 500);
  };

  return (
    <>
      {openOptimizeImageModel && (
        <Dialog
          open={openOptimizeImageModel}
          onClose={handleCloseOptimizeImageModel}
          aria-labelledby="responsive-dialog-title"
        >
          <Delete
            title={t("edit_image_tooltipText")}
            description={t("edit_image_tooltipText_subHead")}
            onConfirm={handleOpenCropModel}
            loading={resizeLoading}
            onCancel={handleCloseOptimizeImageModel}
          />
        </Dialog>
      )}
      <Dialog
        open={open}
        disableBackdropClick
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "800px",
              borderRadius: "8px",
            },
          },
        }}
        TransitionComponent={Transition}
        keepMounted
        //    onClose={handleClose}
        fullWidth
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        {selectedImages?.length > 0 ? (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                sx={{
                  color: "#1B2430",
                  fontSize: "20px",
                  marginBottom: "20px",
                  fontWeight: "700",
                }}
                id="alert-dialog-slide-title"
              >
                {title}
              </DialogTitle>
              <IconButton
                onClick={() => {
                  handleClose(false);
                  setSelectedImages([]);
                }}
                aria-label="save"
                hover="none"
                sx={{
                  hover: "none",
                  marginBottom: "15px",
                  marginRight: "22px",
                }}
              >
                X
              </IconButton>
            </Stack>

            <DialogContent>
              <Stack direction="column">
                <Item>
                  {selectedImages && (
                    <>
                      {selectedImages?.map((img, index) => {
                        return (
                          <CoverImagesCard
                            img={img}
                            handleDelete={handleDelete}
                            handleClose={handleClose}
                            index={index}
                            isUploading={isUploading}
                            setIsUploading={setIsUploading}
                            uploadedImages={uploadedImages}
                            setUploadedImages={setUploadedImages}
                            handleImageError={handleImageError}
                            selectedImages={selectedImages}
                            setSelectedImages={setSelectedImages}
                            category={category}
                            handleOpenOptimizeImageModel={
                              handleOpenOptimizeImageModel
                            }
                            closeCropModal={closeCropModal}
                            openCropModel={openCropModel}
                            setOpenCropModel={setOpenCropModel}
                            handleOptimize={handleOptimize}
                            setSelectedFile={setSelectedFile}
                            locationId={locationId}
                            setTouchedValue={setTouchedValue}
                          />
                        );
                      })}
                    </>
                  )}
                </Item>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Grid container display="flex" justifyContent="flex-end">
                {" "}
                {selectedImages?.length > 0 && (
                  <>
                    <Button
                      sx={{
                        fontWeight: "700",
                        size: "large",
                        color: "#1B2430",
                        borderRadius: "8px",
                        padding: " 14px 20px",
                        border: "1px solid #E0E0E0",
                        hover: "none",
                      }}
                      variant="outlined"
                      // color="#1B2430"

                      onClick={() => {
                        setSelectedImages([]);
                        setUploadedImages([]);
                        handleClose(false);
                      }}
                    >
                      {t("Cancel")}
                    </Button>

                    <CommonButton
                      disabled={
                        isLoading ||
                        isUploading ||
                        selectedImages?.length === 0 ||
                        selectedImages.find((item) => item?.error === true)
                          ? true
                          : false
                      }
                      isLoading={isLoading}
                      onSubmit={() =>
                        uploadedImages?.length === selectedImages?.length
                          ? handleClose(true)
                          : setIsUploading(true)
                      }
                      label={
                        uploadedImages?.length === selectedImages?.length
                          ? t("Submit")
                          : t("Upload")
                      }
                    />
                  </>
                )}
              </Grid>
            </DialogActions>
          </>
        ) : (
          <>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <DialogTitle
                id="alert-dialog-slide-title"
                sx={{
                  fontWeight: "700",
                  color: "#1B2430",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                  lineHeight: "28px",
                }}
              >
                {title}
              </DialogTitle>
              <IconButton
                onClick={() => handleClose()}
                aria-label="save"
                hover="none"
                sx={{ hover: "none", marginRight: "22px" }}
              >
                X
              </IconButton>
            </Stack>
            <DialogContent>
              <Stack direction="column">
                <Item>
                  <Box
                    sx={{
                      p: 2,

                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                      height: "70vh",
                    }}
                  >
                    <form
                      className={classes.formUpload}
                      onDragEnter={handleDrag}
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <label
                        className={classes.lableFileUpload}
                        htmlFor={`file-logo`}
                      >
                        <AddAPhotoOutlinedIcon
                          sx={{
                            height: "79.38px",
                            width: "91.67px",
                            cursor: "pointer",
                            color: "#8D9298",
                          }}
                        />
                        <input
                          id={`file-logo`}
                          type="file"
                          accept=".jpg, .png, .jpeg"
                          multiple={false}
                          ref={inputFileRef}
                          onChange={onFileChangeCapture}
                          style={{ display: "none" }}
                        />
                      </label>
                      {dragActive && (
                        <div
                          className={classes.dragFile}
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        marginTop: "26px",
                        fontWeight: "400",
                        color: "#495059",
                      }}
                    >
                      {t("uploadItemImage")}
                    </Typography>
                  </Box>
                </Item>
              </Stack>
            </DialogContent>
          </>
        )}
      </Dialog>
      <style>
        {`
       .MuiDialogActions-root {
        display: block;
      }
     
    `}
      </style>
    </>
  );
};

export default CoverImageModal;

import React, { useState } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { LazyLoadImage } from "react-lazy-load-image-component";
import noImageGray from "../../../../../../../assets/images/noImageGray.png";
import { Box, CircularProgress, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import { useStyles } from "../../../../Styles/style";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

const PreviewImageModal = ({
  open,
  handleClose,
  handleClickOpen,
  previewImages,
  handleDeleteImage,
  title,
  displayButtonGrid,
  indexOfItem,
  handleDeleteImagePreview,
  mediaCount,
  previewLoading,
  fetchMoreData,
}) => {
  const classes = useStyles();
  const [loadImg, setLoadImg] = useState(false);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const [indexValue, setIndexValue] = useState(indexOfItem);
  const [next, setNext] = useState(false);
  const [Previous, setPrevious] = useState(false);
  const [disabledNext, setDisabledNext] = useState(false);
  const [disabledPrev, setDisabledPrev] = useState(false);

  const handleBeforeLOad = () => {
    setLoadImg(true);
  };
  const handleAfterLOad = () => {
    setLoadImg(false);
  };
  function isLastIndex(array, selectedIndex) {
    const lastIndex = array.length - 1;
    return selectedIndex === lastIndex;
  }
  function isFirstIndex(array, selectedIndex) {
    return selectedIndex === 0;
  }

  const handleClickPrevious = () => {
    if (isFirstIndex(previewImages, indexValue)) {
      setDisabledPrev(true);
    } else {
      setDisabledPrev(false);
      setDisabledNext(false);
      setPrevious(true);
      setNext(false);
      setIndexValue(indexValue - 1);
    }
  };
  const handleClickNext = () => {
    if (isLastIndex(previewImages, indexValue)) {
      //  setDisabledNext(true);
      if (indexValue + 1 === mediaCount) {
        setDisabledNext(true);
      } else {
        fetchMoreData();
        setTimeout(() => {
          setNext(true);
          setIndexValue(indexValue + 1);
        }, 1000);
      }
    } else {
      setDisabledNext(false);
      setDisabledPrev(false);
      setPrevious(false);
      setNext(true);
      setIndexValue(indexValue + 1);
    }
  };
  console.log(previewImages, "previewImages");

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth
        maxHeight
        //   aria-labelledby="responsive-dialog-title"
        //  sx={{ width: "900px" }}
      >
        <Box sx={{ display: "flex" }}>
          <Grid item xs={11} sm={11} md={11} lg={11}>
            <DialogTitle id="alert-dialog-title">
              <Typography
                sx={{
                  fontWeight: "700",
                  color: "#1B2430",
                  fontSize: "20px",
                  letterSpacing: "0.5px",
                  lineHeight: "28px",
                }}
              >
                {`${title}`}
              </Typography>
            </DialogTitle>
          </Grid>

          <Grid
            item
            xs={1}
            sm={1}
            md={1}
            lg={1}
            sx={{ justifyContent: "flex-end", textAlign: "end" }}
          >
            <IconButton
              onClick={handleClose}
              sx={{ paddingTop: "12px", fontWeight: 700 }}
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Box>
        <DialogContent sx={{ paddingTop: "0px" }}>
          <Grid
            item
            xs={12}
            sm={12}
            lg={12}
            md={12}
            sx={{ padding: "0px 15px 0px 20px" }}
          >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography className={classes.fieldTextDescription}></Typography>{" "}
              <Typography className={classes.limitFieldTextDescription}>
                {previewLoading ? (
                  <CircularProgress
                    color="secondary"
                    style={{ width: "16px", height: "16px" }}
                  />
                ) : (
                  indexValue + 1 + "/" + (mediaCount === 0 ? 1 : mediaCount)
                )}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <LazyLoadImage
              loading="lazy"
              src={
                loadImg
                  ? noImageGray
                  : indexOfItem !== null
                  ? previewImages[indexValue]
                  : previewImages[0]
              }
              beforeLoad={handleBeforeLOad}
              afterLoad={handleAfterLOad}
              style={{
                width: "750px",
                height: "310px",
                borderRadius: "8px",
              }}
              PlaceholderSrc={noImageGray}
              alt="Image Alt"
              effect="blur"
            />
          </Grid>
        </DialogContent>
        {displayButtonGrid && (
          <Grid container sx={{ padding: "0px 20px 12px 20px" }}>
            <Grid container sx={{ justifyContent: "center" }}>
              <Grid
                item
                xs={4}
                sm={4}
                md={4}
                lg={4}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  // cursor: "pointer",
                }}
              ></Grid>
              <Grid
                item
                xs={3}
                sm={3}
                md={3}
                lg={3}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => !disabledPrev && handleClickPrevious()}
              >
                <Box textAlign="right" sx={{ display: "flex" }}>
                  <KeyboardArrowLeftIcon
                    // className={classes.PrevNextButton}
                    sx={{
                      color: disabledPrev
                        ? "#cbd5e1"
                        : Previous
                        ? "#06BDFF"
                        : "#1B2430",
                      marginTop: "4px",
                    }}
                  />
                  <Typography
                    sx={{
                      color: disabledPrev
                        ? "#cbd5e1"
                        : Previous
                        ? "#06BDFF"
                        : "#1B2430",
                    }}
                    className={classes.PrevNextButton}
                  >
                    Previous
                  </Typography>
                </Box>
              </Grid>

              <Grid
                item
                xs={5}
                sm={5}
                md={5}
                lg={5}
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  cursor: "pointer",
                }}
                onClick={() => !disabledNext && handleClickNext()}
              >
                <Box sx={{ display: "flex" }}>
                  <Typography
                    className={classes.PrevNextButton}
                    sx={{
                      color: disabledNext
                        ? "#cbd5e1"
                        : next
                        ? "#06BDFF"
                        : "#1B2430",
                    }}
                  >
                    Next
                  </Typography>
                  <KeyboardArrowRightIcon
                    // className={classes.PrevNextButton}
                    sx={{
                      color: disabledNext
                        ? "#cbd5e1"
                        : next
                        ? "#06BDFF"
                        : "#1B2430",
                      marginTop: "4px",
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Divider variant="middle" />
        <DialogActions>
          {/* <Button onClick={handleClose}>Disagree</Button> */}
          <CommonButton
            displayWhite="true"
            label={"Cancel"}
            onSubmit={handleClose}
          />

          <Button
            onClick={() => {
              handleDeleteImage();
            }}
            className={classes.logoDeleteButton}
          >
            {"Delete"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default PreviewImageModal;

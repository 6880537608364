import React, { useEffect, useState } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
  Paper,
  Button,
  TextField,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ServicesModel from "./ServicesModel/ServicesModel";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import { Dialog } from "@material-ui/core";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import NotFound from "../../../../../../../components/NotFound/NotFound";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { styled } from "@mui/system";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: "#fff",
  border: "1px solid #EBEBEC",
  height: "30px",
  padding: "6px",
  margin: "0.3rem",
  paddingLeft: "15px",
  paddingRight: "15px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  borderRadius: "800px",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  color: theme.palette.text.secondary,
}));
const CompanyLabel = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  setIndexValue,
  selectedLocationsBulkUpdate,
  onCancel,
  handleCloseFilterModel,
  processedLocations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [brandTags, setBrandTags] = useState([]);
  const [labelError, setLabelError] = useState(false);
  const [labelLimitError, setLabelLimitError] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [openServicesModel, setOpenServicesModel] = useState(false);
  const [customService, setCustomService] = useState("");
  const [allLocationSelectedServices, setAllLocationSelectedServices] =
    useState([]);
  const [showAddServiceField, setShowAddServiceField] = useState(false);
  const [serviceItems, setServiceItems] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [filteredSuggestions, setFilteredSuggestions] = useState([]);
  const [editableService, setEditableService] = useState({});
  const [editableServiceIndex, setEditableServiceIndex] = useState({});
  const [getServicesLoading, setGetServicesLoading] = useState(false);
  const [noServiceData, setNoServiceData] = useState(false);
  const [ispressEnter, setIspressEnter] = useState(false);
  const [duplicateBrand, setDuplicateBrand] = useState(false);
  const [duplicateService, setDuplicateService] = useState(false);
  const [serviceAlreadyExits, setServiceAlreadyExits] = useState(false);

  useEffect(() => {
    handleGetServices();
  }, []);
  useEffect(() => {
    const controller = new AbortController();

    handleGetServices(controller);

    return () => {
      // Cleanup: Abort the API request on component unmount or dependency change
      controller.abort();
    };
  }, [indexValue]);

  const handleGetServices = async (controller) => {
    setGetServicesLoading(true);
    let locationIds = [];

    try {
      const suggestionsResponse = await api.get(
        `service/bulkServicesAndBrands?locationId=${processedLocations}`,
        { signal: controller.signal }
      );

      if (suggestionsResponse.status === 200) {
        setFilteredSuggestions(suggestionsResponse?.data?.data);
        setSuggestions(suggestionsResponse?.data?.data);
        setNoServiceData(false);
      } else if (suggestionsResponse.status === 204) {
        setNoServiceData(true);
        setFilteredSuggestions([]);
        setSuggestions([]);
      }
      setGetServicesLoading(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("API call was cancelled");
      } else {
        toast.error(error?.response?.data?.message);
        setGetServicesLoading(false);
        setNoServiceData(false);
        setFilteredSuggestions([]);
        setSuggestions([]);
      }
    }
  };

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  const handleOpen = () => {
    if (touchedValue === false) {
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleChangeCustomService = (event) => {
    setTouchedValue(true);
    if (!event.target.value) {
      setDuplicateService(false);
      setServiceAlreadyExits(false);
    }

    setCustomService(event.target.value);
    setIspressEnter(true);
  };

  const handleEnterKeyPress = (event) => {
    try {
      let textWithoutSpaces = event?.target?.value?.trim().replace(/\s+/g, "");
      if (event.key === "Enter" && textWithoutSpaces.length > 0) {
        const isDuplicate = serviceItems.some((item) => {
          const serviceTypes =
            item?.freeFormServiceItem?.label?.displayName
              ?.toLowerCase()
              ?.replace(/\s+/g, "") ||
            getDisplayName(
              suggestions,
              item?.structuredServiceItem?.serviceTypeId
            )
              ?.replace(/\s+/g, "")
              ?.toLowerCase();

          return serviceTypes === textWithoutSpaces?.toLowerCase();
        });

        if (isDuplicate) {
          setDuplicateService(true);
          setServiceAlreadyExits(false);
          return;
        }

        const serviceAlreadyExists = filteredSuggestions?.find(
          (item) =>
            item?.displayName?.replace(/\s+/g, "")?.toLowerCase() ===
            textWithoutSpaces?.toLowerCase()
        );

        if (serviceAlreadyExists) {
          setServiceAlreadyExits(true);
          setDuplicateService(false);
          return;
        } else {
          setServiceAlreadyExits(false);
        }

        const updatedServices = [...serviceItems];
        if (event?.target?.value) {
          const newService = {
            freeFormServiceItem: {
              label: { displayName: (event?.target?.value).trim() },
              category: null,
            },
          };
          updatedServices.push(newService);
        }

        setShowAddServiceField(false);
        setServiceItems(updatedServices);
        setCustomService("");
        setIspressEnter(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleBrandsKeyDown = (e) => {
    setTouchedValue(true);
    if (e.key !== "Enter" || e.target.value.trim() === "") return;

    let value = e.target.value.toLowerCase().trim();
    if (value.includes("\n") && value !== "\n") {
      const lastLineBreakRemoved = value.replace(/\n+$/, "");
      const arr = lastLineBreakRemoved.split("\n");
      const filtered = arr.filter((item) => item?.trim() !== "");
      const mergeResult = [...brandTags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setBrandTags(uniqueKeywords);

      e.target.value = "";
    } else {
      if (!value.trim()) return;
      const mergeResult = [...brandTags, value];
      const uniqueKeywords = [...new Set(mergeResult)];
      setBrandTags(uniqueKeywords);
    }
    const lowerCaseBrandTags = brandTags.map((tag) => tag.toLowerCase());
    const lastLineBreakRemoved = value.replace(/\n+$/, "");
    const arr = lastLineBreakRemoved.split("\n");
    const filtered = arr.filter((item) => item?.trim() !== "");
    if (lowerCaseBrandTags.includes(value.toLowerCase())) {
      setDuplicateBrand(true);
      setTimeout(() => {
        setDuplicateBrand(false);
      }, 2000);
    } else {
      for (let i = 0; i < brandTags.length; i++) {
        if (filtered.includes(brandTags[i])) {
          setDuplicateBrand(true);
          setTimeout(() => {
            setDuplicateBrand(false);
          }, 2000);
          break;
        }
      }
    }

    e.target.value = "";
  };

  const handleAddBrandsKeywords = (e) => {
    // let value = e.target.value;
    if (e.target.value.includes(",") && e.target.value.includes("\n")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(/[\n,]+/);
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(brandTags);
      const mergeResult = [...brandTags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setBrandTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(",")) {
      const lastCommaRemoved = e?.target?.value.replace(/,*$/, "");
      const arr = lastCommaRemoved.split(",");
      const filtered = arr.filter((item) => item?.trim() !== "");
      filtered.concat(brandTags);
      const mergeResult = [...brandTags, ...filtered];
      const uniqueKeywords = [...new Set(mergeResult)];
      setBrandTags(uniqueKeywords);
      e.target.value = "";
    } else if (e.target.value.includes(".")) {
      const lastDotRemoved = e?.target?.value.replace(/\.+$/, "");
      const arr = lastDotRemoved.split(".");
      arr.concat(brandTags);
      const mergeResult = [...brandTags, ...arr];
      const uniqueKeywords = [...new Set(mergeResult)];
      setBrandTags(uniqueKeywords);
      e.target.value = "";
    }
    const lowerCaseBrandTags = brandTags.map((tag) => tag.toLowerCase());
    if (lowerCaseBrandTags.includes(e.target.value.toLowerCase())) {
      setDuplicateBrand(true);
    } else {
      setDuplicateBrand(false);
    }
  };

  const removeBrandsTag = (index) => {
    setBrandTags(brandTags.filter((el, i) => i !== index));
    setTouchedValue(true);
  };

  const handleKeywords = async () => {
    setTouchedValue(true);
    if (
      labelError === false &&
      labelLimitError === false &&
      duplicateBrand === false &&
      ispressEnter === false
    ) {
      setDisplayConfirmationBox(false);
      setIsLoading(true);

      function getServiceDisplayName(serviceTypeId) {
        const suggestion = suggestions?.find(
          (suggestion) => suggestion?.serviceTypeId === serviceTypeId
        );
        return suggestion ? suggestion?.displayName.trim() : null;
      }
      const updateArray = serviceItems?.map((item) => {
        if (Object.keys(item).includes("freeFormServiceItem")) {
          return item;
        } else {
          return {
            freeFormServiceItem: {
              label: {
                displayName: suggestions.filter(
                  (x) =>
                    x?.serviceTypeId ===
                    item?.structuredServiceItem?.serviceTypeId
                )[0]?.displayName,
              },
              category: suggestions.filter(
                (x) =>
                  x?.serviceTypeId ===
                  item?.structuredServiceItem?.serviceTypeId
              )[0]?.name,
            },
          };
        }
      });

      const modifiedServiceItems = serviceItems?.map((item) => {
        if (item.freeFormServiceItem) {
          return {
            ...item,
            freeFormServiceItem: {
              ...item?.freeFormServiceItem,
              displayName: item?.freeFormServiceItem?.label?.displayName.trim(),
            },
          };
        } else if (item.structuredServiceItem) {
          return {
            ...item,
            structuredServiceItem: {
              ...item?.structuredServiceItem,
              displayName: getServiceDisplayName(
                item?.structuredServiceItem?.serviceTypeId
              ),
            },
          };
        }
        return item;
      });

      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate.map((item) => {
          locationIds.push(item.id);
        });
      }
      try {
        const res = await api.patch(`/service/bulkServicesAndBrands`, {
          locationId: locationIds,
          brands: brandTags,
          services: modifiedServiceItems.map(
            (item) =>
              item?.freeFormServiceItem?.displayName.trim() ||
              item?.structuredServiceItem?.displayName.trim()
          ),
          serviceItems: updateArray,
        });

        if (res.status === 200) {
          setIsLoading(false);
          toast.success("Updated Successfully");
          //   getLocationDetailsListing(location);

          if (indexValue !== null) {
            setSelectedTab(indexValue);
          }
          if (cancelState === true) {
            onCancel();
            handleCloseFilterModel();
          }
          setTouchedValue(false);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    } else {
      setIndexValue(null);
      setDisplayConfirmationBox(false);
    }
  };

  const handleCancelButton = () => {
    //  setServiceItems(locationDetailsData?.result?.google?.serviceItems);
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
    } else {
      setDisplayConfirmationBox(true);
    }
  };

  const handleAddNewData = () => {
    setTouchedValue(true);
    const updatedServices = [...serviceItems];
    serviceItems.push(updatedServices);
    setServiceItems(updatedServices);
    setShowAddServiceField(true);
  };

  const handleOpenServicesModel = (item, index) => {
    setEditableService(item);
    setEditableServiceIndex(index);
    setOpenServicesModel(true);
  };

  const handleCloseServicesModel = () => {
    setOpenServicesModel(false);
  };

  const handleAddService = (data, ind) => {
    setTouchedValue(true);

    const newService = {
      structuredServiceItem: {
        serviceTypeId: data?.serviceTypeId,
      },
    };

    const updatedServiceItems = [...serviceItems];

    updatedServiceItems.push(newService);

    setServiceItems(updatedServiceItems);
    const updatedFilteredSuggestions = filteredSuggestions.filter(
      (suggestion) => {
        if (suggestion?.serviceTypeId === data?.serviceTypeId) return false;
        return true;
      }
    );
    setFilteredSuggestions(updatedFilteredSuggestions);

    setAllLocationSelectedServices([...allLocationSelectedServices]);
  };

  const handleRemoveService = (data, ind) => {
    setTouchedValue(true);
    const updatedServiceItems = [...serviceItems];

    updatedServiceItems.splice(ind, 1);

    const filterSuggestions = suggestions.filter((suggestion) => {
      for (let service of updatedServiceItems) {
        if (
          service?.hasOwnProperty("structuredServiceItem") &&
          service?.structuredServiceItem?.serviceTypeId ===
            suggestion?.serviceTypeId
        ) {
          return false;
        }
      }

      return true;
    });

    setServiceItems(updatedServiceItems);
    setFilteredSuggestions(filterSuggestions);
  };

  const getDisplayName = (suggestions, id) => {
    const displayName = suggestions?.filter(
      (suggestion) => suggestion?.serviceTypeId === id
    );
    return displayName[0]?.displayName || null;
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {openServicesModel && (
        <ServicesModel
          title={t("edit_services_detail")}
          open={openServicesModel}
          handleCloseNewRequestModel={handleCloseServicesModel}
          serviceItem={editableService}
          serviceSuggestions={suggestions}
          //  location={locationDetailsData}
          serviceItems={serviceItems}
          setServiceItems={setServiceItems}
          editableServiceIndex={editableServiceIndex}
          suggestions={suggestions}
        />
      )}
      {displayConfirmationBox && (
        <Dialog
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleKeywords}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            /*   getLocationDetails={getLocationDetails}
            location={location} */
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={11}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("services")}
          </Typography>
          <BootstrapTooltip title={t("Company_Services_Subhead")}>
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>

      <Divider />

      <>
        <Grid container className={classes.gridContainertwoBulk}>
          {isLoading || getServicesLoading ? (
            <>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.loaderBox}
              >
                <Loader />
              </Grid>
            </>
          ) : (
            <>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <Grid container sx={{ padding: "18px" }}>
                  <Grid
                    item
                    md={11.5}
                    sm={11.5}
                    xs={11.5}
                    lg={11.5}
                    sx={{ marginBottom: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.fieldTextService}>
                        {t("Brands")}
                      </Typography>
                      <BootstrapTooltip title={t("brands_subText")}>
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            marginTop: "4px",
                            cursor: "pointer",
                          }}
                        >
                          <InfoOutlinedIcon />
                        </Typography>
                      </BootstrapTooltip>
                    </Box>
                    <Grid className={"tags-input-container"}>
                      {brandTags?.length > 0 &&
                        brandTags.map((keywords, index) => (
                          <div className={classes.tagItem} key={index}>
                            <Box className={classes.keywordBox}>
                              <Typography className={classes.keywordTypo}>
                                {keywords}
                              </Typography>{" "}
                              <CloseIcon
                                className={classes.keywordIcon}
                                onClick={() => removeBrandsTag(index)}
                              />
                            </Box>
                          </div>
                        ))}

                      {/*  <input
                          type="text"
                          className={classes.tagsInput}
                          placeholder={t("Add_Brands")}
                          onKeyDown={handleBrandsKeyDown}
                        /> */}
                      <TextField
                        variant="standard"
                        rows={4}
                        maxRows={20}
                        multiline
                        placeholder={t("Add_Brands")}
                        className={classes.tagsInput}
                        InputProps={{
                          disableUnderline: true,
                        }}
                        onKeyDown={handleBrandsKeyDown}
                        onChange={(e) => {
                          if (
                            e.target.value === "," ||
                            e.target.value === "." ||
                            e.target.value.trim() === ""
                          ) {
                            e.target.value = "";
                          } else if (
                            (e.target.value !== "" &&
                              e.target.value !== "," &&
                              e.target.value.includes(".")) ||
                            (e.target.value !== "." &&
                              e.target.value !== "" &&
                              e.target.value.includes(","))
                          ) {
                            handleAddBrandsKeywords(e);
                            setTouchedValue(true);
                          }
                        }}
                        //  disabled={tags?.length > 20}
                        style={{
                          width: "100%",
                          marginLeft: "5px",
                          borderBottom: "none",
                          marginTop: "5px",

                          padding: "0px",
                        }}
                      />
                    </Grid>
                    {duplicateBrand && (
                      <Typography sx={{ color: "red", fontSize: "12px" }}>
                        {t("Brands_duplicated_error")}
                      </Typography>
                    )}
                  </Grid>

                  <Grid
                    item
                    md={12}
                    sm={12}
                    xs={12}
                    lg={12}
                    // sx={{ marginBottom: "12px" }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Typography className={classes.fieldTextService}>
                        {t("Services")}
                      </Typography>
                      <BootstrapTooltip title={t("services_subText")}>
                        <Typography
                          sx={{
                            marginLeft: "5px",
                            marginTop: "4px",
                            cursor: "pointer",
                          }}
                        >
                          <InfoOutlinedIcon />
                        </Typography>
                      </BootstrapTooltip>
                    </Box>
                  </Grid>
                  {serviceItems?.length > 0 &&
                    serviceItems?.map((item, index) => {
                      let displayName =
                        item?.freeFormServiceItem?.label?.displayName ||
                        getDisplayName(
                          suggestions,
                          item?.structuredServiceItem?.serviceTypeId
                        );
                      let description = "";
                      if (
                        item?.freeFormServiceItem?.label?.description ||
                        item?.structuredServiceItem?.description
                      ) {
                        description =
                          item?.freeFormServiceItem?.label?.description ||
                          item?.structuredServiceItem?.description;
                      }

                      return (
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          sx={{ marginBottom: "12px" }}
                        >
                          <Grid container alignItems="center">
                            <Grid
                              item
                              xs={11.5}
                              lg={11.5}
                              // mt={1}
                              sx={{
                                border: "1px solid #c7c7c7c7",
                                padding: "0.5em",
                                borderRadius: "3px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleOpenServicesModel(item, index)
                              }
                            >
                              <Grid container alignItems="center">
                                <Grid item xs={11.5} sm={11.5} lg={11.5}>
                                  <Typography className={classes.servicesTypo}>
                                    {displayName}
                                  </Typography>

                                  <Typography
                                    className={classes.servicesTypoDesc}
                                  >
                                    {description.length > 120
                                      ? `${description.slice(0, 120)}...`
                                      : description}
                                  </Typography>
                                </Grid>
                                <Grid item xs={0.5} sm={0.5} lg={0.5} mt={1.5}>
                                  <ChevronRightIcon sx={{ color: "#8D9298" }} />
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid
                              item
                              xs={0.5}
                              lg={0.5}
                              // mt={1}
                            >
                              <IconButton
                                onClick={() => handleRemoveService(item, index)}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      );
                    })}

                  {/* </Grid> */}

                  {showAddServiceField && (
                    <Grid container>
                      <Grid item xs={11.5} sm={11.5} lg={11.5}>
                        <TextField
                          id="outlined-basic"
                          variant="outlined"
                          placeholder={t("Add_Services")}
                          value={customService}
                          fullWidth
                          onChange={handleChangeCustomService}
                          onKeyPress={handleEnterKeyPress}
                        />
                      </Grid>

                      <Grid item xs={0.5} sm={0.5} lg={0.5}>
                        {" "}
                        <IconButton
                          onClick={() => {
                            setCustomService("");
                            setShowAddServiceField(false);
                            setIspressEnter(false);
                            setDuplicateService(false);
                            setServiceAlreadyExits(false);
                          }}
                          className="delete_button"
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      {(!customService && showAddServiceField) ||
                      ispressEnter ? (
                        <Typography
                          sx={{
                            color: "#c0c0c0",
                            fontSize: "12px",
                            margin: "5px",
                          }}
                        >
                          {t("add_service")}
                        </Typography>
                      ) : duplicateService ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            margin: "5px",
                          }}
                        >
                          {t("Services_duplicated_error")}
                        </Typography>
                      ) : serviceAlreadyExits ? (
                        <Typography
                          sx={{
                            color: "red",
                            fontSize: "12px",
                            margin: "5px",
                          }}
                        >
                          {t("Services_already_exists")}
                        </Typography>
                      ) : null}
                    </Grid>
                  )}
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    lg={12}
                    mb={2}
                    sx={{ marginTop: "10px", marginLeft: "-10px" }}
                  >
                    <Button
                      disabled={
                        (!customService && showAddServiceField) || ispressEnter
                      }
                      onClick={handleAddNewData}
                      className={classes.addButton}
                    >
                      <AddIcon fontSize="medium" />{" "}
                      <Typography
                        className={
                          (!customService && showAddServiceField) ||
                          ispressEnter
                            ? classes.addButtonDisabled
                            : classes.addButton
                        }
                      >
                        {t("Add_custom_services")}
                      </Typography>
                    </Button>
                  </Grid>
                  {!noServiceData &&
                    filteredSuggestions?.length > 0 &&
                    typeof filteredSuggestions[0] !== "boolean" && (
                      <>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          sx={{
                            marginBottom: "12px",
                            marginTop: "12px",
                          }}
                        >
                          <Typography
                            sx={{
                              color: "#1B2430",
                              fontSize: "15px",
                              fontWeight: 500,
                              marginLeft: "7px",
                            }}
                          >
                            {t("suggestions")}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          md={12}
                          sm={12}
                          xs={12}
                          lg={12}
                          sx={{ marginBottom: "12px" }}
                        >
                          <Grid container sx={{ marginTop: "6px" }}>
                            {filteredSuggestions?.map((item, index) => (
                              <Item
                                onClick={() => handleAddService(item, index)}
                                key={index}
                                sx={{
                                  border:
                                    item?.displayName?.toLowerCase() ===
                                    customService?.toLowerCase()
                                      ? "1px solid red"
                                      : "1px solid #EBEBEC",
                                }}
                              >
                                <AddIcon
                                  sx={{
                                    color: "#06BDFF",
                                    fontWeight: "700",
                                  }}
                                />
                                <Typography className={classes.moreHoursText}>
                                  {item?.displayName}
                                </Typography>
                              </Item>
                            ))}
                          </Grid>
                        </Grid>
                      </>
                    )}
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        <Divider />
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Back")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={
              isLoading ||
              duplicateBrand ||
              labelError ||
              labelLimitError ||
              (!customService && showAddServiceField) ||
              ispressEnter
            }
            onSubmit={handleKeywords}
            //  onSubmit={() => handleOpen()}
            label={t("Save")}
          />
        </Grid>
      </>
    </Grid>
  );
};

export default CompanyLabel;

import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
  IconButton,
  Typography,
  Divider,
  DialogTitle,
  Dialog,
  DialogActions,
  Box,
  Checkbox,
  Tabs,
  Tab,
  Alert,
  DialogContent,
} from "@mui/material";
import CommonButton from "../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import { makeStyles } from "@mui/styles";
import { languagesData } from "../../../../assets/defaultLanguage";
import { api } from "../../../../contexts/JWTContext";
import ReviewTextPresent from "./ReviewTextPresent";

const useStyles = makeStyles((theme) => ({
  headingText: {
    fontWeight: "400",
    fontSize: "16px",
    lineHeight: "24px",
    color: "#495059",
  },
  headingTextGrid: {
    marginBottom: "10px",
  },
  mainHeadingContainer: {
    //  paddingTop: "10px",
    //  paddingLeft: "20px",
    // paddingRight: "20px",
    //  paddingBottom: "20px",
    width: "100%",
  },
  mainKeyWordContainer: {
    paddingTop: "15px",
  },
  labelText: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    color: "#1B2430",
  },
  titleText: {
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "28px",
    color: "#1B2430",
  },
  errorText: {
    color: "#d32f2f",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  helpingText: {
    color: "#c0c0c0",
    marginTop: "3px",
    marginLeft: "14px",
    fontSize: "0.6964285714285714rem",
  },
  postSubmitButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "98px",
    height: "48px",
    borderRadius: "8px",
  },
  PostAiButton: {
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "20px",
    width: "136px",
    height: "48px",
    borderRadius: "8px",
  },
}));

const PostAIModal = ({
  title,
  onCancel,
  openDefaultModel,
  textPresentPrompt,
  setTextPresentPrompt,
  tags,
  setTags,
  textNotPresentPrompt,
  setTextNotPresentPrompt,
  tagsNotPresent,
  setTagsNotPresent,
  selectedReviewOption,
  formalChecked,
  setFormalChecked,
  reviewToneChecked,
  setReviewToneChecked,
  friendlyChecked,
  setFriendlyChecked,
  includeChecked,
  setIncludeChecked,
  excludeChecked,
  setExcludeChecked,
  yesChecked,
  setYesChecked,
  noChecked,
  setNoChecked,
  moreLanguageChecked,
  setMoreLanguageChecked,
  userLanguageChecked,
  setUserLanguageChecked,
  userLanguage,
  setUserLanguage,
  reviewLanguageChecked,
  setReviewLanguageChecked,
  extraData,
  setExtraData,
  formalCheckedTwo,
  setFormalCheckedTwo,
  reviewToneCheckedTwo,
  setReviewToneCheckedTwo,
  friendlyCheckedTwo,
  setFriendlyCheckedTwo,
  includeCheckedTwo,
  setIncludeCheckedTwo,
  excludeCheckedTwo,
  setExcludeCheckedTwo,
  yesCheckedTwo,
  setYesCheckedTwo,
  noCheckedTwo,
  setNoCheckedTwo,
  moreLanguageCheckedTwo,
  setMoreLanguageCheckedTwo,
  userLanguageCheckedTwo,
  setUserLanguageCheckedTwo,
  userLanguageTwo,
  setUserLanguageTwo,

  extraDataTwo,
  setExtraDataTwo,
  handleSavePrompt,
  Ratings,
  selectedLocation,
  setDisplayToast,
  displayToast,
  selectedRating,
  modelPresentPrompt,
  setModelPresentPrompt,
  modelNotPresentPrompt,
  setModelNotPresentPrompt,
  userTranslationLanguage,
  setUserTranslationLanguage,
  userTranslationLanguageTwo,
  setUserTranslationLanguageTwo,
  selectAllPresent,
  setSelectAllPresent,
  selectAllNotPresent,
  setSelectAllNotPresent,
  handleDisplayNames,
  defaultLocation,
  getLocation,
  singleChecked,
  setSingleChecked,
  singleCheckedTwo,
  setSingleCheckedTwo,
  multiChecked,
  setMultiChecked,
  setMultiCheckedTwo,
  multiCheckedTwo,
  tagsRequired,
  setTagsRequired,
  setTagsRequiredTwo,
  tagsRequiredTwo,
  tagsGridRef,
  tagsGridRefTwo,
  selectedTab,
  setSelectedTab,
  langGridRef,
  langGridRefTwo,
  setLanguageRequired,
  languageRequired,
  languageRequiredTwo,
  setLanguageRequiredTwo,
  scrollStateLang,
  setScrollStateLang,
  scrollStateLangTwo,
  setScrollStateLangTwo,
  scrollStateTags,
  setScrollStateTags,
  scrollStateTagsTwo,
  setScrollStateTagsTwo,

  excludeNameChecked,
  setExcludeNameChecked,
  excludeNameCheckedtwo,
  setExcludeNameCheckedTwo,
  firstNameChecked,
  setFirstNameChecked,
  firstNameCheckedTwo,
  setFirstNameCheckedTwo,
  fullNameChecked,
  setFullNameChecked,
  fullNameCheckedTwo,
  setFullNameCheckedTwo,
  defaultEmailchecked,
  setDefualtEmailChecked,
  defaultEmailcheckedTwo,
  setDefualtEmailCheckedTwo,
  customEmailchecked,
  setCustomEmailChecked,
  customEmailcheckedTwo,
  setCustomEmailCheckedTwo,
  emailValue,
  setEmailValue,
  setEmailValueTwo,
  emailValueTwo,
  setEmailValidation,
  emailValidation,
  setEmailValidationTwo,
  emailValidationTwo,
  signatureValue,
  setSignatureValue,
  signatureValueTwo,
  setSignatureValueTwo,
  setAutoSelect,
  autoSelect,

  setCustomLengthValue,
  customLengthValue,
  setCustomLengthValueTwo,
  customLengthValueTwo,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const [openTestModel, setOpenTestModel] = useState(false);
  const [textPresentGeneratedContent, setTextPresentGeneratedContent] =
    useState("");
  const [allReviewSource, setAllReviewSource] = useState([]);
  const [textNotPresentGeneratedContent, setTextNotPresentGeneratedContent] =
    useState("");
  const [reviewsData, setReviewsData] = useState([]);
  const [dummyReviewsData, setDummyReviewsData] = useState([]);

  useEffect(() => {
    if (openDefaultModel) {
      getReviewsData();
      getAllThirdPartySource();
      if (
        selectedReviewOption?.some((item) => item?.label === "Present") &&
        selectedReviewOption?.some((item) => item?.label === "Not Present")
      ) {
        setSelectedTab(0);
      } else if (
        selectedReviewOption?.some((item) => item?.label === "Present") ==
          false &&
        selectedReviewOption?.some((item) => item?.label === "Not Present") ===
          true
      ) {
        setSelectedTab(1);
      } else {
        setSelectedTab(0);
      }
    }
  }, [openDefaultModel]);

  const handleChange = (event, newValue) => {
    setSelectedTab(newValue);
    setDisplayToast(false);
    setTagsRequired(false);
    setTagsRequiredTwo(false);
    setLanguageRequired(false);
    setLanguageRequiredTwo(false);
  };

  const handleOpenTestModel = () => {
    let tagsError = false;
    let tagsErrorTwo = false;
    let langError = false;
    let langErrorTwo = false;
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      (excludeChecked || includeChecked) &&
      tags?.length === 0
    ) {
      setTagsRequired(true);
      setScrollStateTags(true);
      tagsError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      (excludeCheckedTwo || includeCheckedTwo) &&
      tagsNotPresent?.length === 0
    ) {
      setTagsRequiredTwo(true);
      setScrollStateTagsTwo(true);
      tagsErrorTwo = true;
    } else {
      setTagsRequired(false);
      tagsError = false;
      setTagsRequiredTwo(false);
      setScrollStateTags(false);
      setTagsRequiredTwo(false);
      tagsErrorTwo = false;
    }
    if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Present") ||
        selectedTab === 0) &&
      moreLanguageChecked &&
      userLanguage?.length === 0
    ) {
      setLanguageRequired(true);
      setScrollStateLang(true);
      langError = true;
    } else if (
      ((selectedReviewOption?.length === 1 &&
        selectedReviewOption[0]?.label === "Not Present") ||
        selectedTab === 1) &&
      moreLanguageCheckedTwo &&
      userLanguageTwo?.length === 0
    ) {
      setLanguageRequiredTwo(true);
      setScrollStateLangTwo(true);

      langErrorTwo = true;
    } else {
      setLanguageRequiredTwo(false);
      setLanguageRequiredTwo(false);
      setScrollStateLangTwo(false);
      langError = false;
      setLanguageRequired(false);
      langErrorTwo = false;
    }
    if (
      tagsError === false &&
      tagsErrorTwo === false &&
      langError === false &&
      langErrorTwo === false
    ) {
      setOpenTestModel(true);
    }
  };
  const handleCloseTestModel = () => {
    setOpenTestModel(false);
  };

  const getReviewsData = async () => {
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds?.push(item?.id);
      });
    }
    let ratings = [];
    if (Ratings?.length > 0) {
      Ratings?.map((item) => {
        ratings?.push(item?.id);
      });
    }

    try {
      const res = await api.get(
        `/review?limit=20&page=1&filters[locationId][$equals]=${locationIds?.toString()}&filters[ratingValue][$equals]=${ratings?.toString()}${
          selectedReviewOption?.some((item) => item?.label === "Present")
            ? "&filters[ratingText][$ne]=" + null
            : ""
        }`
      );

      if (res.status < 400 && res.data.data?.results?.length > 0) {
        setReviewsData(res.data.data?.results);
      } else {
        setReviewsData([]);
      }
    } catch (error) {
      setReviewsData([]);
    }
  };
  const getAllThirdPartySource = async () => {
    try {
      const res = await api.get(`/thirdPartyReviewSource/`, {
        params: {
          limit: 100,
        },
      });
      if (res.status === 200) {
        setAllReviewSource(res.data.data);
        let tempSelected;
      }
    } catch (error) {}
  };

  const getDummyReviewsData = async () => {
    let locationIds = [];
    if (selectedLocation?.length > 0) {
      selectedLocation?.map((item) => {
        locationIds?.push(item?.id);
      });
    }

    try {
      const res = await api.get(
        `review/dummy?text=${selectedTab === 0 ? true : false}&ratingValue=${
          Ratings === "one"
            ? "1"
            : Ratings === "two"
            ? "2"
            : Ratings === "three"
            ? "3"
            : Ratings === "four"
            ? "4"
            : "5"
        }`
      );

      if (res.status === 200) {
        let tempArray = res.data.data;
        tempArray?.forEach((item) => {
          item["locationId"] = defaultLocation?.id;
        });

        setDummyReviewsData(tempArray);
      } else {
        setDummyReviewsData([]);
      }
    } catch (error) {
      setDummyReviewsData([]);
    }
  };

  useEffect(() => {
    if (reviewsData?.length === 0) {
      getDummyReviewsData();
    }
  }, [reviewsData, selectedTab]);

  const handleChangeAutoSelect = (e) => {
    setAutoSelect(e.target.checked);
    if (e.target.checked) {
      if (selectedTab === 0) {
        // Response Tone
        setFormalCheckedTwo(formalChecked);
        setFriendlyCheckedTwo(friendlyChecked);
        setReviewToneCheckedTwo(reviewToneChecked);

        // Reply on behalf
        setSingleCheckedTwo(singleChecked);
        setMultiCheckedTwo(multiChecked);

        // Style
        setExcludeCheckedTwo(excludeChecked);
        setIncludeCheckedTwo(includeChecked);
        setTagsNotPresent(tags);

        // Customer name
        setFullNameCheckedTwo(fullNameChecked);
        setFirstNameCheckedTwo(firstNameChecked);
        setExcludeNameCheckedTwo(excludeNameChecked);

        // Business Email
        setDefualtEmailCheckedTwo(defaultEmailchecked);
        setCustomEmailCheckedTwo(customEmailchecked);
        setEmailValueTwo(emailValue);

        // Signature
        setYesCheckedTwo(yesChecked);
        setNoCheckedTwo(noChecked);
        setSignatureValueTwo(signatureValue);

        // Length
        setCustomLengthValueTwo(customLengthValue);

        // Extras
        setExtraDataTwo(extraData);

        let str = modelPresentPrompt;
        str = str.replace(
          "left a review with text",
          "has left a review without text"
        );
        if (str.includes("{{-reviewLanguage-}}")) {
          str = str.replace("{{-reviewLanguage-}}", "{{-locationLanguage-}}");
        } else if (str.includes("{{-multipleLanguage-}}")) {
          str = str.replace("{{-multipleLanguage-}}", "{{-locationLanguage-}}");
        } else {
          let userSelectedLang = userLanguage?.map((item) => item?.language);
          let langString = [];
          let userSelectedLangs = [];
          languagesData.forEach((language) => {
            userLanguage?.forEach((item) => {
              if (item?.language === language?.language) {
                userSelectedLangs?.push(language);
              }
            });
          });
          langString = userSelectedLangs?.map((item) => item?.language);

          if (str?.includes(langString?.toString())) {
            str = str.replace(langString?.toString(), "{{-locationLanguage-}}");
          }
        }

        str = str.replace("Review: {{-reviewText-}}", "");
        setModelNotPresentPrompt(str);
      } else {
        // Response Tone
        setFormalChecked(formalCheckedTwo);
        setFriendlyChecked(friendlyCheckedTwo);
        setReviewToneChecked(reviewToneCheckedTwo);

        // Reply on behalf
        setSingleChecked(singleCheckedTwo);
        setMultiChecked(multiCheckedTwo);

        // Style
        setExcludeChecked(excludeCheckedTwo);
        setIncludeChecked(includeCheckedTwo);
        setTags(tagsNotPresent);

        // Customer name
        setFullNameChecked(fullNameCheckedTwo);
        setFirstNameChecked(firstNameCheckedTwo);
        setExcludeNameChecked(excludeNameCheckedtwo);

        // Business Email
        setDefualtEmailChecked(defaultEmailcheckedTwo);
        setCustomEmailChecked(customEmailcheckedTwo);
        setEmailValue(emailValueTwo);

        // Signature
        setYesChecked(yesCheckedTwo);
        setNoChecked(noCheckedTwo);
        setSignatureValue(signatureValueTwo);

        // Length

        setCustomLengthValue(customLengthValueTwo);

        // Extras
        setExtraData(extraDataTwo);

        // Language
        setReviewLanguageChecked(false);
        setUserLanguage([]);
        setUserLanguageChecked(true);
        setMoreLanguageChecked(false);

        let str = modelNotPresentPrompt;
        str = str.replace(
          "has left a review without text",
          "left a review with text"
        );
        str = str.replace(
          "Star Rating: {{-ratingStars-}}",
          "Star Rating: {{-ratingStars-}} Review: {{-reviewText-}}"
        );

        setModelPresentPrompt(str);
      }
    }
  };

  return (
    <>
      <Dialog
        maxWidth
        maxHeight
        open={openDefaultModel}
        onClose={onCancel}
        sx={{ "& .MuiDialog-paper": { width: "900px", height: "700px" } }}
      >
        <DialogTitle>
          <Box sx={{ display: "flex" }}>
            <Grid item xs={11} sm={11} md={11} lg={11}>
              <Typography className={classes.titleText}>
                {t("Customised_prompt")}
              </Typography>
            </Grid>

            <Grid
              item
              xs={1}
              sm={1}
              md={1}
              lg={1}
              sx={{ justifyContent: "flex-end", textAlign: "end" }}
            >
              <IconButton
                autoFocus
                onClick={onCancel}
                className="delete_button"
                sx={{ paddingTop: "0px" }}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Box>
          <Grid className={classes.headingTextGrid}>
            <Typography className={classes.headingText}>
              {t("prompt_model_subHeading")}
            </Typography>
          </Grid>
        </DialogTitle>

        <Divider variant="middle" />
        <DialogContent>
          {selectedReviewOption?.length > 0 &&
            selectedReviewOption?.length === 2 && (
              <Grid container>
                <Grid xs={12} md={12} sm={12} lg={12}>
                  <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
                    <Tabs
                      sx={{ width: "60%" }}
                      className={classes.Tabs}
                      value={selectedTab}
                      onChange={handleChange}
                      variant="scrollable"
                      centered
                    >
                      <Tab
                        sx={{ width: "50%", paddingTop: "0px" }}
                        label={t("Review_text_present")}
                        disabled={
                          selectedReviewOption?.some(
                            (item) => item?.label === "Present"
                          ) === false
                        }
                      />

                      <Tab
                        sx={{ width: "50%" }}
                        label={t("Review_text_not_present")}
                        disabled={
                          selectedReviewOption?.some(
                            (item) => item?.label === "Not Present"
                          ) === false
                        }
                      />
                    </Tabs>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  sx={{ display: "flex", marginTop: "8px" }}
                >
                  <Checkbox
                    checked={autoSelect}
                    onChange={(e) => {
                      handleChangeAutoSelect(e);

                      //  handleChangeLocationModel(arr);
                      //  handleChangeGroupsModel(arr, e.target.checked);
                    }}
                    size="small"
                    sx={{ paddingRight: "3px", paddingLeft: "0px" }}
                  />
                  <Typography sx={{ fontSize: "12px", marginTop: "10px" }}>
                    {t("prompt_both_checkbox")}{" "}
                    {selectedTab !== 0
                      ? t("Review_text_present")
                      : t("Review_text_not_present")}
                  </Typography>
                </Grid>

                {selectedTab === 0 && (
                  <ReviewTextPresent
                    present={true}
                    textPresentPrompt={textPresentPrompt}
                    formalChecked={formalChecked}
                    setFormalChecked={setFormalChecked}
                    setFormalCheckedTwo={setFormalCheckedTwo}
                    reviewToneChecked={reviewToneChecked}
                    setReviewToneChecked={setReviewToneChecked}
                    setReviewToneCheckedTwo={setReviewToneCheckedTwo}
                    friendlyChecked={friendlyChecked}
                    setFriendlyChecked={setFriendlyChecked}
                    setFriendlyCheckedTwo={setFriendlyCheckedTwo}
                    includeChecked={includeChecked}
                    setIncludeChecked={setIncludeChecked}
                    setIncludeCheckedTwo={setIncludeCheckedTwo}
                    excludeChecked={excludeChecked}
                    setExcludeChecked={setExcludeChecked}
                    setExcludeCheckedTwo={setExcludeCheckedTwo}
                    yesChecked={yesChecked}
                    setYesChecked={setYesChecked}
                    setYesCheckedTwo={setYesCheckedTwo}
                    noChecked={noChecked}
                    setNoChecked={setNoChecked}
                    setNoCheckedTwo={setNoCheckedTwo}
                    moreLanguageChecked={moreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageChecked}
                    moreLanguageCheckedTwo={moreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageChecked}
                    setUserLanguageChecked={setUserLanguageChecked}
                    setUserLanguageCheckedTwo={setUserLanguageCheckedTwo}
                    userLanguage={userLanguage}
                    setUserLanguage={setUserLanguage}
                    setUserLanguageTwo={setUserLanguageTwo}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tags}
                    setTags={setTags}
                    setTagsNotPresent={setTagsNotPresent}
                    tagsNotPresent={tagsNotPresent}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    extraData={extraData}
                    setExtraData={setExtraData}
                    setExtraDataTwo={setExtraDataTwo}
                    setTextPresentPrompt={setTextPresentPrompt}
                    setTextNotPresentPrompt={setTextNotPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelPresentPrompt}
                    setModelPresentPrompt={setModelPresentPrompt}
                    setModelNotPresentPrompt={setModelNotPresentPrompt}
                    modelNotPresentPrompt={modelNotPresentPrompt}
                    userTranslationLanguage={userTranslationLanguage}
                    setUserTranslationLanguage={setUserTranslationLanguage}
                    setUserTranslationLanguageTwo={
                      setUserTranslationLanguageTwo
                    }
                    selectAllPresent={selectAllPresent}
                    setSelectAllPresent={setSelectAllPresent}
                    setSelectAllNotPresent={setSelectAllNotPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleChecked}
                    setSingleChecked={setSingleChecked}
                    setSingleCheckedTwo={setSingleCheckedTwo}
                    multiChecked={multiChecked}
                    setMultiChecked={setMultiChecked}
                    setMultiCheckedTwo={setMultiCheckedTwo}
                    tagsRequired={tagsRequired}
                    setTagsRequired={setTagsRequired}
                    tagsRequiredTwo={tagsRequiredTwo}
                    tagsGridRef={tagsGridRef}
                    langGridRef={langGridRef}
                    setLanguageRequired={setLanguageRequired}
                    languageRequired={languageRequired}
                    scrollStateLang={scrollStateLang}
                    setScrollStateLang={setScrollStateLang}
                    scrollStateTags={scrollStateTags}
                    setScrollStateTags={setScrollStateTags}
                    excludeNameChecked={excludeNameChecked}
                    setExcludeNameChecked={setExcludeNameChecked}
                    setExcludeNameCheckedTwo={setExcludeNameCheckedTwo}
                    firstNameChecked={firstNameChecked}
                    setFirstNameChecked={setFirstNameChecked}
                    setFirstNameCheckedTwo={setFirstNameCheckedTwo}
                    fullNameChecked={fullNameChecked}
                    setFullNameChecked={setFullNameChecked}
                    setFullNameCheckedTwo={setFullNameCheckedTwo}
                    defaultEmailchecked={defaultEmailchecked}
                    setDefualtEmailChecked={setDefualtEmailChecked}
                    setDefualtEmailCheckedTwo={setDefualtEmailCheckedTwo}
                    customEmailchecked={customEmailchecked}
                    setCustomEmailChecked={setCustomEmailChecked}
                    setCustomEmailCheckedTwo={setCustomEmailCheckedTwo}
                    emailValue={emailValue}
                    setEmailValue={setEmailValue}
                    setEmailValueTwo={setEmailValueTwo}
                    setEmailValidation={setEmailValidation}
                    emailValidation={emailValidation}
                    signatureValue={signatureValue}
                    setSignatureValue={setSignatureValue}
                    setSignatureValueTwo={setSignatureValueTwo}
                    setAutoSelect={setAutoSelect}
                    autoSelect={autoSelect}
                    setCustomLengthValue={setCustomLengthValue}
                    customLengthValue={customLengthValue}
                    setCustomLengthValueTwo={setCustomLengthValueTwo}
                  />
                )}
                {selectedTab === 1 && (
                  <ReviewTextPresent
                    present={false}
                    textPresentPrompt={textNotPresentPrompt}
                    formalChecked={formalCheckedTwo}
                    setFormalChecked={setFormalCheckedTwo}
                    setFormalCheckedTwo={setFormalChecked}
                    reviewToneChecked={reviewToneCheckedTwo}
                    setReviewToneChecked={setReviewToneCheckedTwo}
                    setReviewToneCheckedTwo={setReviewToneChecked}
                    friendlyChecked={friendlyCheckedTwo}
                    setFriendlyChecked={setFriendlyCheckedTwo}
                    setFriendlyCheckedTwo={setFriendlyChecked}
                    includeChecked={includeCheckedTwo}
                    setIncludeChecked={setIncludeCheckedTwo}
                    setIncludeCheckedTwo={setIncludeChecked}
                    excludeChecked={excludeCheckedTwo}
                    setExcludeChecked={setExcludeCheckedTwo}
                    setExcludeCheckedTwo={setExcludeChecked}
                    yesChecked={yesCheckedTwo}
                    setYesChecked={setYesCheckedTwo}
                    setYesCheckedTwo={setYesChecked}
                    noChecked={noCheckedTwo}
                    setNoChecked={setNoCheckedTwo}
                    setNoCheckedTwo={setNoChecked}
                    moreLanguageChecked={moreLanguageCheckedTwo}
                    setMoreLanguageCheckedTwo={setMoreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageCheckedTwo}
                    setUserLanguageChecked={setUserLanguageCheckedTwo}
                    setUserLanguageCheckedTwo={setUserLanguageChecked}
                    userLanguage={userLanguageTwo}
                    setUserLanguage={setUserLanguageTwo}
                    setUserLanguageTwo={setUserLanguage}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tagsNotPresent}
                    setTags={setTagsNotPresent}
                    setTagsNotPresent={setTags}
                    tagsNotPresent={tags}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextNotPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textNotPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    extraData={extraDataTwo}
                    setExtraData={setExtraDataTwo}
                    setExtraDataTwo={setExtraData}
                    setTextPresentPrompt={setTextNotPresentPrompt}
                    setTextNotPresentPrompt={setTextPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelNotPresentPrompt}
                    setModelPresentPrompt={setModelNotPresentPrompt}
                    setModelNotPresentPrompt={setModelPresentPrompt}
                    modelNotPresentPrompt={modelPresentPrompt}
                    userTranslationLanguage={userTranslationLanguageTwo}
                    setUserTranslationLanguage={setUserTranslationLanguageTwo}
                    setUserTranslationLanguageTwo={setUserTranslationLanguage}
                    selectAllPresent={selectAllNotPresent}
                    setSelectAllPresent={setSelectAllNotPresent}
                    setSelectAllNotPresent={setSelectAllPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleCheckedTwo}
                    setSingleChecked={setSingleCheckedTwo}
                    setSingleCheckedTwo={setSingleChecked}
                    multiChecked={multiCheckedTwo}
                    setMultiChecked={setMultiCheckedTwo}
                    setMultiCheckedTwo={setMultiChecked}
                    setTagsRequired={setTagsRequiredTwo}
                    tagsRequired={tagsRequiredTwo}
                    tagsRequiredTwo={setTagsRequired}
                    tagsGridRef={tagsGridRefTwo}
                    langGridRef={langGridRefTwo}
                    languageRequired={languageRequiredTwo}
                    setLanguageRequired={setLanguageRequiredTwo}
                    scrollStateLang={scrollStateLangTwo}
                    setScrollStateLang={setScrollStateLangTwo}
                    scrollStateTags={scrollStateTagsTwo}
                    setScrollStateTags={setScrollStateTagsTwo}
                    excludeNameChecked={excludeNameCheckedtwo}
                    setExcludeNameChecked={setExcludeNameCheckedTwo}
                    setExcludeNameCheckedTwo={setExcludeNameChecked}
                    firstNameChecked={firstNameCheckedTwo}
                    setFirstNameChecked={setFirstNameCheckedTwo}
                    setFirstNameCheckedTwo={setFirstNameChecked}
                    fullNameChecked={fullNameCheckedTwo}
                    setFullNameChecked={setFullNameCheckedTwo}
                    setFullNameCheckedTwo={setFullNameChecked}
                    defaultEmailchecked={defaultEmailcheckedTwo}
                    setDefualtEmailChecked={setDefualtEmailCheckedTwo}
                    setDefualtEmailCheckedTwo={setDefualtEmailChecked}
                    customEmailchecked={customEmailcheckedTwo}
                    setCustomEmailChecked={setCustomEmailCheckedTwo}
                    setCustomEmailCheckedTwo={setCustomEmailChecked}
                    setEmailValue={setEmailValueTwo}
                    emailValue={emailValueTwo}
                    setEmailValueTwo={setEmailValue}
                    setEmailValidation={setEmailValidationTwo}
                    emailValidation={emailValidationTwo}
                    signatureValue={signatureValueTwo}
                    setSignatureValue={setSignatureValueTwo}
                    setSignatureValueTwo={setSignatureValue}
                    setAutoSelect={setAutoSelect}
                    autoSelect={autoSelect}
                    setCustomLengthValue={setCustomLengthValueTwo}
                    customLengthValue={customLengthValueTwo}
                    setCustomLengthValueTwo={setCustomLengthValue}
                  />
                )}
              </Grid>
            )}
          {selectedReviewOption?.length > 0 &&
            selectedReviewOption?.length === 1 && (
              <Grid container>
                {selectedReviewOption?.some(
                  (item) => item?.label === "Present"
                ) && (
                  <ReviewTextPresent
                    present={true}
                    textPresentPrompt={textPresentPrompt}
                    formalChecked={formalChecked}
                    setFormalChecked={setFormalChecked}
                    setFormalCheckedTwo={setFormalCheckedTwo}
                    reviewToneChecked={reviewToneChecked}
                    setReviewToneChecked={setReviewToneChecked}
                    setReviewToneCheckedTwo={setReviewToneCheckedTwo}
                    friendlyChecked={friendlyChecked}
                    setFriendlyChecked={setFriendlyChecked}
                    setFriendlyCheckedTwo={setFriendlyCheckedTwo}
                    includeChecked={includeChecked}
                    setIncludeChecked={setIncludeChecked}
                    setIncludeCheckedTwo={setIncludeCheckedTwo}
                    excludeChecked={excludeChecked}
                    setExcludeChecked={setExcludeChecked}
                    setExcludeCheckedTwo={setExcludeCheckedTwo}
                    yesChecked={yesChecked}
                    setYesChecked={setYesChecked}
                    setYesCheckedTwo={setYesCheckedTwo}
                    noChecked={noChecked}
                    setNoChecked={setNoChecked}
                    setNoCheckedTwo={setNoCheckedTwo}
                    moreLanguageChecked={moreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageChecked}
                    moreLanguageCheckedTwo={moreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageChecked}
                    setUserLanguageChecked={setUserLanguageChecked}
                    setUserLanguageCheckedTwo={setUserLanguageCheckedTwo}
                    userLanguage={userLanguage}
                    setUserLanguage={setUserLanguage}
                    setUserLanguageTwo={setUserLanguageTwo}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tags}
                    setTags={setTags}
                    setTagsNotPresent={setTagsNotPresent}
                    tagsNotPresent={tagsNotPresent}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    extraData={extraData}
                    setExtraData={setExtraData}
                    setExtraDataTwo={setExtraDataTwo}
                    setTextPresentPrompt={setTextPresentPrompt}
                    setTextNotPresentPrompt={setTextNotPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelPresentPrompt}
                    setModelPresentPrompt={setModelPresentPrompt}
                    setModelNotPresentPrompt={setModelNotPresentPrompt}
                    modelNotPresentPrompt={modelNotPresentPrompt}
                    userTranslationLanguage={userTranslationLanguage}
                    setUserTranslationLanguage={setUserTranslationLanguage}
                    setUserTranslationLanguageTwo={
                      setUserTranslationLanguageTwo
                    }
                    selectAllPresent={selectAllPresent}
                    setSelectAllPresent={setSelectAllPresent}
                    setSelectAllNotPresent={setSelectAllNotPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleChecked}
                    setSingleChecked={setSingleChecked}
                    setSingleCheckedTwo={setSingleCheckedTwo}
                    multiChecked={multiChecked}
                    setMultiChecked={setMultiChecked}
                    setMultiCheckedTwo={setMultiCheckedTwo}
                    tagsRequired={tagsRequired}
                    setTagsRequired={setTagsRequired}
                    tagsRequiredTwo={tagsRequiredTwo}
                    tagsGridRef={tagsGridRef}
                    langGridRef={langGridRef}
                    setLanguageRequired={setLanguageRequired}
                    languageRequired={languageRequired}
                    scrollStateLang={scrollStateLang}
                    setScrollStateLang={setScrollStateLang}
                    scrollStateTags={scrollStateTags}
                    setScrollStateTags={setScrollStateTags}
                    excludeNameChecked={excludeNameChecked}
                    setExcludeNameChecked={setExcludeNameChecked}
                    setExcludeNameCheckedTwo={setExcludeNameCheckedTwo}
                    firstNameChecked={firstNameChecked}
                    setFirstNameChecked={setFirstNameChecked}
                    setFirstNameCheckedTwo={setFirstNameCheckedTwo}
                    fullNameChecked={fullNameChecked}
                    setFullNameChecked={setFullNameChecked}
                    setFullNameCheckedTwo={setFullNameCheckedTwo}
                    defaultEmailchecked={defaultEmailchecked}
                    setDefualtEmailChecked={setDefualtEmailChecked}
                    setDefualtEmailCheckedTwo={setDefualtEmailCheckedTwo}
                    customEmailchecked={customEmailchecked}
                    setCustomEmailChecked={setCustomEmailChecked}
                    setCustomEmailCheckedTwo={setCustomEmailCheckedTwo}
                    emailValue={emailValue}
                    setEmailValue={setEmailValue}
                    setEmailValueTwo={setEmailValueTwo}
                    setEmailValidation={setEmailValidation}
                    emailValidation={emailValidation}
                    signatureValue={signatureValue}
                    setSignatureValue={setSignatureValue}
                    setSignatureValueTwo={setSignatureValueTwo}
                    setAutoSelect={setAutoSelect}
                    autoSelect={autoSelect}
                    setCustomLengthValue={setCustomLengthValue}
                    customLengthValue={customLengthValue}
                    setCustomLengthValueTwo={setCustomLengthValueTwo}
                  />
                )}

                {selectedReviewOption?.some(
                  (item) => item?.label === "Not Present"
                ) && (
                  <ReviewTextPresent
                    present={false}
                    textPresentPrompt={textNotPresentPrompt}
                    formalChecked={formalCheckedTwo}
                    setFormalChecked={setFormalCheckedTwo}
                    setFormalCheckedTwo={setFormalChecked}
                    reviewToneChecked={reviewToneCheckedTwo}
                    setReviewToneChecked={setReviewToneCheckedTwo}
                    setReviewToneCheckedTwo={setReviewToneChecked}
                    friendlyChecked={friendlyCheckedTwo}
                    setFriendlyChecked={setFriendlyCheckedTwo}
                    setFriendlyCheckedTwo={setFriendlyChecked}
                    includeChecked={includeCheckedTwo}
                    setIncludeChecked={setIncludeCheckedTwo}
                    setIncludeCheckedTwo={setIncludeChecked}
                    excludeChecked={excludeCheckedTwo}
                    setExcludeChecked={setExcludeCheckedTwo}
                    setExcludeCheckedTwo={setExcludeChecked}
                    yesChecked={yesCheckedTwo}
                    setYesChecked={setYesCheckedTwo}
                    setYesCheckedTwo={setYesChecked}
                    noChecked={noCheckedTwo}
                    setNoChecked={setNoCheckedTwo}
                    setNoCheckedTwo={setNoChecked}
                    moreLanguageChecked={moreLanguageCheckedTwo}
                    setMoreLanguageCheckedTwo={setMoreLanguageChecked}
                    setMoreLanguageChecked={setMoreLanguageCheckedTwo}
                    userLanguageChecked={userLanguageCheckedTwo}
                    setUserLanguageChecked={setUserLanguageCheckedTwo}
                    setUserLanguageCheckedTwo={setUserLanguageChecked}
                    userLanguage={userLanguageTwo}
                    setUserLanguage={setUserLanguageTwo}
                    setUserLanguageTwo={setUserLanguage}
                    reviewLanguageChecked={reviewLanguageChecked}
                    setReviewLanguageChecked={setReviewLanguageChecked}
                    tags={tagsNotPresent}
                    setTags={setTagsNotPresent}
                    setTagsNotPresent={setTags}
                    tagsNotPresent={tags}
                    openTestModel={openTestModel}
                    setTextPresentGeneratedContent={
                      setTextNotPresentGeneratedContent
                    }
                    textPresentGeneratedContent={textNotPresentGeneratedContent}
                    handleCloseTestModel={handleCloseTestModel}
                    extraData={extraDataTwo}
                    setExtraData={setExtraDataTwo}
                    setExtraDataTwo={setExtraData}
                    setTextPresentPrompt={setTextNotPresentPrompt}
                    setTextNotPresentPrompt={setTextPresentPrompt}
                    reviewsData={reviewsData}
                    dummyReviewsData={dummyReviewsData}
                    allReviewSource={allReviewSource}
                    openDefaultModel={openDefaultModel}
                    selectedTab={selectedTab}
                    setDisplayToast={setDisplayToast}
                    displayToast={displayToast}
                    selectedRating={selectedRating}
                    modelPresentPrompt={modelNotPresentPrompt}
                    setModelPresentPrompt={setModelNotPresentPrompt}
                    setModelNotPresentPrompt={setModelPresentPrompt}
                    modelNotPresentPrompt={modelNotPresentPrompt}
                    userTranslationLanguage={userTranslationLanguageTwo}
                    setUserTranslationLanguage={setUserTranslationLanguageTwo}
                    setUserTranslationLanguageTwo={setUserTranslationLanguage}
                    selectAllPresent={selectAllNotPresent}
                    setSelectAllPresent={setSelectAllNotPresent}
                    setSelectAllNotPresent={setSelectAllPresent}
                    handleDisplayNames={handleDisplayNames}
                    getLocation={getLocation}
                    defaultLocation={defaultLocation}
                    singleChecked={singleCheckedTwo}
                    setSingleChecked={setSingleCheckedTwo}
                    setSingleCheckedTwo={setSingleChecked}
                    multiChecked={multiCheckedTwo}
                    setMultiChecked={setMultiCheckedTwo}
                    setMultiCheckedTwo={setMultiChecked}
                    setTagsRequired={setTagsRequiredTwo}
                    tagsRequired={tagsRequiredTwo}
                    tagsRequiredTwo={setTagsRequired}
                    tagsGridRef={tagsGridRefTwo}
                    langGridRef={langGridRefTwo}
                    languageRequired={languageRequiredTwo}
                    setLanguageRequired={setLanguageRequiredTwo}
                    scrollStateLang={scrollStateLangTwo}
                    setScrollStateLang={setScrollStateLangTwo}
                    scrollStateTags={scrollStateTagsTwo}
                    setScrollStateTags={setScrollStateTagsTwo}
                    excludeNameChecked={excludeNameCheckedtwo}
                    setExcludeNameChecked={setExcludeNameCheckedTwo}
                    setExcludeNameCheckedTwo={setExcludeNameChecked}
                    firstNameChecked={firstNameCheckedTwo}
                    setFirstNameChecked={setFirstNameCheckedTwo}
                    setFirstNameCheckedTwo={setFirstNameChecked}
                    fullNameChecked={fullNameCheckedTwo}
                    setFullNameChecked={setFullNameCheckedTwo}
                    setFullNameCheckedTwo={setFullNameChecked}
                    defaultEmailchecked={defaultEmailcheckedTwo}
                    setDefualtEmailChecked={setDefualtEmailCheckedTwo}
                    setDefualtEmailCheckedTwo={setDefualtEmailChecked}
                    customEmailchecked={customEmailcheckedTwo}
                    setCustomEmailChecked={setCustomEmailCheckedTwo}
                    setCustomEmailCheckedTwo={setCustomEmailChecked}
                    setEmailValue={setEmailValueTwo}
                    emailValue={emailValueTwo}
                    setEmailValueTwo={setEmailValue}
                    setEmailValidation={setEmailValidationTwo}
                    emailValidation={emailValidationTwo}
                    signatureValue={signatureValueTwo}
                    setSignatureValue={setSignatureValueTwo}
                    setSignatureValueTwo={setSignatureValue}
                    setAutoSelect={setAutoSelect}
                    autoSelect={autoSelect}
                    setCustomLengthValue={setCustomLengthValue}
                    customLengthValue={customLengthValue}
                    setCustomLengthValueTwo={setCustomLengthValueTwo}
                  />
                )}
              </Grid>
            )}
        </DialogContent>

        <DialogActions
          sx={{ padding: "20px", boxShadow: "0px -1px 0px #E0E0E0" }}
        >
          <Grid container>
            <Grid item xs={6} sm={6} md={6} lg={6}></Grid>
            <Grid
              item
              xs={6}
              sm={6}
              md={6}
              lg={6}
              sx={{ display: "flex", justifyContent: "space-between" }}
            >
              <CommonButton
                displayWhite="true"
                onSubmit={onCancel}
                //   label={<RefreshIcon />}
                alignment={true}
                label={t("Cancel")}
              />

              <CommonButton
                onSubmit={handleOpenTestModel}
                label={t("test_prompt")}
                alignment={true}
                className={classes.PostAiButton}
                disabled={
                  (selectedTab === 0 &&
                    ((formalChecked === false &&
                      friendlyChecked === false &&
                      reviewToneChecked === false) ||
                      ((excludeChecked === true || includeChecked === true) &&
                        tags?.length === 0) ||
                      customLengthValue === null ||
                      customLengthValue === 0 ||
                      (yesChecked === false && noChecked === false) ||
                      (userLanguageChecked === false &&
                        reviewLanguageChecked === false &&
                        moreLanguageChecked === false) ||
                      (moreLanguageChecked === true &&
                        userLanguage?.length === 0))) ||
                  (customEmailchecked &&
                    (emailValue === null || emailValue === "")) ||
                  emailValidation ||
                  (yesChecked &&
                    (signatureValue === null || signatureValue === ""))
                    ? true
                    : selectedTab === 1 &&
                      ((formalCheckedTwo === false &&
                        friendlyCheckedTwo === false &&
                        reviewToneCheckedTwo === false) ||
                        ((excludeCheckedTwo === true ||
                          includeCheckedTwo === true) &&
                          tagsNotPresent?.length === 0) ||
                        customLengthValueTwo === null ||
                        customLengthValueTwo === 0 ||
                        (yesCheckedTwo === false && noCheckedTwo === false) ||
                        (customEmailcheckedTwo &&
                          (emailValueTwo === null || emailValueTwo === "")) ||
                        emailValidationTwo ||
                        (yesCheckedTwo &&
                          (signatureValueTwo === null ||
                            signatureValueTwo === "")))
                    ? true
                    : false
                }
              />
              <CommonButton
                onSubmit={handleSavePrompt}
                disabled={
                  (selectedTab === 0 &&
                    ((formalChecked === false &&
                      friendlyChecked === false &&
                      reviewToneChecked === false) ||
                      ((excludeChecked === true || includeChecked === true) &&
                        tags?.length === 0) ||
                      customLengthValue === null ||
                      customLengthValue === 0 ||
                      (yesChecked === false && noChecked === false) ||
                      (userLanguageChecked === false &&
                        reviewLanguageChecked === false &&
                        moreLanguageChecked === false) ||
                      (moreLanguageChecked === true &&
                        userLanguage?.length === 0))) ||
                  (customEmailchecked &&
                    (emailValue === null || emailValue === "")) ||
                  emailValidation ||
                  (yesChecked &&
                    (signatureValue === null || signatureValue === ""))
                    ? true
                    : selectedTab === 1 &&
                      ((formalCheckedTwo === false &&
                        friendlyCheckedTwo === false &&
                        reviewToneCheckedTwo === false) ||
                        ((excludeCheckedTwo === true ||
                          includeCheckedTwo === true) &&
                          tagsNotPresent?.length === 0) ||
                        customLengthValueTwo === null ||
                        customLengthValueTwo === 0 ||
                        (yesCheckedTwo === false && noCheckedTwo === false) ||
                        (customEmailcheckedTwo &&
                          (emailValueTwo === null || emailValueTwo === "")) ||
                        emailValidationTwo ||
                        (yesCheckedTwo &&
                          (signatureValueTwo === null ||
                            signatureValueTwo === "")))
                    ? true
                    : false
                }
                label={t("Save")}
                alignment={true}
              />
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PostAIModal;

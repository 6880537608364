import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  Box,
  IconButton,
  Typography,
  Divider as MuiDivider,
  MenuItem,
  Menu,
  Button,
  TextField,
  Dialog,
  Chip,
  Card,
  Avatar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../../Styles/style";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { toast } from "react-toastify";
import { api } from "../../../../../../../../contexts/JWTContext";
import Delete from "../../../../../../../../components/Models/DeleteModal/Delete";
import Truncate from "react-truncate";
import Loader from "../../../../../../../../components/Loaders/Loader";
import SectionDetailModel from "./SectionDetail";

const LocationProductsSection = ({
  locationProducts,
  handleOpenProductSectionModel,
  setSelectedItemData,
  setSelectedSectionData,
  setTouchedValue,
  getLocationDetails,
  location,
  isLoading,
  setIsLoading,
  locationDetailsData,
  getAllMenus,
  search,
  setCallApi,
  selectedSectionData,
  getLocationDetailsListing,
  containerRef,
  openState,
  setOpenState,
  selectedItemData,
  setLocationProducts,
  setLoading,
  loading,
  setSearch,
  selectedLocationsBulkUpdate,
  allMedia,
  getAllMedia,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElTwo, setAnchorElTwo] = React.useState(null);
  const companyData = JSON.parse(localStorage.getItem("company"));
  const [showEdit, setShowEdit] = useState(false);
  const [sectionName, setSectionName] = useState("");
  const [loadingSectionName, setLoadingSectionName] = useState(false);
  const [openItemDeleteModel, setOpenItemDeleteModel] = useState(false);
  const [openSectionDeleteModel, setOpenSectionDeleteModel] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const [openOtherSectionModel, setOpenOtherSectionModel] = useState(false);
  const [openSectionDetailModel, setOpenSectionDetailModel] = useState(false);
  const [sectionError, setSectionError] = useState(false);
  const [locationName, setLocationName] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    setLocationProducts(tempData);
    setSectionError(false);
  };
  const handleClickSectionMenu = (event, section, ind, itemData) => {
    setAnchorElTwo(event.currentTarget);
    setSelectedSectionData(section);
    setLocationName(itemData);

    setSelectedIndex(ind);
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    setLocationProducts(tempData);
    setSectionError(false);
  };
  const handleCloseSectionMenu = () => {
    setAnchorElTwo(null);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleShowEdit = (indexValue, sectionData) => {
    setSectionName(
      sectionData !== null && sectionData?.labels?.length > 0
        ? sectionData?.labels[0]?.displayName
        : ""
    );
    let tempData = locationProducts;
    const indexValueForanySection = tempData[0]?.sections?.findIndex(
      (item) => item?.hasOwnProperty("edit") && item?.edit === true
    );
    if (indexValueForanySection > -1) {
      delete tempData[0].sections[indexValueForanySection]["edit"];
    }

    tempData[0].sections[indexValue]["edit"] = true;
    setLocationProducts(tempData);
    setSectionError(false);

    setShowEdit(true);
  };

  const handleChangeSectionName = (e) => {
    setSectionName(e.target.value);
    if (e.target.value.length > 140) {
      setSectionError(true);
    } else {
      setSectionError(false);
    }
  };
  const handleCancelButton = (indexValue) => {
    setShowEdit(false);
    let tempData = locationProducts;
    delete tempData[0].sections[indexValue]["edit"];
    setLocationProducts(tempData);

    setSectionName("");
    setSectionError(false);
  };

  const handleDataToUpdate = (data) => {
    if (data?.labels?.length > 0) {
      data.labels[0].displayName = sectionName;
      delete data["edit"];
    }

    let menuData = locationDetailsData?.result?.menus;

    let sectionIndex = menuData[0]?.sections?.findIndex(
      (item) =>
        item?.labels[0]?.displayName ===
        selectedSectionData?.labels[0]?.displayName
    );

    if (sectionIndex > -1) {
      menuData[0].sections[sectionIndex] = data;
    }

    return menuData;
  };
  const handleSaveSectionName = async () => {
    setLoadingSectionName(true);
    let dummyObj = {};

    let objjjj = JSON.stringify(selectedSectionData);

    dummyObj = JSON.parse(objjjj);
    let menuData = handleDataToUpdate(dummyObj);

    try {
      setLoadingSectionName(true);

      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
          locationId: location?.id,
        }
      );

      if (res.status === 200) {
        setLoadingSectionName(false);
        setShowEdit(false);
        setSectionName("");
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Updated_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };
  const handleDeleteItem = async () => {
    setDeleteLoading(true);
    let dummyObj = {};
    let objjjj = JSON.stringify(selectedSectionData);
    dummyObj = JSON.parse(objjjj);
    let sectionItems = [...dummyObj?.items];
    sectionItems = sectionItems?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedItemData?.labels[0]?.displayName
    );
    dummyObj.items = sectionItems;
    let menuData = locationDetailsData?.result?.menus;
    let sectionIndex = menuData[0]?.sections?.findIndex(
      (item) =>
        item?.labels[0]?.displayName ===
        selectedSectionData?.labels[0]?.displayName
    );
    if (sectionIndex > -1) {
      if (dummyObj?.items?.length > 0) {
        menuData[0].sections[sectionIndex] = dummyObj;
      } else {
        let sections = menuData[0].sections;
        sections = sections?.filter(
          (item) =>
            item?.labels[0]?.displayName !== dummyObj?.labels[0]?.displayName
        );
        menuData[0].sections = sections;
      }
    }

    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        setDeleteLoading(false);
        handleCloseItemDeleteModel();
        getAllMenus();
        getLocationDetailsListing(location);
        toast.success(`${t("Item_Deleted_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDeleteLoading(false);
    }
  };
  const handleDeleteSection = async () => {
    setDeleteLoading(true);
    let menuData = locationDetailsData?.result?.menus;
    let sections = menuData[0].sections;
    sections = sections?.filter(
      (item) =>
        item?.labels[0]?.displayName !==
        selectedSectionData?.labels[0]?.displayName
    );
    menuData[0].sections = sections;
    try {
      const res = await api.put(
        `/menus/${locationDetailsData?.result?.menusId}`,
        {
          menus: menuData,
        }
      );

      if (res.status === 200) {
        setDeleteLoading(false);
        handleCloseSectionDeleteModel();
        getAllMenus();
        getLocationDetailsListing(location);

        toast.success(`${t("Section_Deleted_Successfully")}`);
      }
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setDeleteLoading(false);
    }
  };

  const handleCloseItemDeleteModel = () => {
    setOpenItemDeleteModel(false);
  };

  const handleCloseSectionDeleteModel = () => {
    setOpenSectionDeleteModel(false);
  };

  const handleCloseOtherSectionModel = () => {
    setOpenOtherSectionModel(false);
  };

  const handleOpenSectionDetailModel = () => {
    setOpenSectionDetailModel(true);
  };
  const handleCloseSectionDetailModel = () => {
    setOpenSectionDetailModel(false);
    setLocationName(null);
  };

  const handleCheckForImage = (selectedItemValue) => {
    if (allMedia !== null)
      if (allMedia?.length > 0) {
        if (
          selectedItemValue?.attributes?.mediaKeys?.length > 0 &&
          selectedItemValue?.attributes?.mediaKeys[0]?.includes("https") ===
            false
        ) {
          const selectedImageItem = allMedia?.find(
            (item) =>
              item?.name?.includes(
                selectedItemValue?.attributes?.mediaKeys?.length > 0
                  ? selectedItemValue?.attributes?.mediaKeys[0]
                  : ""
              ) /* &&
                (item?.category === "FOOD_AND_DRINK" ||
                  item?.category === "FOOD_AND_MENU") */
          );

          return selectedImageItem;
        } else {
          return selectedItemValue?.attributes?.mediaKeys?.length > 0
            ? selectedItemValue?.attributes?.mediaKeys[0]
            : null;
        }
      }
  };

  const handleSearch = (e) => {
    setSearch(e);
    setCallApi(true);
  };

  return (
    <>
      {openSectionDetailModel && (
        <SectionDetailModel
          title={t("menu_section")}
          open={openSectionDetailModel}
          section={selectedSectionData}
          handleCloseProductSectionModel={handleCloseSectionDetailModel}
          selectedLocationsBulkUpdate={selectedLocationsBulkUpdate}
          getLocationDetailsListing={getLocationDetailsListing}
          allMedia={allMedia}
          locationName={locationName}
          getAllMenus={getAllMenus}
        />
      )}
      {openSectionDeleteModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openSectionDeleteModel}
          onClose={handleCloseSectionDeleteModel}
        >
          <Delete
            title={t("Delete_section")}
            description={`${
              selectedSectionData?.labels?.length > 0 &&
              selectedSectionData?.labels[0]?.displayName
            } ${t("Delete_sectionDes")}`}
            onConfirm={handleDeleteSection}
            onCancel={handleCloseSectionDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}
      {openItemDeleteModel && (
        <Dialog
          maxWidth
          maxHeight
          open={openItemDeleteModel}
          onClose={handleCloseItemDeleteModel}
        >
          <Delete
            title={t("Delete_section_item")}
            description={`${t("Delete_sectionDes_Item")} ${
              selectedItemData?.labels?.length > 0 &&
              selectedItemData?.labels[0]?.displayName
            }?`}
            onConfirm={handleDeleteItem}
            onCancel={handleCloseItemDeleteModel}
            loading={deleteLoading}
          />
        </Dialog>
      )}

      <Grid container className={classes.searchGrid}>
        <Grid item xs={12} sm={12} md={12} lg={12} mt={2}>
          <TextField
            placeholder={t("search_section")}
            sx={{ borderRadius: "800px", width: "97.8%" }}
            className={classes.root}
            autoComplete="off"
            //  id="outlined-name"
            value={search}
            size="small"
            InputProps={{
              className: classes.input,

              startAdornment: (
                <IconButton>
                  <SearchOutlinedIcon />
                </IconButton>
              ),
            }}
            onChange={(e) => handleSearch(e.target.value)}
          />
        </Grid>
      </Grid>

      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "460px",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Loader />
        </Box>
      ) : (
        <>
          {companyData?.menuEnabled === true && (
            <Grid container className={classes.searchGrid}>
              {" "}
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className={classes.addButtonGrid}
              >
                <Button
                  onClick={() => {
                    handleOpenProductSectionModel("section");
                  }}
                  className={classes.addButtonSectionBulk}
                >
                  <AddIcon
                    fontSize="small"
                    className={classes.addIconSection}
                  />{" "}
                  <Typography sx={{ fontWeight: 700 }}>
                    {t("product_section")}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          )}

          <Grid container className={classes.menusGrid} ref={containerRef}>
            <Box sx={{ width: "100%" }}>
              {locationProducts?.length > 0
                ? locationProducts?.map(
                    (item) =>
                      item?.menus?.length > 0 &&
                      item?.menus[0]?.sections?.length > 0 &&
                      item?.menus[0]?.sections?.map((section, ind) => {
                        const editMode = section?.hasOwnProperty("edit");
                        return (
                          <Grid
                            container
                            //  className={classes.productSectionGridBulk}
                          >
                            <Grid item xs={12} sm={12} md={12} lg={12} mb={2}>
                              <Card
                                variant="outlined"
                                sx={{
                                  bgcolor: "#ffff",
                                  borderRadius: "8px",
                                  // height: "auto",
                                }}
                              >
                                <Box className={classes.boxContainerDescBulk}>
                                  <Typography
                                    className={classes.sectionNameStyle}
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      position: "relative",
                                    }}
                                  >
                                    {section?.labels?.length > 0
                                      ? section?.labels[0]?.displayName
                                      : ""}
                                  </Typography>

                                  <Chip
                                    style={{
                                      borderRadius: "800px",
                                      cursor: "pointer",
                                      width: "auto",
                                      marginTop: "8px",
                                      height: "25px",
                                      fontSize: "10px",
                                      color: "#1B2430",
                                      fontWeight: 500,
                                    }}
                                    label={`${item?.internalName} ${
                                      item?.city !== "" && item?.city !== null
                                        ? "(" + item?.city + ")"
                                        : ""
                                    }`}
                                  />
                                  {companyData?.menuEnabled === true && (
                                    <IconButton
                                      onClick={(e) => {
                                        handleClickSectionMenu(
                                          e,
                                          section,
                                          ind,
                                          item
                                        );
                                      }}
                                      disabled={editMode == true}
                                      sx={{
                                        padding: "0px",
                                        "&:hover": {
                                          backgroundColor: "#F5F5F5",
                                        },
                                      }}
                                      // disabled={companyData?.menuEnabled === false}
                                    >
                                      <MoreVertIcon fontSize="small" />
                                    </IconButton>
                                  )}

                                  <Menu
                                    anchorEl={anchorElTwo}
                                    open={Boolean(anchorElTwo)}
                                    onClose={handleCloseSectionMenu}
                                  >
                                    <MenuItem
                                      onClick={(e) => {
                                        e.preventDefault();
                                        handleOpenSectionDetailModel();
                                        handleCloseSectionMenu();
                                      }}
                                    >
                                      <Typography>
                                        {t("view_details")}
                                      </Typography>
                                    </MenuItem>
                                  </Menu>
                                </Box>

                                {section?.items?.length > 0 &&
                                  section?.items.map(
                                    (singleItem, singleItemIndex) => {
                                      const selectedImageData =
                                        handleCheckForImage(singleItem);

                                      return (
                                        <Grid
                                          container
                                          className={
                                            classes.productItemGridBulk
                                          }
                                          key={singleItem.id}
                                        >
                                          <Grid
                                            item
                                            xs={12}
                                            sm={12}
                                            md={12}
                                            lg={12}
                                          >
                                            <Box
                                              className={
                                                classes.boxContainerDescBulk
                                              }
                                            >
                                              {" "}
                                              <Box sx={{ width: "100%" }}>
                                                <Typography
                                                  className={
                                                    classes.sectionItemNameStyle
                                                  }
                                                  sx={{
                                                    display: "flex",
                                                    alignItems: "center", // Align the text and icon vertically
                                                  }}
                                                >
                                                  {singleItem?.labels?.length >
                                                  0
                                                    ? singleItem?.labels[0]
                                                        ?.displayName
                                                    : ""}
                                                </Typography>
                                                <Box
                                                  sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                  }}
                                                >
                                                  <Box
                                                    sx={{
                                                      width: "100%",
                                                    }}
                                                  >
                                                    {singleItem?.labels
                                                      ?.length > 0 &&
                                                      singleItem?.labels[0]?.hasOwnProperty(
                                                        "description"
                                                      ) && (
                                                        <Box
                                                          sx={{
                                                            width: "95%",
                                                            display: "flex",
                                                          }}
                                                        >
                                                          <Truncate
                                                            lines={2}
                                                            ellipsis={
                                                              <span>...</span>
                                                            }
                                                            style={{
                                                              fontSize: "12px",
                                                              fontWeight: 400,
                                                              lineHeight:
                                                                "14.7px",
                                                              color: editMode
                                                                ? "#B0B0B0"
                                                                : "#1B2430",

                                                              marginBottom:
                                                                "5px",
                                                              width: "92%",
                                                            }}
                                                          >
                                                            {" "}
                                                            <Typography
                                                              className={
                                                                editMode ===
                                                                true
                                                                  ? classes.sectionItemDesStyleDisabled
                                                                  : classes.sectionItemDesStyle
                                                              }
                                                            >
                                                              {
                                                                singleItem
                                                                  ?.labels[0]
                                                                  ?.description
                                                              }
                                                            </Typography>
                                                          </Truncate>
                                                        </Box>
                                                      )}
                                                    <Box
                                                      sx={{ display: "flex" }}
                                                    >
                                                      {singleItem?.attributes &&
                                                        Object.keys(
                                                          singleItem.attributes
                                                        ).length > 0 &&
                                                        singleItem.attributes?.hasOwnProperty(
                                                          "price"
                                                        ) &&
                                                        ((singleItem?.attributes
                                                          ?.price?.units !==
                                                          null &&
                                                          singleItem?.attributes
                                                            ?.price?.units !==
                                                            "") ||
                                                          singleItem?.attributes
                                                            ?.price?.nanos !==
                                                            "") && (
                                                          <Typography
                                                            className={
                                                              editMode
                                                                ? classes.sectionItemPriceStyleDisabled
                                                                : classes.sectionItemPriceStyle
                                                            }
                                                          >
                                                            {`${
                                                              singleItem?.attributes?.price?.hasOwnProperty(
                                                                "units"
                                                              )
                                                                ? singleItem
                                                                    ?.attributes
                                                                    ?.price
                                                                    ?.units
                                                                : "0"
                                                            }${
                                                              singleItem?.attributes?.price?.hasOwnProperty(
                                                                "nanos"
                                                              ) &&
                                                              singleItem
                                                                ?.attributes
                                                                ?.price
                                                                ?.nanos !== null
                                                                ? "." +
                                                                  singleItem?.attributes?.price?.nanos
                                                                    .toString()
                                                                    .slice(0, 2)
                                                                : ""
                                                            } ${
                                                              singleItem
                                                                ?.attributes
                                                                ?.price
                                                                ?.currencyCode !==
                                                              null
                                                                ? singleItem
                                                                    ?.attributes
                                                                    ?.price
                                                                    ?.currencyCode
                                                                : ""
                                                            }`}
                                                          </Typography>
                                                        )}

                                                      {singleItem?.attributes &&
                                                        Object.keys(
                                                          singleItem.attributes
                                                        ).length > 0 &&
                                                        singleItem?.attributes?.hasOwnProperty(
                                                          "dietaryRestriction"
                                                        ) && (
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                            }}
                                                          >
                                                            {singleItem?.attributes?.hasOwnProperty(
                                                              "price"
                                                            ) === true &&
                                                              singleItem
                                                                ?.attributes
                                                                ?.price
                                                                ?.units !==
                                                                null &&
                                                              singleItem
                                                                ?.attributes
                                                                ?.price
                                                                ?.units !==
                                                                "" && (
                                                                <Box
                                                                  className={
                                                                    classes.dotStyle
                                                                  }
                                                                ></Box>
                                                              )}
                                                            <Chip
                                                              style={{
                                                                background:
                                                                  editMode ===
                                                                    false &&
                                                                  "#E3F9F2",
                                                                //   border: "1px solid #BBBDC1",
                                                                borderRadius:
                                                                  "800px",
                                                                cursor:
                                                                  "pointer",
                                                                fontWeight: 600,
                                                                width: "auto",
                                                                marginTop:
                                                                  "3px",
                                                                marginLeft:
                                                                  singleItem?.attributes?.hasOwnProperty(
                                                                    "price"
                                                                  ) === true &&
                                                                  singleItem
                                                                    ?.attributes
                                                                    ?.price
                                                                    ?.units !==
                                                                    null &&
                                                                  singleItem
                                                                    ?.attributes
                                                                    ?.price
                                                                    ?.units !==
                                                                    ""
                                                                    ? "8px"
                                                                    : "0px",
                                                                height: "25px",
                                                                fontSize:
                                                                  "10px",
                                                                color: editMode
                                                                  ? "#808080"
                                                                  : "#1B2430",
                                                              }}
                                                              label={
                                                                singleItem
                                                                  ?.attributes
                                                                  ?.dietaryRestriction
                                                                  ?.length >
                                                                  0 &&
                                                                singleItem?.attributes?.dietaryRestriction?.includes(
                                                                  "VEGETARIAN"
                                                                )
                                                                  ? "Vegetarian"
                                                                  : singleItem
                                                                      ?.attributes
                                                                      ?.dietaryRestriction
                                                                      ?.length >
                                                                      0 &&
                                                                    singleItem?.attributes?.dietaryRestriction?.includes(
                                                                      "VEGAN"
                                                                    )
                                                                  ? "Vegan"
                                                                  : ""
                                                              }
                                                            />
                                                          </Box>
                                                        )}
                                                    </Box>
                                                  </Box>

                                                  {selectedImageData !== null &&
                                                    selectedImageData !==
                                                      undefined && (
                                                      <Avatar
                                                        variant="square"
                                                        className={
                                                          classes.imageAvatarDisplay
                                                        }
                                                      >
                                                        <LazyLoadImage
                                                          loading="lazy"
                                                          src={
                                                            selectedImageData?.sourceUrl
                                                              ? selectedImageData?.sourceUrl
                                                              : selectedImageData
                                                          }
                                                          style={{
                                                            height: "60px",
                                                            width: "60px",
                                                            opacity: editMode
                                                              ? 0.5
                                                              : 1, // If editMode is true, make it semi-transparent
                                                            filter: editMode
                                                              ? "grayscale(100%)"
                                                              : "none", // Apply grayscale when editMode is true
                                                            pointerEvents:
                                                              editMode
                                                                ? "none"
                                                                : "auto", // Disable interaction when editMode is true
                                                          }}
                                                          alt="Image Alt"
                                                          effect="blur"
                                                        />
                                                      </Avatar>
                                                    )}
                                                </Box>
                                              </Box>
                                            </Box>
                                          </Grid>
                                        </Grid>
                                      );
                                    }
                                  )}
                              </Card>
                            </Grid>
                          </Grid>
                        );
                      })
                  )
                : (search !== "" || locationProducts?.length === 0) && (
                    <Grid
                      container
                      sx={{
                        height: "320px",
                        justifyContent: "center",
                        textAlign: "center",
                        alignItems: "center",
                      }}
                    >
                      <Grid item sm={8} md={8} lg={8}>
                        <Typography
                          sx={{
                            lineHeight: "28px",
                            fontWeight: 700,
                            fontSize: "20px",
                            color: "#1b2430",
                            marginTop: "30px",
                            marginBottom: "15px",
                          }}
                        >
                          {t("No_Menu_found")}
                        </Typography>
                      </Grid>
                    </Grid>
                  )}
            </Box>
          </Grid>
        </>
      )}
    </>
  );
};

export default LocationProductsSection;

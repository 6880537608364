import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  TextField,
  Card,
  Checkbox,
  Avatar,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import CommonCard from "./CommonCard";
import MultipleImagesCard from "./MultipleImagesCommonCard";
import CoverImagesCard from "./CoverImageCard/CoverImageCard";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import { Dialog } from "@material-ui/core";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import { useNavigate } from "react-router-dom";
import { useStyles } from "../../../../Styles/style";
import Loader from "../../../../../../../components/Loaders/Loader";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import ConfirmModalTwo from "../../../../../../../components/Models/ConfirmModalLodging";

import { LazyLoadImage } from "react-lazy-load-image-component";
import LogoImageModal from "./SingleImageModal/CoverImageModal";
import SquareImageModal from "./SquareModal/SquareImageModal";
import CoverImageModal from "./CoverImageCard/CoverImageModal";
import TeamImageModal from "./TeamImageCard/TeamImageModal";
import PreviewImageModal from "./PreviewImageModal/PreviewImageModal";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
// import noImageGray from "../../../../../../assets/images/noImageGray.png";
import noImageGray from "../../../../../../../assets/images/noImageGray.png";
import addImage from "../../../../../../../assets/images/download.png";
import { styled } from "@mui/system";
import AdditionalPhotoModal from "./AdditionalPhotos/AdditionalPhotosModal";
import ExteriorImageModal from "./ExteriorImages/ExteriorImagesModal";
import InteriorImageModal from "./InteriorImages/InteriorImageModal";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import FoodImageModal from "../../../../ListingLocationDetails/Components/Images/Modals/Food&DrinkImages/FoodImageModal";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));

const CoverImages = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  onCancel,
  handleCloseFilterModel,
  location,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [isLoading, setIsLoading] = useState(false);
  const [openLogoImagesModal, setOpenLogoImagesModal] = useState(false);
  const [openSquareImagesModal, setOpenSquareImagesModal] = useState(false);
  const [openCoverImagesModal, setOpenCoverImagesModal] = useState(false);
  const [openTeamImageModal, setOpenTeamImageModal] = useState(false);
  const [logoImages, setLogoImages] = useState([]);
  const [selectedLogoImages, setSelectedLogoImages] = useState([]);
  const [squareLogoImages, setSquareLogoImages] = useState([]);
  const [selectedSquareLogoImages, setSelectedSquareLogoImages] = useState([]);
  const [coverPhotoImages, setCoverPhotoImages] = useState([]);
  const [selectedCoverPhotoImages, setSelectedCoverPhotoImages] = useState([]);
  const [teamPhotoImages, setTeamPhotoImages] = useState([]);
  const [additionalPhotoImages, setAdditionalPhotoImages] = useState([]);
  const [openAddtionalModal, setAdditionalModal] = useState(false);
  const [interiorPhotoImages, setInteriorPhotoImages] = useState([]);
  const [exteriorPhotoImages, setExteriorPhotoImages] = useState([]);
  const [exteriorImageModal, setExteriorImageModal] = useState(false);
  const [interiorImageModal, setInteriorImageModal] = useState(false);
  const [selectedTeamPhotoImages, setSelectedTeamPhotoImages] = useState([]);
  const [selectedAdditionalImages, setSelectedAdditionalImages] = useState([]);
  const [selectedInteriorImages, setSelectedInteriorImages] = useState([]);
  const [selectedExteriorImages, setSelectedExteriorImages] = useState([]);
  const [page, setPage] = useState(0);
  const [category, setCategory] = useState("");
  const [finalUploadImages, setFinalUploadImages] = useState([]);
  const [finalDeleteImages, setFinalDeleteImages] = useState([]);
  const [cancelState, setCancelState] = useState(false);
  const [openPreviewImageModal, setOpenPreviewImageModal] = useState(false);
  const [value, setValue] = useState(0);
  const [previewImages, setPreviewImages] = useState([]);
  const [displayButtonGrid, setDisplayButtonGrid] = useState(false);
  const [indexValueArr, setIndexValueArr] = useState(0);
  const classes = useStyles();
  const [title, setTitle] = useState("");
  const [apiCategory, setApiCategory] = useState("");
  const [mediaCount, setMediaCount] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [otherValue, setOtherValue] = useState(null);
  const [previewLoading, setPreviewLoading] = useState(false);

  const [foodPhotoImages, setFoodPhotoImages] = useState([]);
  const [selectedFoodImages, setSelectedFoodImages] = useState([]);
  const [foodImageModal, setFoodImageModal] = useState(false);

  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      //   getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };
  const handleOpenLogoModal = (images) => {
    setSelectedLogoImages(images);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setOpenLogoImagesModal(true);
    setCategory("LOGO");
  };
  const handleOpenSquareLogoModal = (images) => {
    setSelectedSquareLogoImages(images);
    setOpenLogoImagesModal(false);
    setAdditionalModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setOpenSquareImagesModal(true);
    setCategory("SQUARED_LOGO");
  };
  const handleOpenCoverModal = (images) => {
    setSelectedCoverPhotoImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setOpenCoverImagesModal(true);

    setCategory("COVER");
  };
  const handleOpenTeamModal = (images) => {
    setSelectedTeamPhotoImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setAdditionalModal(false);
    setOpenTeamImageModal(true);
    setCategory("TEAMS");
  };

  const handleOpenAdditionalModal = (images) => {
    setSelectedAdditionalImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(true);
    setCategory("ADDITIONAL");
  };
  const handleOpenExteriorModal = (images) => {
    setSelectedExteriorImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setInteriorImageModal(false);
    setExteriorImageModal(true);

    setCategory("EXTERIOR");
  };
  const handleOpenInteriorModal = (images) => {
    setSelectedInteriorImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(true);

    setCategory("INTERIOR");
  };

  const handleCloseLogoPictureModal = () => {
    document.getElementById("file-containerLogo").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseSquarePictureModal = () => {
    document.getElementById("file-container1").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseCoverPictureModal = () => {
    document.getElementById("file-container2").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseTeamPictureModal = () => {
    document.getElementById("file-container3").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseAdditonalPictureModal = () => {
    document.getElementById("file-container4").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseExteriorPictureModal = () => {
    document.getElementById("file-container5").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleCloseInteriorPictureModal = () => {
    document.getElementById("file-container6").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
  };
  const handleImagesShow = (images) => {
    if (category === "LOGO") {
      let arr = [];
      let logoObj = {
        sourceUrl: images[0],
        category: "LOGO",
        alreadyUploadedPhoto: false,
      };

      finalUploadImages.push(logoObj);
      arr.push(logoObj);
      setLogoImages(arr);
    }
    if (category === "SQUARED_LOGO") {
      let arr3 = [];
      let logoObj = {
        sourceUrl: images[0],
        category: "SQUARED_LOGO",
        alreadyUploadedPhoto: false,
      };

      finalUploadImages.push(logoObj);
      arr3.push(logoObj);
      setSquareLogoImages(arr3);
    }
    if (category === "COVER") {
      let arr2 = [];
      let coverObj = {
        sourceUrl: images[0],
        category: "COVER",
        alreadyUploadedPhoto: false,
      };
      finalUploadImages.push(coverObj);
      arr2.push(coverObj);
      setCoverPhotoImages(arr2);
    }
    if (category === "TEAMS") {
      let arr4 = [...teamPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "TEAMS",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr4.push(teamObj1);
        setMediaCount(arr4?.length);
        setTeamPhotoImages(arr4);
      });
    }
    if (category === "ADDITIONAL") {
      let arr5 = [...additionalPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "ADDITIONAL",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr5.push(teamObj1);
        setMediaCount(arr5?.length);
        setAdditionalPhotoImages(arr5);
      });
    }
    if (category === "EXTERIOR") {
      let arr6 = [...exteriorPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "EXTERIOR",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr6.push(teamObj1);
        setMediaCount(arr6?.length);
        setExteriorPhotoImages(arr6);
      });
    }
    if (category === "INTERIOR") {
      let arr7 = [...interiorPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "INTERIOR",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr7.push(teamObj1);
        setMediaCount(arr7?.length);
        setInteriorPhotoImages(arr7);
      });
    }
    if (category === "FOOD_AND_DRINK") {
      let arr8 = [...foodPhotoImages];
      images?.map((item) => {
        let teamObj1 = {
          sourceUrl: item,
          category: "FOOD_AND_DRINK",
          alreadyUploadedPhoto: false,
        };
        finalUploadImages.push(teamObj1);
        arr8.push(teamObj1);
        setMediaCount(arr8?.length);
        setFoodPhotoImages(arr8);
      });
    }
    setFinalUploadImages([...finalUploadImages]);
  };

  const handleDeleteImages = (item, index) => {
    let deleteItemsArr = [...finalDeleteImages];
    const ind = finalUploadImages.findIndex(
      (data) => data?.sourceUrl === item?.sourceUrl
    );
    const filteredItems = finalUploadImages?.filter((data, Ind) => {
      return Ind !== ind;
    });

    setFinalUploadImages(filteredItems);
    if (item?.alreadyUploadedPhoto === true) {
      deleteItemsArr?.push(item?._id);
    }
    setFinalDeleteImages(deleteItemsArr);
    if (item?.category === "LOGO") {
      setLogoImages([]);
    }
    if (item?.category === "SQUARED_LOGO") {
      setSquareLogoImages([]);
    }
    if (item?.category === "COVER") {
      setCoverPhotoImages([]);
    }
    if (item?.category === "TEAMS") {
      const deleteImage = teamPhotoImages.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setTeamPhotoImages(deleteImage);
    }
    if (item?.category === "ADDITIONAL") {
      const deleteImage = additionalPhotoImages.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setAdditionalPhotoImages([...deleteImage]);
    }
    if (item?.category === "EXTERIOR") {
      const deleteImage = exteriorPhotoImages.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setExteriorPhotoImages(deleteImage);
    }
    if (item?.category === "INTERIOR") {
      const deleteImage = interiorPhotoImages?.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setInteriorPhotoImages(deleteImage);
    }
    if (item?.category === "FOOD_AND_DRINK") {
      const deleteImage = foodPhotoImages?.filter((item, indx) => {
        return indx !== index;
      });
      setMediaCount(deleteImage?.length);
      setFoodPhotoImages(deleteImage);
    }
    setTouchedValue(true);
  };
  const handleDeleteImagePreview = (item, index) => {
    const ind = previewImages.findIndex(
      (data) => data?.sourceUrl === item?.sourceUrl
    );
    const filteredItems = previewImages?.filter((data, Ind) => {
      return Ind !== ind;
    });
    setPreviewImages(filteredItems);
  };

  const handleUpdateCoverImages = async () => {
    if (finalUploadImages?.length > 0) {
      setPage(0);
      finalUploadImages?.map((item) => {
        delete item.alreadyUploadedPhoto;
      });
      let locationIds = [];
      if (selectedLocationsBulkUpdate?.length > 0) {
        selectedLocationsBulkUpdate?.map((item) => {
          locationIds?.push(item?.id);
        });
      }
      try {
        setIsLoading(true);
        setDisplayConfirmationBox(false);
        const res = await api.patch(`/locationListing/mediaInBulk`, {
          locationIds: locationIds,
          images: finalUploadImages,
        });

        if (res.status === 200) {
          setIsLoading(false);
          //  getLocationDetailsListing(location);
          setTouchedValue(false);
          if (finalDeleteImages?.length === 0) {
            toast.success("Updated Successfully");
          }
          if (indexValue !== null) {
            let selectedValue = indexValue;
            setSelectedTab(selectedValue);
          } else {
            // getLocationMedia(1);
            handleCloseBox();
          }
          if (cancelState === true) {
            onCancel();
            handleCloseFilterModel();
          }
          /*  setFinalUploadImages([]);
          setFinalDeleteImages([]);
          setTeamPhotoImages([]);
          setAdditionalPhotoImages([]);
          setInteriorPhotoImages([]);
          setCoverPhotoImages([]);
          setLogoImages([]);
          setExteriorPhotoImages([]);
          setSelectedSquareLogoImages([]);
          setSelectedLogoImages([]); */

          // onCancel();
          // getLocationDetails(location);

          // setLocationDetailsData(res?.data?.data);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message);
        setIsLoading(false);
      }
    }
  };

  const logoValidations = {
    ratio: "1:1",
    minSize: 1000,
    maxSiz: 50000,
    square: false,
  };
  const squareLogoValidations = {
    ratio: "1:1",
    minSize: 1000,
    maxSiz: 50000,
    square: true,
  };
  const coverValidations = {
    ratio: null,
    minSize: 1000,
    maxSiz: 50000,
    square: false,
  };
  const teamValidations = {
    ratio: null,
    minSize: 1000,
    maxSiz: 50000,
    square: false,
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  const handleChange = (event, newValue) => {
    if (touchedValue === true) {
      setOpenConfirmBox(true);
      setOtherValue(newValue);
    } else {
      setValue(newValue);
      setOtherValue(null);
      setPage(0);
      setMediaCount(0);
      setHasMore(true);
      setFinalUploadImages([]);
      setFinalDeleteImages([]);
      setTeamPhotoImages([]);
      setAdditionalPhotoImages([]);
      setInteriorPhotoImages([]);
      setCoverPhotoImages([]);
      setLogoImages([]);
      setExteriorPhotoImages([]);
      setSelectedSquareLogoImages([]);
      setSelectedLogoImages([]);
      if (newValue === 1) {
        setApiCategory("TEAMS");
      } else if (newValue === 2) {
        setApiCategory("ADDITIONAL");
      } else if (newValue === 3) {
        setApiCategory("EXTERIOR");
      } else if (newValue === 4) {
        setApiCategory("INTERIOR");
      } else if (newValue === 5) {
        setApiCategory("FOOD_AND_DRINK");
      }
    }
  };

  const handleOpenPreviewImage = (images, displayGrid, value) => {
    setPreviewImages(images);
    setOpenPreviewImageModal(true);
    setDisplayButtonGrid(displayGrid);
    if (value !== undefined) {
      setIndexValueArr(value);
    } else {
      setIndexValueArr(null);
    }
  };

  const handleClosePreviewImage = () => {
    setOpenPreviewImageModal(false);
  };
  const handleCloseBox = () => {
    setOpenConfirmBox(false);
    if (otherValue !== null) {
      setValue(otherValue);
      setFinalUploadImages([]);
      setFinalDeleteImages([]);
      setTeamPhotoImages([]);
      setAdditionalPhotoImages([]);
      setInteriorPhotoImages([]);
      setCoverPhotoImages([]);
      setLogoImages([]);
      setExteriorPhotoImages([]);
      setSelectedSquareLogoImages([]);
      setSelectedLogoImages([]);
      setTouchedValue(false);
    }
  };

  const handleOpenFoodModal = (images) => {
    setSelectedFoodImages(images);
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setFoodImageModal(true);

    setCategory("FOOD_AND_DRINK");
  };

  const handleCloseFoodPictureModal = () => {
    document.getElementById("file-container7").value = "";
    setOpenLogoImagesModal(false);
    setOpenSquareImagesModal(false);
    setOpenCoverImagesModal(false);
    setOpenTeamImageModal(false);
    setAdditionalModal(false);
    setExteriorImageModal(false);
    setInteriorImageModal(false);
    setFoodImageModal(false);
    setSelectedLogoImages([]);
    setSelectedSquareLogoImages([]);
    setSelectedCoverPhotoImages([]);
    setSelectedInteriorImages([]);
    setSelectedExteriorImages([]);
    setSelectedTeamPhotoImages([]);
    setSelectedAdditionalImages([]);
    setSelectedFoodImages([]);
  };

  return (
    <>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCoverImages}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
            /*    getLocationDetails={getLocationDetails}
          location={location} */

            //  loading={setIsLoading}
          />
        </Dialog>
      )}
      {openConfirmBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={openConfirmBox}
          // onClose={handleCloseBox}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModalTwo
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onConfirm={handleUpdateCoverImages}
            onCancel={handleCloseBox}
            loading={isLoading}
            /*   location={location}
          loading={isLoading} */
          />
        </Dialog>
      )}
      <Grid
        container
        direction="column"
        className={classes.gridContainerTwoBulk}
      >
        <Grid container sx={{ padding: "18px" }}>
          <Grid
            item
            xs={10}
            sm={10}
            md={11.5}
            lg={11.5}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Typography className={classes.modalHeader}>
              {t("cover_images_uberAll")}
            </Typography>
            <BootstrapTooltip title={t("cover_images_uberAllSub")}>
              <Typography
                sx={{
                  marginLeft: "5px",
                  marginTop: "4px",
                  cursor: "pointer",
                }}
              >
                <InfoOutlinedIcon />
              </Typography>
            </BootstrapTooltip>
          </Grid>
          <Grid item xs={2} sm={2} md={0.5} lg={0.5}>
            <IconButton
              onClick={() => {
                handleOpen();
                setCancelState(true);
              }}
              className="delete_button"
            >
              <CloseIcon />
            </IconButton>
          </Grid>
        </Grid>
        <Divider />
        <Box sx={{ width: "100%", overflow: "auto", height: "480px" }}>
          <Box>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label={t("Logos_Cover_Images")} {...a11yProps(0)} />
              <Tab label={t("listing_images_mainphoto")} {...a11yProps(1)} />
              <Tab label={t("Additional_photos")} {...a11yProps(2)} />
              <Tab label={t("exterior")} {...a11yProps(3)} />
              <Tab label={t("interior")} {...a11yProps(4)} />
              <Tab label={t("Food_&_Drinks")} {...a11yProps(5)} />
            </Tabs>
          </Box>

          <CustomTabPanel value={value} index={0}>
            {isLoading ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  height: "430px",
                  // marginTop: "150px",
                }}
              >
                <Loader />
              </Grid>
            ) : (
              <>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    {/*************** Logo container **************/}
                    <CommonCard
                      title={t("listing_images_logo")}
                      tooltipText={t("logo_tooltip_text")}
                      imagesArray={logoImages}
                      handleOpenModal={handleOpenLogoModal}
                      handleDeleteImage={handleDeleteImages}
                      // locationId={location?.id}
                      handleClose={handleCloseLogoPictureModal}
                      open={openLogoImagesModal}
                      loading={isLoading}
                      handleImagesShow={handleImagesShow}
                      setTouchedValue={setTouchedValue}
                      category={category}
                      selectedImages={selectedLogoImages}
                      setSelectedImages={setSelectedLogoImages}
                      validation={logoValidations}
                      identifier="containerLogo"
                      handleOpenPreviewImage={handleOpenPreviewImage}
                      setTitle={setTitle}
                    />
                  </Grid>
                  {/*************** Square logo container **************/}
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <CommonCard
                      title={t("squareLogo")}
                      tooltipText={t("squareLogo_tooltip")}
                      imagesArray={squareLogoImages}
                      handleOpenModal={handleOpenSquareLogoModal}
                      handleDeleteImage={handleDeleteImages}
                      //  locationId={location?.id}
                      handleClose={handleCloseSquarePictureModal}
                      open={openSquareImagesModal}
                      loading={isLoading}
                      handleImagesShow={handleImagesShow}
                      setTouchedValue={setTouchedValue}
                      category={category}
                      selectedImages={selectedSquareLogoImages}
                      setSelectedImages={setSelectedSquareLogoImages}
                      validation={squareLogoValidations}
                      identifier="container1"
                      setTitle={setTitle}
                      handleOpenPreviewImage={handleOpenPreviewImage}
                    />
                  </Grid>
                  <Divider variant="middle" />
                  {/*************** Cover images container **************/}
                  <Grid item xs={12} sm={12} md={12} lg={12}>
                    <CoverImagesCard
                      title={t("listing_images_coverPhoto")}
                      tooltipText={t("coverImage_tooltipText")}
                      imagesArray={coverPhotoImages}
                      handleOpenModal={handleOpenCoverModal}
                      handleDeleteImage={handleDeleteImages}
                      alignment={true}
                      //  locationId={location?.id}
                      handleClose={handleCloseCoverPictureModal}
                      open={openCoverImagesModal}
                      loading={isLoading}
                      handleImagesShow={handleImagesShow}
                      setTouchedValue={setTouchedValue}
                      category={category}
                      selectedImages={selectedCoverPhotoImages}
                      setSelectedImages={setSelectedCoverPhotoImages}
                      identifier="container2"
                      setTitle={setTitle}
                      handleOpenPreviewImage={handleOpenPreviewImage}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </CustomTabPanel>

          {value === 1 &&
            (isLoading ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  height: "430px",
                  // marginTop: "150px",
                }}
              >
                <Loader />
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MultipleImagesCard
                    title={t("listing_images_mainphoto")}
                    tooltipText={t("coverImage_tooltipText")}
                    imagesArray={teamPhotoImages}
                    handleOpenModal={handleOpenTeamModal}
                    handleDeleteImage={handleDeleteImages}
                    alignment={true}
                    // locationId={location?.id}
                    handleClose={handleCloseTeamPictureModal}
                    open={openTeamImageModal}
                    loading={isLoading}
                    handleImagesShow={handleImagesShow}
                    setTouchedValue={setTouchedValue}
                    category={category}
                    identifier="container3"
                    //  fetchMoreData={fetchMoreTeamsData}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    handleOpenPreviewImage={handleOpenPreviewImage}
                    setTitle={setTitle}
                    mediaCount={mediaCount}
                    finalDeleteImages={finalDeleteImages}
                  />
                </Grid>
              </Grid>
            ))}

          {value === 2 &&
            (isLoading ? (
              <Grid
                container
                sx={{
                  justifyContent: "center",
                  textAlign: "center",
                  alignItems: "center",
                  height: "430px",
                  // marginTop: "150px",
                }}
              >
                <Loader />
              </Grid>
            ) : (
              <Grid container>
                <Grid item xs={12} sm={12} md={12} lg={12}>
                  <MultipleImagesCard
                    title={t("Additional_photos")}
                    tooltipText={t("Additional_photos_tooltipText")}
                    imagesArray={additionalPhotoImages}
                    handleOpenModal={handleOpenAdditionalModal}
                    handleDeleteImage={handleDeleteImages}
                    alignment={true}
                    //   locationId={location?.id}
                    handleClose={handleCloseAdditonalPictureModal}
                    open={openTeamImageModal}
                    loading={isLoading}
                    handleImagesShow={handleImagesShow}
                    setTouchedValue={setTouchedValue}
                    category={category}
                    identifier="container4"
                    // fetchMoreData={fetchMoreAdditionalData}
                    hasMore={hasMore}
                    setHasMore={setHasMore}
                    handleOpenPreviewImage={handleOpenPreviewImage}
                    setTitle={setTitle}
                    mediaCount={mediaCount}
                    finalDeleteImages={finalDeleteImages}
                  />
                </Grid>
              </Grid>
            ))}

          {value === 3 && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MultipleImagesCard
                  title={t("exterior")}
                  tooltipText={t("exterior_tooltipText")}
                  imagesArray={exteriorPhotoImages}
                  handleOpenModal={handleOpenExteriorModal}
                  handleDeleteImage={handleDeleteImages}
                  alignment={true}
                  // locationId={location?.id}
                  handleClose={handleCloseExteriorPictureModal}
                  open={openTeamImageModal}
                  loading={isLoading}
                  handleImagesShow={handleImagesShow}
                  setTouchedValue={setTouchedValue}
                  category={category}
                  identifier="container5"
                  //fetchMoreData={fetchMoreExteriorData}
                  hasMore={hasMore}
                  setHasMore={setHasMore}
                  handleOpenPreviewImage={handleOpenPreviewImage}
                  setTitle={setTitle}
                  mediaCount={mediaCount}
                  finalDeleteImages={finalDeleteImages}
                />
              </Grid>
            </Grid>
          )}

          {value === 4 && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MultipleImagesCard
                  title={t("interior")}
                  tooltipText={t("interior_tooltipText")}
                  imagesArray={interiorPhotoImages}
                  handleOpenModal={handleOpenInteriorModal}
                  handleDeleteImage={handleDeleteImages}
                  alignment={true}
                  //    locationId={location?.id}
                  handleClose={handleCloseInteriorPictureModal}
                  open={openTeamImageModal}
                  loading={isLoading}
                  handleImagesShow={handleImagesShow}
                  setTouchedValue={setTouchedValue}
                  category={category}
                  identifier="container6"
                  //  fetchMoreData={fetchMoreInteriorData}
                  hasMore={hasMore}
                  setHasMore={setHasMore}
                  handleOpenPreviewImage={handleOpenPreviewImage}
                  setTitle={setTitle}
                  mediaCount={mediaCount}
                  finalDeleteImages={finalDeleteImages}
                />
              </Grid>
            </Grid>
          )}

          {value === 5 && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <MultipleImagesCard
                  title={t("Food_&_Drinks")}
                  tooltipText={t("interior_tooltipText")}
                  imagesArray={foodPhotoImages}
                  handleOpenModal={handleOpenFoodModal}
                  handleDeleteImage={handleDeleteImages}
                  alignment={true}
                  locationId={location?.id}
                  handleClose={handleCloseFoodPictureModal}
                  open={foodImageModal}
                  loading={isLoading}
                  handleImagesShow={handleImagesShow}
                  setTouchedValue={setTouchedValue}
                  category={category}
                  identifier="container7"
                  // fetchMoreData={fetchMoreFoodrData}
                  fetchMoreData={() => {}}
                  hasMore={hasMore}
                  setHasMore={setHasMore}
                  handleOpenPreviewImage={handleOpenPreviewImage}
                  setTitle={setTitle}
                  mediaCount={mediaCount}
                  finalDeleteImages={finalDeleteImages}
                />
              </Grid>
            </Grid>
          )}
        </Box>
        <Divider />

        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={12}
          className={classes.gridButtons}
        >
          <CommonButton
            displayWhite="true"
            label={t("Back")}
            onSubmit={handleCancelButton}
          />
          <CommonButton
            disabled={isLoading}
            onSubmit={handleUpdateCoverImages}
            label={t("Save")}
          />
        </Grid>
      </Grid>
      {openLogoImagesModal && (
        <LogoImageModal
          title={t("listing_images_logo")}
          //  locationId={location.id}
          handleClose={handleCloseLogoPictureModal}
          open={openLogoImagesModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={logoValidations}
          selectedImages={selectedLogoImages}
          setSelectedImages={setSelectedLogoImages}
          category={category}
        />
      )}

      {openSquareImagesModal && (
        <SquareImageModal
          title={t("squareLogo")}
          //   locationId={location.id}
          handleClose={handleCloseSquarePictureModal}
          open={openSquareImagesModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={squareLogoValidations}
          selectedImages={selectedSquareLogoImages}
          setSelectedImages={setSelectedSquareLogoImages}
          category={category}
        />
      )}
      {openCoverImagesModal && (
        <CoverImageModal
          //  locationId={location.id}
          handleClose={handleCloseCoverPictureModal}
          open={openCoverImagesModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={coverValidations}
          category={category}
          selectedImages={selectedCoverPhotoImages}
          setSelectedImages={setSelectedCoverPhotoImages}
        />
      )}
      {openTeamImageModal && (
        <TeamImageModal
          //  locationId={location.id}
          handleClose={handleCloseTeamPictureModal}
          open={openTeamImageModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedTeamPhotoImages}
          setSelectedImages={setSelectedTeamPhotoImages}
          tooltipText={t("coverImage_tooltipText")}
          title={t("listing_images_mainphoto")}
        />
      )}
      {openAddtionalModal && (
        <AdditionalPhotoModal
          //  locationId={location.id}
          title={t("Additional_photos")}
          tooltipText={t("Additional_photos_tooltipText")}
          handleClose={handleCloseAdditonalPictureModal}
          open={openAddtionalModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedAdditionalImages}
          setSelectedImages={setSelectedAdditionalImages}
        />
      )}
      {exteriorImageModal && (
        <ExteriorImageModal
          title={t("exterior")}
          //   locationId={location.id}
          tooltipText={t("exterior_tooltipText")}
          handleClose={handleCloseExteriorPictureModal}
          open={exteriorImageModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedExteriorImages}
          setSelectedImages={setSelectedExteriorImages}
        />
      )}
      {interiorImageModal && (
        <InteriorImageModal
          title={t("interior")}
          //  locationId={location.id}
          tooltipText={t("interior_tooltipText")}
          handleClose={handleCloseInteriorPictureModal}
          open={interiorImageModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedInteriorImages}
          setSelectedImages={setSelectedInteriorImages}
        />
      )}

      {openPreviewImageModal && (
        <PreviewImageModal
          handleClickOpen={handleOpenPreviewImage}
          open={openPreviewImageModal}
          handleClose={handleClosePreviewImage}
          previewImages={previewImages}
          handleDeleteImage={handleDeleteImages}
          title={title}
          displayButtonGrid={displayButtonGrid}
          indexOfItem={indexValueArr}
          handleDeleteImagePreview={handleDeleteImagePreview}
          mediaCount={mediaCount}
          previewLoading={previewLoading}
          /*   fetchMoreData={
          value === 0
            ? null
            : value === 1
            ? fetchMoreTeamsData
            : value === 2
            ? fetchMoreAdditionalData
            : value === 3
            ? fetchMoreExteriorData
            : fetchMoreInteriorData
        } */
        />
      )}

      {foodImageModal && (
        <FoodImageModal
          title={t("Food_&_Drinks")}
          locationId={location.id}
          tooltipText={t("interior_tooltipText")}
          handleClose={handleCloseFoodPictureModal}
          open={foodImageModal}
          loading={isLoading}
          handleImagesShow={handleImagesShow}
          setTouchedValue={setTouchedValue}
          validation={teamValidations}
          category={category}
          selectedImages={selectedFoodImages}
          setSelectedImages={setSelectedFoodImages}
        />
      )}
    </>
  );
};

export default CoverImages;

import React, { useEffect, useState, useRef } from "react";
import {
  Grid,
  IconButton,
  Typography,
  Divider as MuiDivider,
  Box,
  Tooltip,
  tooltipClasses,
} from "@mui/material";
import Collapsible from "../../../../../../../components/CustomComponents/Collapsible.js";
import Loader from "../../../../../../../components/Loaders/Loader";
import CommonButton from "../../../../../../../components/CustomComponents/Buttons/CommonButton";
import CloseIcon from "@mui/icons-material/Close";
import Divider from "@mui/material/Divider";
import { useTranslation } from "react-i18next";
import { api } from "../../../../../../../contexts/JWTContext";
import { toast } from "react-toastify";
import { useStyles } from "../../../../Styles/style";
import { Dialog } from "@material-ui/core";
import LodgingAttributes from "./lodging/index";
import LocationAttributes from "./attributes/index";
import ConfirmModal from "../../../../../../../components/Models/ConfirmationModal";
import dayjs from "dayjs";
import { styled } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

const BootstrapTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} placement="top" />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.black,
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#1B2430",
    borderRadius: "8px",
  },
}));
const AttributesModal = ({
  touchedValue,
  setTouchedValue,
  setDisplayConfirmationBox,
  displayConfirmationBox,
  indexValue,
  setSelectedTab,
  selectedTab,
  setIndexValue,
  setSelectedLocationsBulkUpdate,
  selectedLocationsBulkUpdate,
  handleCloseFilterModel,
  onCancel,
  processedLocations,
}) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [itemsArr, setItemsArr] = useState([]);
  const [allAttributes, setAllAttributes] = useState(null);
  const [allAttributesArr, setAllAttributesArr] = useState([]);
  const [filteredItemsArr, setFilteredItemsArr] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});
  const [hasEmpty, setHasEmpty] = useState(false);
  const [enumRequired, setEnumRequired] = useState(false);
  const [urlRequired, setUrlRequired] = useState(false);
  const [urlValidation, setUrlValidation] = useState(false);
  const [repeatedEnumRequired, setRepeatedEnumRequired] = useState(false);
  const [cancelState, setCancelState] = useState(false);
  const [removeState, setRemoveState] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [attributesWithCategories, setAttributesWithCategories] = useState({});
  const [categoriesData, setCategoriesData] = useState(null);
  const [attributesLoading, setAttributesLoading] = useState(false);
  const [selectedCategoryName, setSelectedCategoryName] = useState(null);
  const [displayChildCategories, setDisplayChildCategories] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [nextCategory, setNextCategory] = useState(null);
  const [integerError, setIntegerError] = useState(false);
  const [locationCategoriesData, setLocationCategoriesData] = useState(null);
  const [errors, setErrors] = useState([]);
  const [errorMessage, setErrorMessage] = useState([]);
  const [selectedLanguagesArr, setSelectedLanguagesArr] = useState([]);
  const [expandedPanels, setExpandedPanels] = useState([]);
  const gridContainerRef = useRef(null);

  let url = false;
  let enums = false;

  useEffect(() => {
    if (itemsArr?.length > 0) {
      if (itemsArr?.length === 1) {
        if (
          itemsArr[0]?.name === "" &&
          itemsArr[0]?.valueType === "" &&
          itemsArr[0]?.displayName === ""
        ) {
          setTouchedValue(false);
        }
      }
      if (removeState === true) {
        setDisabled(false);
      }

      setDisabled(false);
    } else if (itemsArr?.length === 0 && removeState === true) {
      setTouchedValue(true);
      setDisabled(false);
    } else if (itemsArr?.length === 0 && removeState === false) {
      setTouchedValue(false);
      setDisabled(false);
    }
  }, [itemsArr]);
  useEffect(() => {
    const controller = new AbortController();

    // Call the function with the controller
    getAllAttributes(controller);

    // Cleanup function to abort the API request on component unmount or dependency change
    return () => {
      controller.abort();
    };
  }, [indexValue]);

  useEffect(() => {
    getAllAttributes();
  }, []);

  useEffect(async () => {
    if (allAttributes !== null) {
      handleDisplayData();
    }
  }, [allAttributes]);

  useEffect(() => {
    const hasEmptyName = itemsArr.some((item) => item.displayName === "");
    setHasEmpty(hasEmptyName);
  }, [itemsArr]);

  const handleClose = () => {
    setDisplayConfirmationBox(false);
  };

  useEffect(() => {
    if (
      (errors.length > 0 || errorMessage.length > 0) &&
      gridContainerRef.current
    ) {
      gridContainerRef.current.scrollTop = 0;
    }
  }, [errors, errorMessage]);
  const handleOpen = () => {
    if (touchedValue === false) {
      // setSelectedTab(newValue);
      onCancel();
      handleCloseFilterModel();
      setDisplayConfirmationBox(false);
      // setIndexValue(null);
    } else {
      setDisplayConfirmationBox(true);
      //  setIndexValue(newValue);
    }
  };
  const handleCancelButton = () => {
    setCancelState(true);
    if (touchedValue === false) {
      setDisplayConfirmationBox(false);
      onCancel();
      //   getLocationDetails(location);
    } else {
      setDisplayConfirmationBox(true);
    }
  };
  const handleDisplayData = () => {
    if (allAttributes !== null && allAttributes?.attributes?.length > 0) {
      let tempArrrr = [];
      const tempArr = allAttributes?.attributes?.map((item) => {
        let tempObj = {
          name: item?.parent,
          valueType: item?.valueType,
          values: [
            item?.valueMetadata?.length > 0 && item?.valueMetadata[0]?.value,
          ],
          valueMetadata:
            item?.valueMetadata?.length > 0 && item?.valueMetadata
              ? item?.valueMetadata
              : [],
          displayName: item?.displayName,
          closed: false,
        };
        tempArrrr.push(tempObj);
        allAttributesArr.push(tempObj);
      });
      setAllAttributesArr([...allAttributesArr]);

      const results = tempArrrr.filter(
        ({ name: id1 }) => !itemsArr.some(({ name: id2 }) => id2 === id1)
      );

      setFilteredItemsArr(results);
    }
  };
  const getAllAttributes = async (controller) => {
    setIsLoading(true);

    try {
      const res = await api.get(
        `locationListing/bulkAttributes?locationId=${processedLocations}`,
        { signal: controller.signal }
      );

      if (res.status === 200) {
        setAllAttributes(res?.data?.data);
      }
      setIsLoading(false);
    } catch (error) {
      if (error.name === "AbortError") {
        console.log("API call was cancelled");
      } else {
        console.error("API call failed", error);
        setIsLoading(false);
      }
    }
  };

  const filterNonNullValues = (obj) => {
    if (Array.isArray(obj)) {
      // Process each item in the array recursively
      const filteredArray = obj
        .map((item) => filterNonNullValues(item))
        .filter(
          (item) =>
            item !== null &&
            !(
              typeof item === "object" &&
              item !== null &&
              Object.keys(item).length === 0
            )
        );

      // Return null if the array is empty after filtering
      return filteredArray.length === 0 ? null : filteredArray;
    } else if (typeof obj === "object" && obj !== null) {
      // Process each key-value pair in the object
      const filteredObject = Object.entries(obj).reduce((acc, [key, value]) => {
        const filteredValue = filterNonNullValues(value);
        if (filteredValue !== null) {
          acc[key] = filteredValue;
        }
        return acc;
      }, {});

      // Return null if the object is empty after filtering
      return Object.keys(filteredObject).length === 0 ? null : filteredObject;
    }

    // Return the value if it's neither an array nor an object
    return obj;
  };

  const handleLodgingDataToUpdate = (dummyObj) => {
    Object.entries(dummyObj).map(([keyOne, valueOne]) => {
      delete valueOne.isEditable;
      if (valueOne?.hasOwnProperty("type")) {
        if (dummyObj[`${keyOne}`]?.value !== null) {
          dummyObj[`${keyOne}`] = dummyObj[`${keyOne}`]?.value;
        } else {
          delete dummyObj[`${keyOne}`];
        }
      } else {
        Object.entries(valueOne).map(([keyTwo, valueTwo]) => {
          if (
            valueTwo?.hasOwnProperty("type") ||
            valueTwo?.hasOwnProperty("hours")
          ) {
            if (valueTwo?.hasOwnProperty("hours")) {
              if (
                dummyObj[`${keyOne}`][`${keyTwo}`]?.hours?.value !== null &&
                dummyObj[`${keyOne}`][`${keyTwo}`]?.minutes?.value !== null
              ) {
                dummyObj[`${keyOne}`][`${keyTwo}`].hours = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.hours?.value
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].minutes = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.minutes?.value
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].nanos = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.nanos?.value !== null
                    ? dummyObj[`${keyOne}`][`${keyTwo}`]?.nanos?.value
                    : 0
                );
                dummyObj[`${keyOne}`][`${keyTwo}`].seconds = parseInt(
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.seconds?.value !== null
                    ? dummyObj[`${keyOne}`][`${keyTwo}`]?.seconds?.value
                    : 0
                );
              } else {
                delete dummyObj[`${keyOne}`][`${keyTwo}`];
              }
            } else {
              if (dummyObj[`${keyOne}`][`${keyTwo}`]?.value !== null) {
                let index = 0;
                dummyObj[`${keyOne}`][`${keyTwo}`] =
                  dummyObj[`${keyOne}`][`${keyTwo}`]?.value;
              } else {
                delete dummyObj[`${keyOne}`][`${keyTwo}`];
              }
            }
          } else {
            if (Array.isArray(valueTwo)) {
              if (keyTwo === "codes") {
                let index = 0;
                if (dummyObj[`${keyOne}`][`${keyTwo}`][index]?.value !== null) {
                  dummyObj[`${keyOne}`][`${keyTwo}`] =
                    dummyObj[`${keyOne}`][`${keyTwo}`][index]?.value;
                } else {
                  delete dummyObj[`${keyOne}`][`${keyTwo}`][index];
                }
              } else if (
                keyTwo === "languagesSpoken" &&
                dummyObj[`${keyOne}`][`${keyTwo}`].some(
                  (languageItem) => languageItem?.spoken?.value === null
                ) === false
              ) {
                valueTwo.map((item, index) => {
                  Object.entries(item)?.map(([keyArr, valueArr]) => {
                    dummyObj[`${keyOne}`][`${keyTwo}`][index][`${keyArr}`] =
                      dummyObj[`${keyOne}`][`${keyTwo}`][index]?.[
                        `${keyArr}`
                      ]?.value;
                  });
                });
              } else {
                valueTwo.map((item, index) => {
                  Object.entries(item)?.map(([keyArr, valueArr]) => {
                    if (item?.spoken?.value !== null) {
                      dummyObj[`${keyOne}`][`${keyTwo}`][index][`${keyArr}`] =
                        dummyObj[`${keyOne}`][`${keyTwo}`][index]?.[
                          `${keyArr}`
                        ]?.value;
                    } else {
                      delete dummyObj[`${keyOne}`][`${keyTwo}`][index];
                    }
                  });
                });
                const filteredArr = dummyObj[`${keyOne}`][`${keyTwo}`].filter(
                  (langItem) => langItem !== null
                );

                // delete dummyObj[`${keyOne}`][`${keyTwo}`];
                dummyObj[`${keyOne}`][`${keyTwo}`] = filteredArr;
              }
            } else {
              Object.entries(valueTwo).map(([keyThree, valueThree]) => {
                if (valueThree?.hasOwnProperty("type")) {
                  if (
                    dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`]?.value !==
                    null
                  ) {
                    dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`] =
                      dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`]?.value;
                  } else {
                    delete dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`];
                  }
                } else {
                  if (Array.isArray(valueThree)) {
                    let index = 0;
                    valueThree?.map((itemFour) => {
                      Object.entries(itemFour).map(([keyFour, valueFour]) => {
                        if (
                          dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                            index
                          ][`${keyFour}`]?.value !== null
                        ) {
                          dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                            index
                          ][`${keyFour}`] =
                            dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                              index
                            ][`${keyFour}`]?.value;
                        } else {
                          delete dummyObj[`${keyOne}`][`${keyTwo}`][
                            `${keyThree}`
                          ][index][`${keyFour}`];
                        }
                      });
                    });
                  } else {
                    valueThree &&
                      Object.entries(valueThree)?.map(
                        ([keyFour, valueFour]) => {
                          if (valueFour?.hasOwnProperty("type")) {
                            if (
                              dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                                `${keyFour}`
                              ]?.value !== null
                            ) {
                              dummyObj[`${keyOne}`][`${keyTwo}`][`${keyThree}`][
                                `${keyFour}`
                              ] =
                                dummyObj[`${keyOne}`][`${keyTwo}`][
                                  `${keyThree}`
                                ][`${keyFour}`]?.value;
                            } else {
                              delete dummyObj[`${keyOne}`][`${keyTwo}`][
                                `${keyThree}`
                              ][`${keyFour}`];
                            }
                          } else {
                            valueFour &&
                              Object.entries(valueFour)?.map(
                                ([keyFive, valueFive]) => {
                                  if (
                                    dummyObj[`${keyOne}`][`${keyTwo}`][
                                      `${keyThree}`
                                    ][`${keyFour}`]?.[`${keyFive}`]?.value !==
                                    null
                                  ) {
                                    dummyObj[`${keyOne}`][`${keyTwo}`][
                                      `${keyThree}`
                                    ][`${keyFour}`][`${keyFive}`] =
                                      dummyObj[`${keyOne}`][`${keyTwo}`][
                                        `${keyThree}`
                                      ][`${keyFour}`]?.[`${keyFive}`]?.value;
                                  } else {
                                    delete dummyObj[`${keyOne}`][`${keyTwo}`][
                                      `${keyThree}`
                                    ][`${keyFour}`]?.[`${keyFive}`];
                                  }
                                }
                              );
                          }
                        }
                      );
                  }
                }
              });
            }
          }
        });
      }
    });
  };
  const handleLodgingErrors = (dummyObj, errArray) => {
    Object.entries(dummyObj).map(([keyOne, valueOne]) => {
      delete valueOne.isEditable;
      if (valueOne?.hasOwnProperty("type")) {
        if (
          valueOne?.value !== null &&
          valueOne?.value !== false &&
          valueOne?.hasOwnProperty("depandant")
        ) {
          const approved = valueOne?.depandant?.some(
            (item) => item?.value === null
          );
        }
      } else {
        Object.entries(valueOne).map(([keyTwo, valueTwo]) => {
          if (
            valueTwo?.hasOwnProperty("type") ||
            valueTwo?.hasOwnProperty("hours")
          ) {
            let index = 0;
            if (
              valueTwo?.value !== null &&
              valueTwo?.value !== false &&
              valueTwo?.hasOwnProperty("depandant")
            ) {
              let arr = [];
              const filledValue = valueTwo?.depandant?.forEach((item) => {
                if (dummyObj[`${keyOne}`][`${item?.key}`]?.value === null) {
                  arr.push(item?.key);
                }
              });
              if (arr.length === valueTwo?.depandant?.length) {
                valueTwo?.depandant.map((item) => errArray.push(item?.key));
              }
            } else if (
              valueTwo?.value === null &&
              valueTwo?.hasOwnProperty("depandant")
            ) {
              let arr = [];
              const filledValue = valueTwo?.depandant?.forEach((item) => {
                if (dummyObj[`${keyOne}`][`${item?.key}`]?.value === null) {
                  arr.push(item?.key);
                }
              });
              if (arr.length !== valueTwo?.depandant?.length) {
                errArray.push(keyTwo);
              }
            }
          }
        });
      }
    });
  };
  const handleAttributesDataToUpdate = (dummyArr) => {
    dummyArr.forEach((item) => {
      delete item["error"];
      delete item["errorRequired"];
      delete item["enumRequired"];
      delete item["valueMetadata"];
      delete item[""];
      if (item?.valueType === "REPEATED_ENUM") {
        let arrSet = [];
        let arrUnset = [];
        item?.repeatedEnumValue?.setValues?.map((item) =>
          arrSet.push(item.value)
        );
        item?.repeatedEnumValue?.unsetValues?.map((item) =>
          arrUnset.push(item.value)
        );
        item.repeatedEnumValue.setValues = arrSet;
        item.repeatedEnumValue.unsetValues = arrUnset;

        // const unsetValues = item?.values;
        //  item["repeatedEnumValue"] = obj;
        //  delete item["values"];
        delete item["displayName"];
        delete item["closed"];
        delete item["showDelete"];
      } else {
        delete item["displayName"];
        delete item["closed"];
        delete item["showDelete"];
      }
    });
  };

  const handleUpdateAttributes = async () => {
    setIsLoading(true);
    let locationIds = [];
    if (selectedLocationsBulkUpdate?.length > 0) {
      selectedLocationsBulkUpdate.map((item) => {
        locationIds.push(item.id);
      });
    }

    let body = {
      locationId: locationIds,
    };
    if (
      allAttributes?.hasOwnProperty("lodging") &&
      allAttributes?.hasOwnProperty("attributes")
    ) {
      let dummyArr = [];
      if (itemsArr?.length > 0) {
        let objjj = JSON.stringify(itemsArr);
        const filteredArr = JSON.parse(objjj);
        let arrWithoutEmptyData = filteredArr.filter(
          (item) => item?.displayName !== ""
        );
        const errorArr = arrWithoutEmptyData?.filter(
          (item) => item?.valueType !== "BOOL"
        );
        if (itemsArr?.length > 0) {
          itemsArr?.forEach((item) => {
            if (
              item?.valueType === "URL" &&
              item?.uriValues?.length > 0 &&
              item?.uriValues[0]?.uri === ""
            ) {
              item["errorRequired"] = true;
              setUrlRequired(true);
              url = true;
            } else if (
              (item?.valueType === "ENUM" && item?.values === undefined) ||
              (item?.valueType === "ENUM" &&
                item?.values?.length > 0 &&
                item?.values[0] === false)
            ) {
              setEnumRequired(true);
              item["enumRequired"] = true;
              enums = true;
            }
          });
        }
        dummyArr = [...arrWithoutEmptyData];
        if (url === false && enums === false) {
          handleAttributesDataToUpdate(dummyArr);
        } else {
          setDisplayConfirmationBox(false);
          setIsLoading(false);
          setIndexValue(null);
          return;
        }
      }

      let errArray = [];

      let dummyObj = {};
      if (categoriesData !== null && Object.keys(categoriesData).length > 0) {
        let objjjj = JSON.stringify(categoriesData);

        dummyObj = JSON.parse(objjjj);
        handleLodgingErrors(dummyObj, errArray);
        const uniqueErrors = [...new Set(errArray)];

        setErrors(uniqueErrors);
        if (errArray?.length > 0) {
          setDisplayChildCategories(true);
          setIsLoading(false);
          return;
        }
        handleLodgingDataToUpdate(dummyObj);
      }

      if (dummyArr?.length > 0) {
        body.attributes = dummyArr;
      }
      if (Object.keys(dummyObj)?.length > 0) {
        body.lodging = dummyObj;
      }
    } else if (
      allAttributes?.hasOwnProperty("lodging") &&
      allAttributes?.hasOwnProperty("attributes") === false
    ) {
      let errArray = [];

      let lodgingObj = JSON.stringify(categoriesData);
      const dummyObj = JSON.parse(lodgingObj);
      handleLodgingErrors(dummyObj, errArray);
      const uniqueErrors = [...new Set(errArray)];
      setErrors(uniqueErrors);
      setDisplayChildCategories(true);
      if (errArray?.length > 0) {
        setDisplayChildCategories(true);
        setIsLoading(false);
        return;
      }
      handleLodgingDataToUpdate(dummyObj);
      body.lodging = dummyObj;
    } else {
      let objjj = JSON.stringify(itemsArr);
      const filteredArr = JSON.parse(objjj);
      let arrWithoutEmptyData = filteredArr.filter(
        (item) => item?.displayName !== ""
      );
      const errorArr = arrWithoutEmptyData?.filter(
        (item) => item?.valueType !== "BOOL"
      );
      if (itemsArr?.length > 0) {
        itemsArr?.forEach((item) => {
          if (
            item?.valueType === "URL" &&
            item?.uriValues?.length > 0 &&
            item?.uriValues[0]?.uri === ""
          ) {
            item["errorRequired"] = true;
            setUrlRequired(true);
            url = true;
          } else if (
            (item?.valueType === "ENUM" && item?.values === undefined) ||
            (item?.valueType === "ENUM" &&
              item?.values?.length > 0 &&
              item?.values[0] === false)
          ) {
            setEnumRequired(true);
            item["enumRequired"] = true;
            enums = true;
          }
        });
      }
      const dummyArr = [...arrWithoutEmptyData];
      if (url === false && enums === false) {
        handleAttributesDataToUpdate(dummyArr);
      } else {
        setDisplayConfirmationBox(false);
        setIndexValue(null);
      }
      body.attributes = dummyArr;
    }

    try {
      setDisplayConfirmationBox(false);
      const res = await api.patch(`/locationListing/bulkAttributes`, body);
      if (indexValue !== null) {
        let selectedValue = indexValue;

        setSelectedTab(selectedValue);
      }
      if (cancelState === true) {
        onCancel();
        handleCloseFilterModel();
      }
      setTouchedValue(false);
      setAttributesLoading(false);
      setIsLoading(false);
      toast.success("Updated Successfully");
      //  setCategoriesData(null);
      //  setItemsArr([]);
      //  handleDisplayData();
    } catch (error) {
      toast.error(error?.response?.data?.message);
      setIsLoading(false);
    }
  };

  const handleChangeAttributeValue = (value, index, item, resultValue) => {
    setTouchedValue(true);
    let tempArr = [...itemsArr];

    if (value !== null) {
      if (value?.valueType === "URL") {
        let objOne = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          uriValues: [
            {
              uri: "",
            },
          ],
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };
        tempArr[index] = objOne;
        itemsArr[index] = objOne;
      } else if (value?.valueType === "REPEATED_ENUM") {
        let objTwo = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          repeatedEnumValue: {
            setValues: [],
            unsetValues: [],
          },
          valueMetadata:
            value?.valueMetadata?.length > 0 ? value?.valueMetadata : [],
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };
        tempArr[index] = objTwo;
        itemsArr[index] = objTwo;
      } else {
        let tempObj = {
          closed: value?.closed,
          displayName: value?.displayName,
          name: value?.name,
          valueType: value?.valueType,
          //   values: [value?.values?.length > 0 && value?.values[0]],
          values: [
            value?.valueMetadata?.length > 0 && value?.valueMetadata[0]?.value,
          ],
          valueMetadata:
            value?.valueMetadata?.length > 0 ? value?.valueMetadata : [],
          showDelete: value?.showDelete ? value?.showDelete : item?.showDelete,
        };

        tempArr[index] = tempObj;
        itemsArr[index] = tempObj;
      }

      //  setSelectedItem(tempObj);
    }
    setItemsArr([...itemsArr]);

    const results = allAttributesArr.filter(
      ({ name: id1 }) => !tempArr.some(({ name: id2 }) => id2 === id1)
    );

    setFilteredItemsArr(results);
  };
  const handleChangeSwitch = (e, index) => {
    setTouchedValue(true);
    if (e.target.checked) {
      itemsArr[index].values[0] = true;
      // setOpen(true);
    } else {
      itemsArr[index].values[0] = false;
      // setOpen(false);
    }
    setItemsArr([...itemsArr]);
  };
  const handleRepeatedEnumSwitch = (e, data, index, ind) => {
    setTouchedValue(true);
    if (e.target.checked) {
      let arr = itemsArr[index].repeatedEnumValue?.setValues;
      arr.push(data);
      itemsArr[index].repeatedEnumValue.setValues = arr;
      const arrIndex = itemsArr[index].repeatedEnumValue?.unsetValues.findIndex(
        (item) => item?.value === data?.value
      );

      if (arrIndex > -1) {
        itemsArr[index].repeatedEnumValue?.unsetValues.splice(arrIndex, 1);
      }
    } else {
      let arr2 = itemsArr[index].repeatedEnumValue?.unsetValues;
      arr2.push(data);
      itemsArr[index].repeatedEnumValue.unsetValues = arr2;
      const arrIndexTwo = itemsArr[
        index
      ].repeatedEnumValue?.setValues.findIndex(
        (item) => item?.value === data?.value
      );
      if (arrIndexTwo > -1) {
        itemsArr[index].repeatedEnumValue?.setValues.splice(arrIndexTwo, 1);
      }
    }
    setItemsArr([...itemsArr]);
  };
  function validURLOther(str) {
    var pattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!pattern.test(str);
  }
  var protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/;

  var localhostDomainRE = /^localhost[\:?\d]*(?:[^\:?\d]\S*)?$/;
  var nonLocalhostDomainRE = /^[^\s\.]+\.\S{2,}$/;

  function validURL(string) {
    if (typeof string !== "string") {
      return false;
    }

    var match = string.match(protocolAndDomainRE);
    if (!match) {
      return false;
    }

    var everythingAfterProtocol = match[1];
    if (!everythingAfterProtocol) {
      return false;
    }

    if (
      localhostDomainRE.test(everythingAfterProtocol) ||
      nonLocalhostDomainRE.test(everythingAfterProtocol)
    ) {
      return true;
    }

    return false;
  }
  const handleChangeURL = (e, item, index) => {
    setTouchedValue(true);
    // itemsArr[index].values[0] = e.target.value;

    if (item?.uriValues?.length > 0) {
      itemsArr[index]["errorRequired"] = false;
      itemsArr[index].uriValues[0].uri = e.target.value;
      const occurrences = (e.target.value.match(/https?/g) || []).length;
      const wwwOccurrences = e.target.value.split("www").length - 1;
      const invalidCharacters = /[<>]/;
      const validity =
        validURL(e.target.value) || validURLOther(e.target.value);
      // const validityother =  validURLOther(e.target.value);
      if (invalidCharacters.test(e.target.value)) {
        itemsArr[index]["error"] = true;
      } else if (occurrences === 2) {
        itemsArr[index]["error"] = true;
      } else if (wwwOccurrences >= 2) {
        itemsArr[index]["error"] = true; // Valid URL with two or more "www" occurrences
      } else if (!validity) {
        itemsArr[index]["error"] = true;
      } else if (invalidCharacters.test(e.target.value)) {
        itemsArr[index]["error"] = true;
      } else {
        itemsArr[index]["error"] = false;
      }
    } else {
      itemsArr[index]["errorRequired"] = true;
    }
    setItemsArr([...itemsArr]);
  };
  const handleChangeEnum = (e, item, index) => {
    setTouchedValue(true);
    if (e === null) {
      itemsArr[index]["enumRequired"] = true;
    } else {
      itemsArr[index].values[0] = e;
      itemsArr[index]["enumRequired"] = false;
    }

    setItemsArr([...itemsArr]);
  };
  const handleChangeRepeatedEnum = (value, item, index) => {
    setTouchedValue(true);
    itemsArr[index].values = value;

    setItemsArr([...itemsArr]);
  };
  const handleAddNewData = () => {
    // setTouchedValue(true);
    let tempObjTwo = {
      name: "",
      valueType: "",
      values: [false],
      displayName: "",
      showDelete: true,
    };

    itemsArr.push(tempObjTwo);
    setItemsArr([...itemsArr]);
  };
  const removeAttribute = (index) => {
    setTouchedValue(true);
    setRemoveState(true);
    // setItemsArr(itemsArr.filter((el, i) => i !== index));

    let tempArr = itemsArr.filter((el, i) => i !== index);
    //  tempArr.filter((el, i) => i !== index);
    const results = allAttributesArr.filter(
      ({ name: id1 }) => !tempArr.some(({ name: id2 }) => id2 === id1)
    );
    setItemsArr(tempArr);
    setFilteredItemsArr(results);
    setUrlRequired(false);
    setEnumRequired(false);
    setUrlValidation(false);
    url = false;
    enums = false;
  };

  //*********************/ Lodging Functions

  const addMultiAttribute = (
    parent,
    subParent,
    grandParent,
    name,
    type,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    let tempObjTwo = { ...locationCategoriesData };
    if (subChild !== undefined) {
      if (type === "boolean") {
        tempObj[subParent][subChild][grandParent][parent][name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][subChild][grandParent][parent][name].value = 0;
      } else {
        tempObj[subParent][subChild][grandParent][parent][name].value = "";
      }
    } else if (subChild === undefined && grandParent !== undefined) {
      if (type === "boolean") {
        if (name === "spoken" || name === "awarded") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = true;
        } else {
          tempObj[subParent][parent][grandParent][name].value = true;
        }
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][parent][grandParent][name].value = 0;
      } else {
        if (name === "ecoCertificate") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = "";
        } else {
          tempObj[subParent][parent][grandParent][name].value = "";
        }
        // tempObj[subParent][parent][grandParent][name].value = "";
      }
    } else if (
      grandParent === undefined &&
      subParent === undefined &&
      parent === undefined &&
      subChild === undefined
    ) {
      if (type === "boolean") {
        tempObj[name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[name].value = 0;
      } else {
        tempObj[name].value = "";
      }
    } else if (subParent !== undefined) {
      if (type === "boolean") {
        if (name === "spoken") {
          let index = 0;
          tempObj[subParent][parent][index][name].value = true;
        } else if (name === "awarded") {
          let index = 0;
          tempObj[subParent][parent][grandParent][index][name].value = true;
        } else {
          tempObj[subParent][parent][name].value = true;
        }
      } else if (type === "integer" || type === "number") {
        tempObj[subParent][parent][name].value = 0;
      } else {
        if (name === "languageCode") {
          let index = 0;
          tempObj[subParent][parent][index][name].value = "";
        } else {
          tempObj[subParent][parent][name].value = "";
        }
      }
    } else {
      if (type === "boolean") {
        tempObj[parent][name].value = true;
      } else if (type === "integer" || type === "number") {
        tempObj[parent][name].value = 0;
      } else {
        tempObj[parent][name].value = "";
      }
    }
    setCategoriesData(tempObj);
  };

  const removeMultiAttribute = (
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = null;
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "languageCode") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = null;
      } else if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = null;
      } else {
        tempObj[subParent][parent][name].value = null;
      }
    } else if (subChild === undefined && grandParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "awarded") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "ecoCertificate") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = null;
      } else if (name === "languageCode") {
        let index = 0;

        tempObj[subParent][parent][index][name].value = "";
      } else {
        tempObj[subParent][parent][grandParent][name].value = null;
      }
    } else if (
      subChild === undefined &&
      subParent === undefined &&
      parent === undefined
    ) {
      tempObj[name].value = null;
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = null;
    } else {
      tempObj[parent][name].value = null;
    }
    setCategoriesData(tempObj);
  };

  const handleChangeChecked = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (
      subParent === undefined &&
      parent === undefined &&
      grandParent === undefined &&
      subChild === undefined
    ) {
      tempObj[name].value = e;
    } else if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        e.target.checked;
    } else if (subChild === undefined && grandParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value =
          e.target.checked;
      } else if (name === "awarded") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value =
          e.target.checked;
      } else {
        tempObj[subParent][parent][grandParent][name].value = e.target.checked;
      }
    } else if (
      subChild === undefined &&
      subParent === undefined &&
      parent === undefined
    ) {
      tempObj[name].value = e.target.checked;
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "spoken") {
        let index = 0;
        tempObj[subParent][parent][index][name].value = e.target.checked;
      } else {
        tempObj[subParent][parent][name].value = e.target.checked;
      }
    } else {
      tempObj[parent][name].value = e.target.checked;
    }

    setCategoriesData(tempObj);
  };

  const handleChangeStringValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = e;
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = e;
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = e;
      } else {
        tempObj[parent][name].value = e;
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = e;
    } else {
      tempObj[parent][name].value = e;
    }
    setCategoriesData(tempObj);
  };

  const handleChangeIntegerValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        parseInt(e);
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = parseInt(e);
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = parseInt(e);
      } else {
        tempObj[parent][name].value = parseInt(e);
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = parseInt(e);
    } else {
      tempObj[parent][name].value = parseInt(e);
    }
    setCategoriesData(tempObj);
  };

  const handleChangeFloatValue = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);

    let tempObj = { ...categoriesData };
    if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value =
        parseFloat(e);
    } else if (subParent === undefined && parent === undefined) {
      tempObj[name].value = parseFloat(e);
    } else if (subParent === undefined && parent !== undefined) {
      if (name === "codes") {
        let index = 0;
        tempObj[parent][name][index].value = parseFloat(e);
      } else {
        tempObj[parent][name].value = parseFloat(e);
      }
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = parseFloat(e);
    } else {
      tempObj[parent][name].value = parseFloat(e);
    }
    setCategoriesData(tempObj);
  };

  const handleChangeDateValue = (e, parent, subParent, name) => {
    setTouchedValue(true);
    let tempObj = { ...categoriesData };
    const hourValue = dayjs(e).format("HH");
    const minuteValue = dayjs(e).format("m");
    const seconds = dayjs(e).format("SS");

    if (subParent === undefined && parent === undefined) {
      tempObj[name].value = e;
    } else if (subParent !== undefined) {
      tempObj[subParent][parent][name].value = e;
    } else {
      tempObj[parent][name].hours.value = hourValue;
      tempObj[parent][name].minutes.value = minuteValue;
      tempObj[parent][name].seconds.value = seconds;
    }
    setCategoriesData(tempObj);
  };

  const addLanguageAttribute = (index) => {
    let tempObj = { ...categoriesData };

    tempObj.services.languagesSpoken[index].spoken.value = true;
    selectedLanguagesArr[index].spoken.value = true;
    setSelectedLanguagesArr([...selectedLanguagesArr]);
    // setCategoriesData(tempObj);
  };

  const removeLanguageAttribute = (index) => {
    let tempObj = { ...categoriesData };
    tempObj.services.languagesSpoken[index].spoken.value = null;
    selectedLanguagesArr[index].spoken.value = null;
    setCategoriesData(tempObj);
    setSelectedLanguagesArr([...selectedLanguagesArr]);
  };

  const switchLanguageAttribute = (index, e) => {
    let tempObj = { ...categoriesData };
    tempObj.services.languagesSpoken[index].spoken.value = e;
    selectedLanguagesArr[index].spoken.value = e;
    setCategoriesData(tempObj);
    setSelectedLanguagesArr([...selectedLanguagesArr]);
  };
  const handleChangeLodgingEnum = (
    e,
    parent,
    subParent,
    grandParent,
    name,
    subChild
  ) => {
    setTouchedValue(true);
    let tempObj = { ...categoriesData };
    if (
      subParent === undefined &&
      parent === undefined &&
      grandParent === undefined
    ) {
      tempObj[name].value = e;
    } else if (subChild !== undefined) {
      tempObj[subParent][subChild][grandParent][parent][name].value = e;
    } else if (grandParent !== undefined) {
      if (name === "languageCode" || name === "ecoCertificate") {
        let index = 0;
        tempObj[subParent][parent][grandParent][index][name].value = e;
      } else {
        tempObj[subParent][parent][name].value = e;
      }
    } else if (grandParent === undefined && subParent !== undefined) {
      if (name === "languageCode") {
        let index = 0;

        tempObj[subParent][parent][index][name].value = e;
      } else {
        tempObj[subParent][parent][name].value = e;
      }
    } else {
      tempObj[parent][name].value = e;
    }
    setCategoriesData(tempObj);
  };

  const handleExpandPanel = (event, newExpanded, panel) => {
    const _expandedPanels = [...expandedPanels];

    if (newExpanded) {
      _expandedPanels.push(panel);
    } else {
      const index = _expandedPanels.findIndex((item) => item === panel);
      if (index > -1) {
        _expandedPanels.splice(index, 1);
      }
    }
    setExpandedPanels([..._expandedPanels]);
  };

  return (
    <Grid item xs={12} sm={12} md={12} lg={12}>
      {displayConfirmationBox && (
        <Dialog
          // fullScreen={fullScreen}
          open={displayConfirmationBox}
          onClose={handleClose}
          aria-labelledby="responsive-dialog-title"
        >
          <ConfirmModal
            title={t("Delete_Bulk_Posts")}
            description={t("Delete_post_subhead")}
            onClose={handleClose}
            onCancel={onCancel}
            indexValue={indexValue}
            setSelectedTab={setSelectedTab}
            cancelState={cancelState}
            touchedValue={touchedValue}
            setTouchedValue={setTouchedValue}
          />
        </Dialog>
      )}
      <Grid container sx={{ padding: "18px" }}>
        <Grid
          item
          xs={10}
          sm={11}
          md={11.5}
          lg={11.5}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Typography className={classes.modalHeader}>
            {t("Attributes")}
          </Typography>
          <BootstrapTooltip
            title={
              allAttributes !== null &&
              allAttributes?.hasOwnProperty("lodging") &&
              allAttributes?.hasOwnProperty("attributes")
                ? t("attributesTooltipBoth")
                : t("attributesTooltipSingle")
            }
          >
            <Typography
              sx={{
                marginLeft: "5px",
                marginTop: "4px",
                cursor: "pointer",
              }}
            >
              <InfoOutlinedIcon />
            </Typography>
          </BootstrapTooltip>
        </Grid>
        <Grid item xs={2} sm={1} md={0.5} lg={0.5}>
          <IconButton
            onClick={() => {
              handleOpen();
              setCancelState(true);
            }}
            className="delete_button"
          >
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Divider />
      <Grid
        container
        className={classes.gridContainertwoBulk}
        ref={gridContainerRef}
      >
        {isLoading ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              sx={{ padding: "18px", paddingTop: "50px", width: "700px" }}
            >
              <Loader />
            </Grid>
          </>
        ) : (
          <Box container sx={{ padding: "18px", width: "100%" }}>
            {allAttributes !== null &&
            allAttributes?.hasOwnProperty("lodging") &&
            allAttributes?.hasOwnProperty("attributes") ? (
              <>
                <Grid sx={12} md={12} sm={12} lg={12} mb={4}>
                  <Collapsible
                    title={t("Hotel_text")}
                    handleChange={handleExpandPanel}
                    expanded={expandedPanels.includes("Hotel")}
                    id={"Hotel"}
                    ComponentToRender={
                      <Grid container>
                        <LodgingAttributes
                          onCancel={onCancel}
                          handleUpdateAttributesWithCategories={
                            handleUpdateAttributes
                          }
                          errorMessage={errorMessage}
                          errors={errors}
                          setErrors={setErrors}
                          setErrorMessage={setErrorMessage}
                          allAttributes={allAttributes?.lodging?.lodgingData}
                          touchedValue={touchedValue}
                          setTouchedValue={setTouchedValue}
                          setDisplayConfirmationBox={setDisplayConfirmationBox}
                          displayConfirmationBox={displayConfirmationBox}
                          indexValue={indexValue}
                          setSelectedTab={setSelectedTab}
                          selectedTab={selectedTab}
                          setAttributesWithCategories={
                            setAttributesWithCategories
                          }
                          attributesWithCategories={attributesWithCategories}
                          handleChangeStringValue={handleChangeStringValue}
                          handleChangeIntegerValue={handleChangeIntegerValue}
                          handleChangeFloatValue={handleChangeFloatValue}
                          addMultiAttribute={addMultiAttribute}
                          removeMultiAttribute={removeMultiAttribute}
                          handleChangeChecked={handleChangeChecked}
                          setCategoriesData={setCategoriesData}
                          categoriesData={categoriesData}
                          attributesLoading={attributesLoading}
                          setAttributesLoading={setAttributesLoading}
                          setSelectedCategoryName={setSelectedCategoryName}
                          selectedCategoryName={selectedCategoryName}
                          handleChangeDateValue={handleChangeDateValue}
                          setDisplayChildCategories={setDisplayChildCategories}
                          displayChildCategories={displayChildCategories}
                          handleChangeEnum={handleChangeLodgingEnum}
                          setIsEditable={setIsEditable}
                          isEditable={isEditable}
                          setOpenConfirmBox={setOpenConfirmBox}
                          openConfirmBox={openConfirmBox}
                          nextCategory={nextCategory}
                          setNextCategory={setNextCategory}
                          setIntegerError={setIntegerError}
                          integerError={integerError}
                          locationCategoriesData={locationCategoriesData}
                          setLocationCategoriesData={setLocationCategoriesData}
                          setSelectedLanguagesArr={setSelectedLanguagesArr}
                          selectedLanguagesArr={selectedLanguagesArr}
                          addLanguageAttribute={addLanguageAttribute}
                          removeLanguageAttribute={removeLanguageAttribute}
                          switchLanguageAttribute={switchLanguageAttribute}
                        />
                      </Grid>
                    }
                  />
                </Grid>

                {allAttributes?.attributes?.length > 0 && (
                  <Grid sx={12} md={12} sm={12} lg={12} mb={4}>
                    {" "}
                    <Collapsible
                      title={t("Restaurant_text")}
                      handleChange={handleExpandPanel}
                      expanded={expandedPanels.includes("Restaurant")}
                      id={"Restaurant"}
                      ComponentToRender={
                        <Grid container>
                          <LocationAttributes
                            onCancel={onCancel}
                            locationDetailsData={() => {}}
                            allAttributes={allAttributes}
                            touchedValue={touchedValue}
                            setTouchedValue={setTouchedValue}
                            setDisplayConfirmationBox={
                              setDisplayConfirmationBox
                            }
                            displayConfirmationBox={displayConfirmationBox}
                            indexValue={indexValue}
                            setSelectedTab={setSelectedTab}
                            selectedTab={selectedTab}
                            handleClose={handleClose}
                            handleUpdateAttributes={handleUpdateAttributes}
                            cancelState={cancelState}
                            isLoading={isLoading}
                            itemsArr={itemsArr}
                            setItemsArr={setItemsArr}
                            enumRequired={enumRequired}
                            setEnumRequired={setEnumRequired}
                            urlRequired={urlRequired}
                            setUrlRequired={setUrlRequired}
                            repeatedEnumRequired={repeatedEnumRequired}
                            setRepeatedEnumRequired={setRepeatedEnumRequired}
                            urlValidation={urlValidation}
                            setUrlValidation={setUrlValidation}
                            filteredItemsArr={filteredItemsArr}
                            handleChangeAttributeValue={
                              handleChangeAttributeValue
                            }
                            setSelectedItem={setSelectedItem}
                            selectedItem={selectedItem}
                            handleChangeSwitch={handleChangeSwitch}
                            removeAttribute={removeAttribute}
                            handleChangeURL={handleChangeURL}
                            handleChangeEnum={handleChangeEnum}
                            handleChangeRepeatedEnum={handleChangeRepeatedEnum}
                            handleRepeatedEnumSwitch={handleRepeatedEnumSwitch}
                            handleAddNewData={handleAddNewData}
                            hasEmpty={hasEmpty}
                            url={url}
                            enums={enums}
                          />
                        </Grid>
                      }
                    />
                  </Grid>
                )}
              </>
            ) : allAttributes?.hasOwnProperty("lodging") &&
              allAttributes?.hasOwnProperty("attributes") === false ? (
              <LodgingAttributes
                onCancel={onCancel}
                handleUpdateAttributesWithCategories={handleUpdateAttributes}
                errorMessage={errorMessage}
                errors={errors}
                setErrors={setErrors}
                setErrorMessage={setErrorMessage}
                allAttributes={allAttributes?.lodging?.lodgingData}
                touchedValue={touchedValue}
                setTouchedValue={setTouchedValue}
                setDisplayConfirmationBox={setDisplayConfirmationBox}
                displayConfirmationBox={displayConfirmationBox}
                indexValue={indexValue}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                setAttributesWithCategories={setAttributesWithCategories}
                attributesWithCategories={attributesWithCategories}
                handleChangeStringValue={handleChangeStringValue}
                handleChangeIntegerValue={handleChangeIntegerValue}
                handleChangeFloatValue={handleChangeFloatValue}
                addMultiAttribute={addMultiAttribute}
                removeMultiAttribute={removeMultiAttribute}
                handleChangeChecked={handleChangeChecked}
                setCategoriesData={setCategoriesData}
                categoriesData={categoriesData}
                attributesLoading={attributesLoading}
                setAttributesLoading={setAttributesLoading}
                setSelectedCategoryName={setSelectedCategoryName}
                selectedCategoryName={selectedCategoryName}
                handleChangeDateValue={handleChangeDateValue}
                setDisplayChildCategories={setDisplayChildCategories}
                displayChildCategories={displayChildCategories}
                handleChangeEnum={handleChangeLodgingEnum}
                setIsEditable={setIsEditable}
                isEditable={isEditable}
                setOpenConfirmBox={setOpenConfirmBox}
                openConfirmBox={openConfirmBox}
                nextCategory={nextCategory}
                setNextCategory={setNextCategory}
                setIntegerError={setIntegerError}
                integerError={integerError}
                locationCategoriesData={locationCategoriesData}
                setLocationCategoriesData={setLocationCategoriesData}
                setSelectedLanguagesArr={setSelectedLanguagesArr}
                selectedLanguagesArr={selectedLanguagesArr}
                addLanguageAttribute={addLanguageAttribute}
                removeLanguageAttribute={removeLanguageAttribute}
                switchLanguageAttribute={switchLanguageAttribute}
              />
            ) : (
              <LocationAttributes
                onCancel={onCancel}
                locationDetailsData={() => {}}
                allAttributes={allAttributes}
                touchedValue={touchedValue}
                setTouchedValue={setTouchedValue}
                setDisplayConfirmationBox={setDisplayConfirmationBox}
                displayConfirmationBox={displayConfirmationBox}
                indexValue={indexValue}
                setSelectedTab={setSelectedTab}
                selectedTab={selectedTab}
                handleClose={handleClose}
                handleUpdateAttributes={handleUpdateAttributes}
                cancelState={cancelState}
                isLoading={isLoading}
                itemsArr={itemsArr}
                setItemsArr={setItemsArr}
                enumRequired={enumRequired}
                setEnumRequired={setEnumRequired}
                urlRequired={urlRequired}
                setUrlRequired={setUrlRequired}
                repeatedEnumRequired={repeatedEnumRequired}
                setRepeatedEnumRequired={setRepeatedEnumRequired}
                urlValidation={urlValidation}
                setUrlValidation={setUrlValidation}
                filteredItemsArr={filteredItemsArr}
                handleChangeAttributeValue={handleChangeAttributeValue}
                setSelectedItem={setSelectedItem}
                selectedItem={selectedItem}
                handleChangeSwitch={handleChangeSwitch}
                removeAttribute={removeAttribute}
                handleChangeURL={handleChangeURL}
                handleChangeEnum={handleChangeEnum}
                handleChangeRepeatedEnum={handleChangeRepeatedEnum}
                handleRepeatedEnumSwitch={handleRepeatedEnumSwitch}
                handleAddNewData={handleAddNewData}
                hasEmpty={hasEmpty}
                url={url}
                enums={enums}
              />
            )}
          </Box>
        )}
      </Grid>
      <Divider />
      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        className={classes.gridButtons}
      >
        <CommonButton
          displayWhite="true"
          label={t("Back")}
          onSubmit={handleCancelButton}
        />
        <CommonButton
          disabled={
            hasEmpty ||
            urlRequired ||
            enumRequired ||
            urlValidation ||
            disabled === true
          }
          onSubmit={handleUpdateAttributes}
          label={t("Save")}
        />
      </Grid>
    </Grid>
  );
};

export default AttributesModal;
